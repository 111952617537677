import React from "react";
import {IIconProps} from "modules/types";

export const IconError: React.FC<IIconProps> = ({width = 30, height = 27, color = "#000"}) => (
	<svg
		width={`${width}px`}
		height={`${height}px`}
		viewBox="0 0 30 27"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg">
		<title>Group</title>
		<g id="Symbols" stroke="none" fill="none">
			<g
				id="3.0-Icons-/-Error-(Missing-/-Are-you-sure)-"
				transform="translate(0.000000, -2.000000)">
				<g id="Group" transform="translate(2.000000, 4.000000)">
					<path
						d="M25.4246774,18.0267805 L15.8469253,1.65773985 C15.2465449,0.63184226 14.1379439,0 12.9371831,0 C11.7364223,0 10.6275231,0.631547969 10.0271427,1.65773985 L0.450285284,18.0267805 C-0.150095095,19.0523838 -0.150095095,20.3163626 0.450285284,21.3425544 C1.05036741,22.3678634 2.15926669,23 3.36002745,23 L22.5149353,23 C23.7153978,23 24.8245953,22.3681577 25.4249757,21.3425544 C26.0250578,20.3163626 26.0250578,19.0523838 25.4246774,18.0267805 Z"
						id="Path"
						stroke={color}
					/>
					<path
						d="M12.9375,17.25 C12.6535494,17.25 12.4050926,17.1425234 12.2276235,16.9633956 C12.0856481,16.7842679 11.9791667,16.5693146 11.9791667,16.2827103 C11.9791667,16.0319315 12.0856481,15.7811526 12.2631173,15.6020249 C12.6180556,15.2437695 13.2569444,15.2437695 13.6118827,15.6020249 C13.7893519,15.7811526 13.8958333,16.0319315 13.8958333,16.2827103 C13.8958333,16.5693146 13.7893519,16.7842679 13.6118827,16.9633956 C13.4344136,17.1783489 13.1859568,17.25 12.9375,17.25 Z"
						id="Path"
						fill={color}
					/>
					<path
						d="M12.9375,13.4166667 C12.4050926,13.4166667 11.9791667,12.9472789 11.9791667,12.3605442 L11.9791667,6.80612245 C11.9791667,6.21938776 12.4050926,5.75 12.9375,5.75 C13.4699074,5.75 13.8958333,6.21938776 13.8958333,6.80612245 L13.8958333,12.3605442 C13.8958333,12.9472789 13.4699074,13.4166667 12.9375,13.4166667 Z"
						id="Path"
						fill={color}
					/>
				</g>
			</g>
		</g>
	</svg>
);
