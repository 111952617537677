import {createReducer} from "redux-act";
import {
	getPrediction,
	getPredictionSuccess,
	IAnswer,
	postPrediction,
	postPredictionSuccess,
	resetIsFetched,
	resetPostStatus,
	resetPrediction,
	setPredictionState,
} from "modules/actions/prediction";
import {size} from "lodash";

export interface IPredictionReducer {
	answers: Record<string, IAnswer[]>;
	isFetched: boolean;
	isPosted: boolean;
	hasAnswers: boolean;
	// activeTab: QuestionType;
}

const initialState: IPredictionReducer = {
	answers: {},
	isFetched: false,
	isPosted: false,
	hasAnswers: false,
	// activeTab: QuestionType.Women,
};

export const prediction = createReducer<IPredictionReducer>({}, initialState)
	.on(getPrediction, (state) => ({
		...state,
		isFetched: false,
	}))
	.on(getPredictionSuccess, (state, {answers}) => {
		return {
			...state,
			answers,
			isFetched: true,
			hasAnswers: size(answers) > 0,
		};
	})
	.on(resetIsFetched, (state) => ({
		...state,
		isFetched: false,
	}))
	.on(setPredictionState, (state, {unitId, answer, questionId}) => {
		const currentUnitAnswers = state.answers[unitId] || [];
		const filterUnitAnswers = currentUnitAnswers.filter((a) => a.questionId !== questionId);
		return {
			...state,
			answers: {
				...state.answers,
				[unitId]: [...filterUnitAnswers, {answer, questionId}],
			},
			isPosted: false,
		};
	})
	.on(postPrediction, (state) => ({
		...state,
		isPosted: false,
	}))
	.on(postPredictionSuccess, (state, payload) => {
		return {
			...state,
			answers: {
				...state.answers,
				[payload.unitId]: payload.answers,
			},
			isPosted: true,
			hasAnswers: true,
		};
	})
	.on(resetPrediction, (state, {unitId}) => ({
		...state,
		answers: {
			...state.answers,
			[unitId]: [],
		},
	}))
	.on(resetPostStatus, (state) => ({
		...state,
		isPosted: false,
	}));
