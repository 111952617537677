import {createAction} from "redux-act";
import {IHelpReducer} from "modules/reducers";
import {IApiResponse} from "modules/types";

export const fetchHelpPageJSON = createAction();
export const fetchHelpPageJSONSuccess = createAction<Omit<IHelpReducer, "isSend">>();
export const fetchHelpPageJSONError = createAction<Error>();

export interface IContactData {
	name: string;
	email: string;
	type: string;
	question: string;
}

export const postContactForm = createAction<IContactData>();
export const postContactFormSuccess = createAction<IApiResponse>();
