import React from "react";
import styled from "styled-components";
import {NavLink} from "react-router-dom";
import theme from "assets/css/theme";

const Row = styled.div`
	width: 100%;
	height: 48px;
	display: flex;
	justify-content: space-around;
	align-items: center;
	padding: 0 20px;
	box-sizing: border-box;
	margin-top: -24px;
	z-index: 5;
	position: relative; ;
`;

const JoinButton = styled(NavLink)`
	background: ${theme.primary};
	height: 48px;
	width: 47%;
	max-width: 200px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	font-family: "StyreneA-Bold", sans-serif;
	font-size: 12px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 15px;
	text-align: center;
	text-transform: uppercase;
	transition: all ease 0.3s;

	&:hover {
		background: ${theme.primaryActive};
	}
`;
const CreateButton = styled(NavLink)`
	background: ${theme.secondary};
	height: 48px;
	width: 47%;
	max-width: 200px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	font-family: "StyreneA-Bold", sans-serif;
	font-size: 12px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 15px;
	text-align: center;
	text-transform: uppercase;
	transition: all ease 0.3s;

	&:hover {
		background: ${theme.secondaryActive};
	}
`;

export const CreateJoinRow: React.FC = () => {
	return (
		<Row>
			<JoinButton as={NavLink} to={"/leagues/join"}>
				Join a league
			</JoinButton>
			<CreateButton as={NavLink} to={"/leagues/create"}>
				Create a league
			</CreateButton>
		</Row>
	);
};
