import React, {Fragment, useEffect} from "react";
import {fetchEntryData} from "modules/actions";
import {useDispatch, useSelector} from "react-redux";
import {getEntryLoadingState} from "modules/selectors/global";
import {RequestState} from "modules";
import {eq} from "lodash";

export const HOCEntryData: React.FC = ({children}) => {
	const dispatch = useDispatch();
	const loadingState = useSelector(getEntryLoadingState);
	const isRequested = eq(loadingState, RequestState.Requested);

	useEffect(() => {
		dispatch(fetchEntryData());
	}, [dispatch]);

	return isRequested ? null : <Fragment>{children}</Fragment>;
};
