import {IIconProps} from "modules/types";
import React from "react";

export const IconLock: React.FC<IIconProps> = ({width = 18, height = 21, color = "#000"}) => (
	<svg
		width={`${width}px`}
		height={`${height}px`}
		viewBox="0 0 18 21"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg">
		<title>Group 4</title>
		<g id="Symbols" stroke="none" fill="none">
			<g id="3.0-Icons-/-Lock" transform="translate(-6.000000, -4.000000)">
				<g id="Group-4" transform="translate(7.000000, 5.000000)">
					<rect id="Rectangle" stroke={color} x="0" y="7" width="16" height="12" />
					<path
						d="M7.97866595,14.4296455 C8.34666595,14.4296455 8.64466595,14.3009013 8.64466595,14.1419151 L8.64666595,11.717376 C8.64666595,11.5583898 8.34866595,11.4296455 7.98066595,11.4296455 C7.61266595,11.4296455 7.31466595,11.5583898 7.31466595,11.717376 L7.31466595,14.1419151 C7.31466595,14.3009013 7.61066595,14.4296455 7.97866595,14.4296455 Z"
						id="Path"
						fill={color}
					/>
					<path
						d="M3.16666667,7 L3.16666667,2 C3.16666667,0.8954305 4.06209717,0 5.16666667,0 L11.1666667,0 C12.2712362,0 13.1666667,0.8954305 13.1666667,2 L13.1666667,7"
						id="Path"
						stroke={color}
					/>
				</g>
			</g>
		</g>
	</svg>
);
