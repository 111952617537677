import {IMAGES_URL} from "modules/constants";

export const daySeconds = 86400;
export const minuteSeconds = 60;
export const hourSeconds = 3600;

export const getTimeSeconds = (time: number) => (minuteSeconds - time) | 0;
export const getTimeMinutes = (time: number) => ((time % hourSeconds) / minuteSeconds) | 0;
export const getTimeHours = (time: number) => ((time % daySeconds) / hourSeconds) | 0;
export const getTimeDays = (time: number) => (time / daySeconds) | 0;

export const getTimeRemaining = (time: number) => {
	const Day = getTimeDays(time);
	const Hour = getTimeHours(time);
	const Minutes = getTimeMinutes(time);
	const Seconds = getTimeSeconds(time);

	if (Day > 0) {
		return `Day`;
	}
	if (Hour > 0) {
		return `Hour`;
	}
	if (Minutes > 0) {
		return `Minutes`;
	}
	if (Seconds > 0) {
		return `Seconds`;
	}
};

export const getMediaImage = (src: string) => (src ? `${IMAGES_URL}image/${src}` : "");

export const userNameForRankings = (
	firstName: string | undefined,
	lastName: string | undefined
) => {
	if (firstName && lastName) {
		return `${firstName} ${lastName.slice(0, 1)}.`;
	}
	return "";
};
