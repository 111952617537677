import {fetchTournamentJSONSuccess} from "modules/actions";
import {createReducer} from "redux-act";

export interface ITournamentReducer {
	startDateTime: string;
	endDateTime: string;
}

const initialState: ITournamentReducer = {
	startDateTime: "",
	endDateTime: "",
};

export const tournament = createReducer<ITournamentReducer>({}, initialState).on(
	fetchTournamentJSONSuccess,
	(state, payload) => ({
		...state,
		...payload,
	})
);
