import {Exist} from "components/Exist";
import {
	daySeconds,
	getTimeDays,
	getTimeHours,
	getTimeMinutes,
	getTimeSeconds,
	hourSeconds,
	minuteSeconds,
} from "modules/utils/help";
import React, {Fragment, useEffect} from "react";
import {CountdownCircleTimer} from "react-countdown-circle-timer";
import styled from "styled-components";
import {
	differenceInDays,
	differenceInHours,
	differenceInMinutes,
	differenceInSeconds,
} from "date-fns";
import {useDispatch} from "react-redux";
import {getPrediction, getRoundPoints, setCurrentSliderRound} from "modules/actions";

const TimerWrapper = styled.div`
	position: absolute;
	width: 110px;
	height: 110px;
	border-radius: 50%;
	background: #fff;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	justify-content: center;
	align-items: center;

	.timer {
	}

	.time {
		color: #000000;
		font-family: "StyreneA-Bold", sans-serif;
		font-size: 33.54px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 22px;
		text-align: center;
		margin: 4px 0;
	}

	.time__prev,
	.time__next {
		color: #000000;
		font-family: "StyreneA-Bold", sans-serif;
		font-size: 18px;
		font-weight: bold;
		letter-spacing: 0;
		text-align: center;
		margin: 0;
	}

	.time__prev {
		mask-image: linear-gradient(
			to bottom,
			transparent 0,
			black 35px,
			black calc(100% - 35px),
			transparent 100%
		);
	}

	.time__next {
		mask-image: linear-gradient(
			to top,
			transparent 0,
			black 35px,
			black calc(100% - 35px),
			transparent 100%
		);
	}
`;
const LoadingTimer = styled.div`
	height: 94px;
	width: 94px;
	border: 5.5px solid #000;
	border-radius: 50%;
	color: #000000;
	font-family: "StyreneA-Bold", sans-serif;
	font-size: 14px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 20px;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 10px;
	box-sizing: border-box;
`;
const timerProps = {
	isPlaying: true,
	size: 94,
	strokeWidth: 5.5,
	colors: "#000",
};

const renderTime = (time: number) => {
	return (
		<div className="time-wrapper">
			<div className="time time__prev">{time > 0 ? time - 1 : "0"}</div>
			<div className="time">{time}</div>
			<div className="time time__next">{time + 1}</div>
		</div>
	);
};

interface IProps {
	roundId?: number;
	startGameDay?: number;
}

export const ComingSoonCounter: React.FC<IProps> = ({roundId, startGameDay = 0}) => {
	const dispatch = useDispatch();
	const deltaTime = startGameDay - Date.now();

	const remainingTime = deltaTime / 1000;
	const daysFull = Math.ceil(remainingTime / daySeconds);
	const daysDuration = daysFull * daySeconds;

	const days = differenceInDays(startGameDay, Date.now());
	const hours = [!days, differenceInHours(startGameDay, Date.now())].every(Boolean);
	const minutes = [!hours, !days, differenceInMinutes(startGameDay, Date.now())].every(Boolean);
	const seconds = [!hours, !days, !minutes, differenceInSeconds(startGameDay, Date.now())].every(
		Boolean
	);

	useEffect(() => {
		if (!seconds && roundId) {
			dispatch(getPrediction({roundId}));
			dispatch(getRoundPoints({roundId}));
			dispatch(setCurrentSliderRound({roundId}));
		}
	}, [dispatch, seconds, roundId]);

	return (
		<TimerWrapper>
			{startGameDay ? (
				<Fragment>
					<Exist when={!!days}>
						<CountdownCircleTimer
							{...timerProps}
							duration={daysDuration}
							initialRemainingTime={remainingTime}>
							{({elapsedTime}) =>
								renderTime(getTimeDays(daysDuration - elapsedTime!))
							}
						</CountdownCircleTimer>
					</Exist>
					<Exist when={hours}>
						<CountdownCircleTimer
							{...timerProps}
							duration={daySeconds}
							initialRemainingTime={remainingTime % daySeconds}
							onComplete={(totalElapsedTime) => [
								remainingTime - totalElapsedTime > hourSeconds,
								0,
							]}>
							{({elapsedTime}) => renderTime(getTimeHours(daySeconds - elapsedTime!))}
						</CountdownCircleTimer>
					</Exist>
					<Exist when={minutes}>
						<CountdownCircleTimer
							{...timerProps}
							duration={hourSeconds}
							initialRemainingTime={remainingTime % hourSeconds}
							onComplete={(totalElapsedTime) => [
								remainingTime - totalElapsedTime > minuteSeconds,
								0,
							]}>
							{({elapsedTime}) =>
								renderTime(getTimeMinutes(hourSeconds - elapsedTime!))
							}
						</CountdownCircleTimer>
					</Exist>
					<Exist when={seconds}>
						<CountdownCircleTimer
							{...timerProps}
							duration={minuteSeconds}
							initialRemainingTime={remainingTime % minuteSeconds}
							onComplete={(totalElapsedTime) => [
								remainingTime - totalElapsedTime > 0,
								0,
							]}>
							{({elapsedTime}) => renderTime(getTimeSeconds(elapsedTime!))}
						</CountdownCircleTimer>
					</Exist>
				</Fragment>
			) : (
				<LoadingTimer>Loading...</LoadingTimer>
			)}
		</TimerWrapper>
	);
};
