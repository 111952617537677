import {includes, keyBy} from "lodash";
import {IStore} from "modules/types/store";
import {createSelector} from "reselect";
import {ILeague} from "modules/reducers";
import {LeagueClass} from "modules/enums";

export const getLeaguesStore = (state: IStore) => state.leagues;

export const getMyLeagues = createSelector(
	getLeaguesStore,
	({leaguesAvailable}) => leaguesAvailable.leagues
);
export const getLeaguesForJoin = createSelector(
	getLeaguesStore,
	({leaguesForJoin}) => leaguesForJoin
);
export const getOfficialLeaguesForJoin = createSelector(getLeaguesForJoin, ({leagues}) =>
	leagues.filter((league) => league.class === LeagueClass.club)
);
export const getCelebrityLeaguesForJoin = createSelector(getLeaguesForJoin, ({leagues}) =>
	leagues.filter((league) => league.class === LeagueClass.celebrity)
);
export const getOtherLeaguesForJoin = createSelector(getLeaguesForJoin, ({leagues}) =>
	leagues.filter((league) =>
		includes([LeagueClass.regular, LeagueClass.predefined], league.class)
	)
);

export const getJoinRequestState = createSelector(
	getLeaguesStore,
	({joinRequestState}) => joinRequestState
);
export const getUpdateLeagueState = createSelector(
	getLeaguesStore,
	({updateLeagueState}) => updateLeagueState
);
export const getCreatedLeagueID = createSelector(getLeaguesStore, ({invites}) => invites.leagueID);

export const getLeagueById = createSelector(getMyLeagues, (leagues) => keyBy(leagues, "id"));

export const getLeagueByIdSelector = createSelector(
	getLeagueById,
	(leaguesById) =>
		(id: number): ILeague | undefined =>
			leaguesById[id]
);

export const getInvitesRequestState = createSelector(
	getLeaguesStore,
	({invites}) => invites.requestState
);

export const getLeagueJoinedUsers = createSelector(getLeaguesStore, ({joinedUsers}) => joinedUsers);
