import * as React from "react";
import styled from "styled-components";

interface IActiveState {
	colorActive?: string;
	name: string;
}

const RadioButtonWrapper = styled.div`
	flex: 1 1 100%;
	display: flex;
	justify-content: space-around;
	margin-top: 20px;
	position: relative;

	&.disabled {
		label {
			position: relative;
			//border: 2px solid grey;

			&:after {
				content: "";
				background: #f7f7f7;
				position: absolute;
				width: 100%;
				height: 100%;
				opacity: 0.5;
			}
		}

		input:checked + label {
			&:after {
				content: "";
				background: transparent;
				position: absolute;
				width: 100%;
				height: 100%;
				opacity: 1;
			}
		}
	}
`;

const RadioButtonStyled = styled.label<IActiveState>`
	width: 48%;
	max-width: 160px;
	height: 48px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 2px solid #000;
	box-sizing: border-box;
	cursor: pointer;

	color: #000000;
	font-family: "StyreneA-Bold", sans-serif;
	font-size: 12px;
	font-weight: bold;
	letter-spacing: 0;
	text-align: center;
	text-transform: uppercase;
	padding: 10px 15px;
	background-color: #fff;
	transition: all ease 0.3s;
`;

const RadioStyled = styled.input<IActiveState>`
	display: none;

	&:checked + ${RadioButtonStyled} {
		border: 2px solid ${(props) => props.colorActive};
		background-color: ${(props) => props.colorActive};
		color: #fff;
	}
`;

export interface IOption {
	readonly value: number | string;
	readonly text: string;
}

interface IProps {
	readonly name: string;
	readonly disabled?: boolean;
	readonly options: IOption[];
	form_value: string | number;
	onChange: (e: React.SyntheticEvent<HTMLInputElement>) => void;
	activeColor?: string;
}

const compare = (value: string | number, to_compare: string | number) => value === to_compare;

const getClassName = (comparator: boolean) => (comparator ? "disabled" : "");

export const FormRadioButtons: React.FC<IProps> = (props) => {
	const {name, options = [], disabled, form_value, onChange, activeColor} = props;
	return (
		<RadioButtonWrapper className={getClassName(Boolean(disabled))}>
			{options.map(({value, text}, index) => (
				<React.Fragment key={index}>
					<RadioStyled
						type="radio"
						id={`${name}_${value}`}
						name={name}
						value={value}
						checked={compare(value, form_value)}
						onChange={onChange}
						disabled={disabled}
						colorActive={activeColor}
					/>
					<RadioButtonStyled htmlFor={`${name}_${value}`} name={name}>
						{text}
					</RadioButtonStyled>
				</React.Fragment>
			))}
		</RadioButtonWrapper>
	);
};
