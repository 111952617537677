import styled from "styled-components";
import React, {Fragment} from "react";
import {IconFacebook, IconTwitter} from "components/Icons";
import {useSelector} from "react-redux";
import {getPredictionAnswerByUnitId} from "modules/selectors/prediction";
import {get, partial, size} from "lodash";
import {getRoundRankings} from "modules/selectors/rankings";
import {getRoundById} from "modules/selectors/rounds";
import {ShareNet} from "modules";
import {share} from "modules/utils/Share";
import {IUnit} from "modules/actions";
import share_navigator from "assets/img/icon/share-navigator.svg";

const PointsRowWrapper = styled.div`
	width: 100%;
	height: 62px;
	background: #fff;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1;
`;
const Cell = styled.div`
	border: 1px solid #e4e4e4;
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	margin-left: -1px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex: 1 1 100%;
	padding: 8px 3px;
	flex-flow: column nowrap;
`;
const PointTitle = styled.div`
	color: #000000;
	font-family: "StyreneA-Regular", sans-serif;
	font-size: 10px;
	letter-spacing: 0;
	line-height: 13px;
	text-align: center;
	text-transform: uppercase;
	width: 100%;
	min-height: 26px;
	margin-bottom: 6px;

	&.auto_height {
		min-height: unset !important;
	}
`;
const PointValue = styled.div`
	color: #000000;
	font-family: "StyreneA-Bold", sans-serif;
	font-size: 12px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 15px;
	text-align: center;
	width: 100%;
`;
const ShareContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;
const ShareButton = styled.div`
	width: 24px;
	height: 24px;
	background: #000;
	border-radius: 50%;
	margin-right: 5px;
`;
const ShareNavigatorButton = styled(ShareButton)`
	background: url(${share_navigator}) no-repeat 50% 50% transparent;
	background-size: contain;
`;
const Step = styled.div`
	width: 20%;
	height: 62px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
`;
const ThirdStep = styled.div`
	position: relative;
	width: 60%;
	height: 62px;
	background: #fff;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	border-top: 1px solid #e4e4e4;
	border-bottom: 1px solid #e4e4e4;
`;
const FourthStep = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;

	${Cell} {
		width: 33.33%;
		border-bottom: none;
		border-top: none;
		border-right: none;

		&:last-child {
			border-right: 1px solid #e4e4e4;
		}
	}
`;

interface IProps {
	roundId: number;
	unit?: IUnit;
	isEmpty?: boolean;
}

export const PointsRow: React.FC<IProps> = ({roundId = 0, unit, isEmpty}) => {
	const currentRound = useSelector(getRoundById)(roundId);
	const roundUnitById = useSelector(getPredictionAnswerByUnitId);
	const unitId = get(unit, "id", 0);
	const currentRoundRankings = useSelector(getRoundRankings);

	if (!currentRound) {
		return null;
	}

	const predictionAnswer = roundUnitById(unitId) || [];
	const sizeOfAnswers = size(predictionAnswer);

	const getPoints = (point: number | null) => {
		if (point !== null) {
			return <PointValue>{point}</PointValue>;
		}
		return <PointValue>-</PointValue>;
	};

	const shareHandler = (sc: ShareNet) => {
		share({
			sc,
			unitId: roundId,
		});
	};

	return (
		<PointsRowWrapper className={isEmpty ? "first_step second_step" : ""}>
			{isEmpty ? (
				<Fragment>
					<Step>
						<Cell>
							<PointTitle>Questions Answered</PointTitle>
							<PointValue>0/5</PointValue>
						</Cell>
					</Step>
					<ThirdStep className={"third_step"}>
						<FourthStep className={"fourth_step"}>
							<Cell>
								<PointTitle>Match day pts</PointTitle>
								{getPoints(currentRoundRankings.roundPoints)}
							</Cell>
							<Cell>
								<PointTitle>Total pts</PointTitle>
								{getPoints(currentRoundRankings.points)}
							</Cell>
							<Cell>
								<PointTitle>Overall rank</PointTitle>
								{getPoints(currentRoundRankings.rank)}
							</Cell>
						</FourthStep>
					</ThirdStep>
					<Step>
						<Cell>
							<PointTitle className={"auto_height"}>Share</PointTitle>
							<ShareContainer>
								{"share" in navigator ? (
									<Fragment>
										<ShareNavigatorButton
											onClick={partial(shareHandler, ShareNet.Navigator)}
										/>
									</Fragment>
								) : (
									<Fragment>
										<ShareButton
											onClick={partial(shareHandler, ShareNet.Facebook)}>
											<IconFacebook />
										</ShareButton>
										<ShareButton
											onClick={partial(shareHandler, ShareNet.Twitter)}>
											<IconTwitter />
										</ShareButton>
									</Fragment>
								)}
							</ShareContainer>
						</Cell>
					</Step>
				</Fragment>
			) : (
				<Fragment>
					<Step>
						<Cell>
							<PointTitle>Questions Answered</PointTitle>
							<PointValue>{sizeOfAnswers}/5</PointValue>
						</Cell>
					</Step>
					<ThirdStep className={"third_step"}>
						<FourthStep className={"fourth_step"}>
							<Cell>
								<PointTitle>Match day pts</PointTitle>
								{getPoints(currentRoundRankings.roundPoints)}
							</Cell>
							<Cell>
								<PointTitle>Total pts</PointTitle>
								{getPoints(currentRoundRankings.points)}
							</Cell>
							<Cell>
								<PointTitle>Overall rank</PointTitle>
								{getPoints(currentRoundRankings.rank)}
							</Cell>
						</FourthStep>
					</ThirdStep>
					<Step>
						<Cell>
							<PointTitle className={"auto_height"}>Share</PointTitle>
							<ShareContainer>
								{"share" in navigator ? (
									<Fragment>
										<ShareNavigatorButton
											onClick={partial(shareHandler, ShareNet.Navigator)}
										/>
									</Fragment>
								) : (
									<Fragment>
										<ShareButton
											onClick={partial(shareHandler, ShareNet.Facebook)}>
											<IconFacebook />
										</ShareButton>
										<ShareButton
											onClick={partial(shareHandler, ShareNet.Twitter)}>
											<IconTwitter />
										</ShareButton>
									</Fragment>
								)}
							</ShareContainer>
						</Cell>
					</Step>
				</Fragment>
			)}
		</PointsRowWrapper>
	);
};
