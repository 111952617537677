import {get, isString} from "lodash";
import {ISagaAction} from "modules/types";
import {SagaIterator} from "redux-saga";
import {call, put} from "typed-redux-saga";
import {showGlobalError} from "modules/actions";
import ApiError from "modules/utils/Api/ApiError";

type TPayload = ApiError | Error | string;

const defaultErrorHandler = (payload: TPayload) => {
	if (isString(payload)) {
		throw new Error(payload);
	}

	if (isString(payload.message)) {
		throw new Error(payload.message);
	}

	if (payload instanceof ApiError) {
		throw new Error(payload.message);
	}
};

const onError = {
	*[ApiError.AUTH_REQUIRED](error: TPayload): SagaIterator {
		yield* call(defaultErrorHandler, error);
	},
};

export const errorsManagerSaga = function* ({payload}: ISagaAction<TPayload>): SagaIterator {
	const error_code = get(payload, "code") as number;

	try {
		const onErrorHandler = get(onError, error_code, defaultErrorHandler);
		yield* call(onErrorHandler, payload);
	} catch (err) {
		yield* put(
			showGlobalError({
				message: get(err, "message") as string,
				code: error_code || 0,
			})
		);
	}
};
