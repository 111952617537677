import React, {Fragment, useEffect} from "react";
import {Container as BaseContainer, LoginByTermsModal} from "components";
import styled from "styled-components";
import theme from "assets/css/theme";
import {useDispatch, useSelector} from "react-redux";
import {userLoginByTerms} from "modules/actions/user";
import {getIsLoggedIn} from "modules/selectors/user";
import {Exist} from "components/Exist";

const Container = styled(BaseContainer)`
	padding: 0;
`;
export const ModalLogo = styled.img`
	display: block;
	width: 100%;
	max-width: 220px;
	margin: 0 auto;
`;
export const ModalText = styled.div`
	color: #000000;
	font-family: "StyreneA-Regular", sans-serif;
	font-size: 12px;
	letter-spacing: 0;
	line-height: 15px;
	text-align: center;
`;
export const RowCheckbox = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	margin-bottom: 10px;
`;
const BaseLabel = styled.label`
	text-align: left;
	color: #343232;
	font-size: 12px;
	line-height: 17px;
`;
export const Label = styled(BaseLabel)`
	cursor: pointer;
	font-size: 12px;
	line-height: 15px;
	a {
		color: ${theme.secondary};
	}
`;
export const Form = styled.form`
	flex: 1 1 100%;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-flow: row wrap;
	width: 100%;
	margin: 10px auto 0;
`;
export const LoginButton = styled.button`
	background: ${theme.primary};
	height: 48px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	font-family: "StyreneA-Bold", sans-serif;
	font-size: 12px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 15px;
	outline: none;
	border: none;
	margin-top: 10px;
	text-align: center;
	text-transform: uppercase;
	transition: all ease 0.3s;

	&:hover {
		background: ${theme.primaryActive};
	}

	&:disabled {
		cursor: initial;
		opacity: 0.2;

		&:hover {
			background-color: ${({color}) => (color ? color : "#fff")};
			color: ${({color}) => (color ? "#FFF" : "#000")};
		}
	}
`;

export const Login: React.FC = () => {
	const dispatch = useDispatch();
	const isAuth = useSelector(getIsLoggedIn);

	useEffect(() => {
		dispatch(userLoginByTerms({}));
	}, [dispatch]);

	return (
		<Fragment>
			<Container />
			<Exist when={!isAuth}>
				<LoginByTermsModal />
			</Exist>
		</Fragment>
	);
};

export default Login;
