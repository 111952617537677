import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import React, {useCallback, useEffect, useState} from "react";
import {BASE_URL} from "modules";
import styled from "styled-components";
import Slider, {Settings} from "react-slick";
import theme from "assets/css/theme";
import {useDispatch, useSelector} from "react-redux";
import {getMatchDayRounds, getRounds} from "modules/selectors/rounds";
import {Exist} from "components/Exist";
import {getPrediction, getRoundPoints, setCurrentSliderRound} from "modules/actions";
import {Modal, ModalActionButton, ModalBody, ModalDescription, ModalTitle} from "components/Modal";
import {IconError, IconInfo} from "components/Icons";
import {last, size} from "lodash";
import {Link} from "react-router-dom";
import {Banner, LiveScoreWrapper, SliderMatchDayItem} from "components";

const SliderContainer = styled.div`
	width: 100%;
	position: relative;

	.slick-list {
		padding: 0 !important;
	}

	.slick-arrow {
		position: absolute;
		top: 98px;
		z-index: 9;

		&.slick-disabled {
			display: none !important;
		}

		&:before,
		&:after {
			display: none;
		}

		&.slick-prev {
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 9px 16px 9px 0;
			border-color: transparent #32c84b transparent transparent;
			left: 20px;
		}

		&.slick-next {
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 9px 0 9px 16px;
			border-color: transparent transparent transparent #32c84b;
			right: 20px;
		}
	}
`;
const IconWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 30px;
`;
const HelpLink = styled(Link)`
	text-decoration: underline;
`;
const BannerImg = styled.img`
	width: 100%;
`;

export const MatchDaySlider: React.FC = () => {
	const dispatch = useDispatch();
	const rounds = useSelector(getRounds);
	const actualRound = useSelector(getMatchDayRounds);
	const currentRound = last(actualRound);
	const lastRound = last(rounds);
	const currentRoundUnits = size(currentRound?.units);

	const startRoundId = rounds[0].id;

	useEffect(() => {
		if (currentRound) {
			dispatch(setCurrentSliderRound({roundId: currentRound.id}));
		}
	}, [dispatch, currentRound]);

	useEffect(() => {
		if (rounds.length) {
			if (currentRound) {
				dispatch(getPrediction({roundId: currentRound.id}));
				dispatch(getRoundPoints({roundId: currentRound.id}));
			} else {
				dispatch(getPrediction({roundId: startRoundId}));
				dispatch(getRoundPoints({roundId: startRoundId}));
			}
		}
	}, [dispatch, currentRound, startRoundId, rounds]);

	const isDoubleMatchInfo = localStorage.getItem("isDoubleMatchInfo");

	const [showErrorModal, setShowErrorModal] = useState(false);
	const [showInfoModal, setShowInfoModal] = useState(!isDoubleMatchInfo);
	const [showEarlyVictoryModal, setShowEarlyVictoryModal] = useState(false);

	const onErrorModal = useCallback(
		(errorSend: boolean) => {
			setShowErrorModal(errorSend);
		},
		[setShowErrorModal]
	);

	const onEarlyVictory = useCallback(
		(finish: boolean) => {
			setShowEarlyVictoryModal(finish);
		},
		[setShowEarlyVictoryModal]
	);

	const closeErrorModal = () => setShowErrorModal(false);
	const closeInfoModal = () => {
		setShowInfoModal(false);
		localStorage.setItem("isDoubleMatchInfo", "0");
	};
	const closeFinishModal = () => setShowEarlyVictoryModal(false);

	const onChangeSlider = useCallback(
		(roundId: number) => {
			dispatch(setCurrentSliderRound({roundId}));
		},
		[dispatch]
	);

	if (!(rounds && lastRound)) {
		return null;
	}

	const actualRoundIndex = currentRound ? currentRound.number - 1 : lastRound.number - 1;

	const sliderSettings: Settings = {
		className: "predictors__slider",
		dots: false,
		arrows: true,
		infinite: true,
		initialSlide: actualRoundIndex,
		slidesToShow: 1,
		swipe: false,
		touchMove: true,
		adaptiveHeight: false,
		beforeChange(currentSlide: number, nextSlide: number) {
			const roundId = rounds[nextSlide].id;
			dispatch(getPrediction({roundId}));
			dispatch(getRoundPoints({roundId}));
			onChangeSlider(roundId);
		},
		onInit() {
			onChangeSlider(currentRound ? currentRound.id : rounds[0].id);
		},
	};

	return (
		<LiveScoreWrapper>
			<Banner link={"/help:prize"}>
				<BannerImg src={`${BASE_URL}predictor-banner.jpg`} alt={"Banner"} />
			</Banner>
			<SliderContainer>
				<Slider {...sliderSettings}>
					{rounds.map((item, index) => {
						return (
							<SliderMatchDayItem
								key={index}
								round={item}
								onErrorModal={onErrorModal}
								onEarlyVictory={onEarlyVictory}
							/>
						);
					})}
				</Slider>
			</SliderContainer>
			<Exist when={showErrorModal}>
				<Modal onClose={closeErrorModal}>
					<ModalBody>
						<IconWrapper>
							<IconError width={59} height={59} color={theme.secondary} />
						</IconWrapper>
						<ModalTitle>Answers Missing</ModalTitle>
						<ModalDescription>
							Please provide answers on all the questions for the match.
						</ModalDescription>
						<ModalActionButton
							color={theme.secondary}
							hover_color={theme.secondaryActive}
							onClick={closeErrorModal}>
							OK
						</ModalActionButton>
					</ModalBody>
				</Modal>
			</Exist>
			<Exist when={showEarlyVictoryModal}>
				<Modal onClose={closeFinishModal}>
					<ModalBody>
						<IconWrapper>
							<IconInfo width={59} height={59} color={theme.secondary} />
						</IconWrapper>
						<ModalTitle>EARLY VICTORY</ModalTitle>
						<ModalDescription>
							<p>This match has been won before lockout.</p>
							<p>
								Unfortunately, this means you will not receive points for this
								match.
							</p>
						</ModalDescription>
						<ModalActionButton
							color={theme.secondary}
							hover_color={theme.secondaryActive}
							onClick={closeFinishModal}>
							OK
						</ModalActionButton>
					</ModalBody>
				</Modal>
			</Exist>
			<Exist when={showInfoModal && currentRoundUnits > 2}>
				<Modal onClose={closeInfoModal}>
					<ModalBody>
						<IconWrapper>
							<IconInfo width={59} height={59} color={theme.secondary} />
						</IconWrapper>
						<ModalTitle>DOUBLE HEADER MATCH DAY</ModalTitle>
						<ModalDescription>
							<p>
								There are two matches each for both womens and mens matches today.
								Be sure to check into the game on a regular basis to make your
								predictions and receive maximum points!
							</p>

							<p>
								To find out more about the the Double Header Match Day schedule,{" "}
								<HelpLink as={Link} to={"/help"}>
									click here.
								</HelpLink>
							</p>
						</ModalDescription>
						<ModalActionButton
							color={theme.secondary}
							hover_color={theme.secondaryActive}
							onClick={closeInfoModal}>
							OK
						</ModalActionButton>
					</ModalBody>
				</Modal>
			</Exist>
		</LiveScoreWrapper>
	);
};
