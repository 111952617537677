import {IStore} from "modules/types/store";
import {createSelector} from "reselect";

export const getPredictionStore = (state: IStore) => state.prediction;
export const getPredictionAnswersStore = (state: IStore) => state.prediction.answers;

export const getIsFetchedPrediction = (state: IStore) => state.prediction.isFetched;
export const getIsPostedPrediction = (state: IStore) => state.prediction.isPosted;
export const getHasAnswers = (state: IStore) => state.prediction.hasAnswers;
// export const getActiveTab = (state: IStore) => state.prediction.activeTab;

export const getPredictionAnswerByUnitId = createSelector(
	[getPredictionAnswersStore],
	(answers) => (id: number) => answers[id] || []
);
