import React, {Fragment, useCallback} from "react";
import {Modal, ModalActionButton, ModalBody, ModalDescription, ModalTitle} from "components/Modal";
import {useDispatch, useSelector} from "react-redux";
import {clearGlobalError} from "modules/actions";
import styled from "styled-components";
import {IconError} from "components/Icons";
import theme from "assets/css/theme";
import {getGlobalErrorMessage} from "modules/selectors/error";

const Overlay = styled.div`
	background: rgba(0, 0, 0, 0.59);
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 9;
`;
const IconWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 30px;
`;

export const ErrorModal: React.FC = () => {
	const dispatch = useDispatch();
	const errorMessage = useSelector(getGlobalErrorMessage);

	const handleClose = useCallback(() => {
		dispatch(clearGlobalError());
	}, [dispatch]);

	if (!errorMessage) {
		return null;
	}

	return (
		<Fragment>
			<Overlay onClick={handleClose} />
			<Modal hide_close={true}>
				<ModalBody>
					<IconWrapper>
						<IconError width={59} height={59} color={theme.secondary} />
					</IconWrapper>
					<ModalTitle>Error</ModalTitle>
					<ModalDescription>{errorMessage}</ModalDescription>
					<ModalActionButton onClick={handleClose}>OK</ModalActionButton>
				</ModalBody>
			</Modal>
		</Fragment>
	);
};
