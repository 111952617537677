import {IIconProps} from "modules/types";
import React from "react";

export const IconLockFormButton: React.FC<IIconProps> = ({
	width = 36,
	height = 37,
	color = "#000",
}) => (
	<svg
		width={`${width}px`}
		height={`${height}px`}
		viewBox="0 0 36 37"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg">
		<title>Group</title>
		<g id="Symbols" stroke="none">
			<g
				id="4.0-Buttons-/-Pink-(Locked)"
				transform="translate(-58.000000, -6.000000)"
				stroke={color}>
				<g id="Group" transform="translate(64.000000, 12.000000)">
					<line
						x1="-2.89546165e-13"
						y1="-8.8817842e-15"
						x2="23.7460818"
						y2="24.5897875"
						id="Line-6"
					/>
					<line
						x1="23.7093108"
						y1="-8.8817842e-15"
						x2="-1.42108547e-13"
						y2="24.6252438"
						id="Line-6"
					/>
				</g>
			</g>
		</g>
	</svg>
);
