import theme from "assets/css/theme";
import React, {Fragment} from "react";
import {NavLink} from "react-router-dom";
import styled from "styled-components";
import {IconHelp, IconPredictor, IconRankings} from "components/Icons";
import {IconLeagues} from "components/Icons/IconLeagues";
import {useSelector} from "react-redux";
import {getGodModeEnable} from "modules/selectors/user";
import {some} from "lodash";
import {IS_COMING_SOON} from "modules";

const MainNav = styled.nav`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 60px;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	background: #000000;
	border-top: 1px solid ${theme.secondary};
	z-index: 11;
`;
const MenuItem = styled(NavLink)`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: row wrap;
	flex: 1 100%;
	width: 25%;
	height: 100%;
	padding: 10px 3px;
	box-sizing: border-box;
	color: #ffffff;
	font-family: "Hundred-Display-Web", sans-serif;
	font-size: 11px;
	letter-spacing: 0;
	line-height: 14px;
	text-align: center;
	text-transform: uppercase;
	transition: all ease 0.3s;

	svg {
		g {
			stroke: #fff;
		}

		path {
			stroke: #fff;
		}
	}

	&.active,
	&:hover {
		color: ${theme.secondary};

		svg {
			g {
				stroke: ${theme.secondary} !important;
			}

			path {
				stroke: ${theme.secondary} !important;
			}
		}
	}
`;
const MenuIcon = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-bottom: 5px;
`;
const MenuItemFake = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: row wrap;
	flex: 1 100%;
	width: 25%;
	height: 100%;
	padding: 10px 3px;
	box-sizing: border-box;
	color: #ffffff;
	font-family: "Hundred-Display-Web", sans-serif;
	font-size: 11px;
	letter-spacing: 0;
	line-height: 14px;
	text-align: center;
	text-transform: uppercase;
	transition: all ease 0.3s;
	cursor: pointer;

	svg {
		g {
			stroke: #fff;
		}

		path {
			stroke: #fff;
		}
	}
`;

export const MainMenu: React.FC = () => {
	const isGodMode = useSelector(getGodModeEnable);
	const isAvailable = some([!IS_COMING_SOON, isGodMode]);

	return (
		<MainNav>
			{isAvailable ? (
				<Fragment>
					<MenuItem exact={true} to={"/"}>
						<MenuIcon>
							<IconPredictor />
						</MenuIcon>
						Predictor
					</MenuItem>
					<MenuItem to={"/leagues"} className={"last_step"}>
						<MenuIcon>
							<IconLeagues color={"#FFF"} />
						</MenuIcon>
						Leagues
					</MenuItem>
					<MenuItem to={"/rankings"}>
						<MenuIcon>
							<IconRankings color={"#FFF"} />
						</MenuIcon>
						Rankings
					</MenuItem>
					<MenuItem to={"/help"}>
						<MenuIcon>
							<IconHelp color={"#FFF"} />
						</MenuIcon>
						Help
					</MenuItem>
				</Fragment>
			) : (
				<Fragment>
					<MenuItemFake>
						<MenuIcon>
							<IconPredictor />
						</MenuIcon>
						Predictor
					</MenuItemFake>
					<MenuItemFake>
						<MenuIcon>
							<IconLeagues color={"#FFF"} />
						</MenuIcon>
						Leagues
					</MenuItemFake>
					<MenuItemFake>
						<MenuIcon>
							<IconRankings color={"#FFF"} />
						</MenuIcon>
						Rankings
					</MenuItemFake>
					<MenuItemFake>
						<MenuIcon>
							<IconHelp color={"#FFF"} />
						</MenuIcon>
						Help
					</MenuItemFake>
				</Fragment>
			)}
		</MainNav>
	);
};
