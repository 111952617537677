import {AnswerType, PredictionStatus, QuestionType, UnitStatus} from "modules";
import React, {SyntheticEvent, useCallback, useEffect, useMemo, useState} from "react";
import {Exist} from "components/Exist";
import {QuestionBinary} from "components/Question";
import theme from "assets/css/theme";
import {QuestionItem} from "components/SliderQuestion";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import form_women_bg from "assets/img/form_women_bg.png";
import form_men_bg from "assets/img/form_men_bg.png";
import {
	ISetAnswer,
	postPrediction,
	resetPrediction,
	setPredictionState,
} from "modules/actions/prediction";
import {
	getHasAnswers,
	getIsFetchedPrediction,
	getIsPostedPrediction,
	getPredictionAnswerByUnitId,
} from "modules/selectors/prediction";
import {IUnit} from "modules/actions";
import {IconLockFormButton} from "components/Icons";
import {eq, every, isEmpty, size} from "lodash";
import {Title} from "components/BaseElements";
import {BottomCountDownTimer} from "components/BottomCountDownTimer";
import {ComingSoonCounter} from "components/ComingSoonCounter";
import {BottomBox, PredictorWillBeSoon, StartMatchDay, TopBox} from "components/SliderMatchDayItem";
import {isBefore} from "date-fns";

interface IFormProps {
	formBg: string;
}

const QuestionWrapper = styled.div`
	width: 100%;
	position: relative;
	z-index: 4;
`;
const QuestionContainer = styled.div`
	width: 100%;
`;

interface ISubmitBtn {
	bgColor?: string;
	color?: string;
}

const Form = styled.form<IFormProps>`
	background: url(${(props) => props.formBg}) no-repeat 100% 100% #000;
`;
const ButtonRow = styled.div`
	width: 100%;
	background: transparent;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-flow: row wrap;
	padding: 20px 15px;
	box-sizing: border-box;
	position: relative;

	&:before {
		position: absolute;
		display: block;
		content: "";
		left: 15px;
		right: 15px;
		top: 0;
		width: 92%;
		height: 1px;
		background-color: grey;
	}
`;
const SubmitBtn = styled.button<ISubmitBtn>`
	width: 100%;
	max-width: 480px;
	height: 48px;
	background-color: ${(props) => props.bgColor};
	color: ${(props) => (props.color ? props.color : "#FFF")};
	font-family: "StyreneA-Bold", sans-serif;
	font-size: 12px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 15px;
	text-align: center;
	text-transform: uppercase;
	border: none;
	outline: none;
	margin-bottom: 6px;
	position: relative;

	svg {
		position: absolute;
		display: none;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);

		#Group {
			stroke-width: 8px;
		}
	}

	&:disabled {
		cursor: initial;

		&:before {
			display: block;
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			background: rgba(0, 0, 0, 0.3);
		}

		svg {
			display: block;
		}
	}
`;

interface IClearBtn {
	disabled?: boolean;
}

const ClearBtn = styled.button<IClearBtn>`
	width: 100%;
	max-width: 480px;
	height: 48px;
	background-color: transparent;
	color: #fff;
	opacity: ${(props) => (props.disabled ? ".2" : "1")};
	font-family: "StyreneA-Regular", sans-serif;
	font-size: 12px;
	letter-spacing: 0;
	line-height: 15px;
	text-align: center;
	text-transform: uppercase;
	border: none;
	outline: none;

	&:disabled {
		cursor: initial;
	}
`;
const SliderWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-flow: row wrap;
	padding: 20px 15px 0 30px;
	//background-color: #000;
`;

const getFormBg = (type: QuestionType) => {
	return eq(type, QuestionType.Women) ? form_men_bg : form_women_bg;
};

const getColorSlider = (type: QuestionType) => {
	return eq(type, QuestionType.Women) ? theme.secondary : theme.primary;
};

const getPostedCopy = (value: boolean) => (value ? "Updated" : "Submitted");
const getNotPostedCopy = (value: boolean) => (value ? "Update Predictions" : "Submit Predictions");

interface IProps {
	roundId: number;
	unit: IUnit;
	setFormError: (errorSend: boolean) => void;
	setEarlyVictory: (finish: boolean) => void;
}

export const MatchDayQuestion: React.FC<IProps> = ({
	roundId,
	unit,
	setFormError,
	setEarlyVictory,
}) => {
	const {type, id, questions, startDateTime, status} = unit;
	const dispatch = useDispatch();
	const unitAnswers = useSelector(getPredictionAnswerByUnitId)(id);
	const formBg = getFormBg(type);
	const predictionAnswer = useSelector(getPredictionAnswerByUnitId)(unit.id);
	const [hasAnswers, setHasAnswers] = useState(false);
	const isFetchedPredictions = useSelector(getIsFetchedPrediction);
	const isPosted = useSelector(getIsPostedPrediction);
	const hasAnswersSelector = useSelector(getHasAnswers);
	const hasQuestions = size(questions);
	// const isPlayingUnit = status === UnitStatus.playing;
	const isEarlyVictory = unit.isEarlyVictory;
	const isFetchedForSetAnswer = every([isFetchedPredictions, !isEmpty(predictionAnswer)]);
	const isPostedForSetAnswer = every([isPosted, hasAnswersSelector]);
	const hasAnswerAndSelector = every([hasAnswers, hasAnswersSelector]);
	const postedCopy = getPostedCopy(hasAnswerAndSelector);
	const notPostedCopy = getNotPostedCopy(hasAnswerAndSelector);
	const isFutureTime = isBefore(Date.now(), new Date(startDateTime));
	const isPostponedUnit = eq(status, UnitStatus.postponed);

	useEffect(() => {
		if (isFetchedForSetAnswer) {
			setHasAnswers(true);
		}
	}, [isFetchedForSetAnswer]);

	useEffect(() => {
		if (isPostedForSetAnswer) {
			setHasAnswers(true);
		}
	}, [isPostedForSetAnswer]);

	const submitButtonText = useMemo(() => {
		if (isPosted) {
			return postedCopy;
		}
		return notPostedCopy;
	}, [isPosted, postedCopy, notPostedCopy]);

	const currentSliderQuestion = questions?.filter((q) =>
		eq(q.answerType, AnswerType.NumberInput)
	);
	const currentBinaryQuestion =
		questions?.filter((q) => eq(q.answerType, AnswerType.Binary)) || [];

	const onSetFormError = useCallback(
		(errorSend: boolean) => {
			setFormError(errorSend);
		},
		[setFormError]
	);

	const onSetEarlyVictory = useCallback(
		(finish: boolean) => {
			setEarlyVictory(finish);
		},
		[setEarlyVictory]
	);

	const setPrediction = useCallback(
		(answer: ISetAnswer) => {
			dispatch(setPredictionState({...answer}));
		},
		[dispatch]
	);

	const colorSlider = getColorSlider(type);

	const predictionSubmit = (event: SyntheticEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (!every([predictionAnswer, eq(hasQuestions, size(predictionAnswer))])) {
			return onSetFormError(true);
		}

		if (isEarlyVictory) {
			return onSetEarlyVictory(true);
		}

		dispatch(
			postPrediction({
				unitId: id,
				answers: unitAnswers.map(({points, unitId, ...rest}) => rest),
			})
		);
	};

	const handlerResetForm = () => {
		dispatch(resetPrediction({unitId: String(id)}));
	};

	const isDisabled = eq(currentBinaryQuestion[0]?.status, PredictionStatus.Locked);

	if (isPostponedUnit) {
		return null;
	}

	return (
		<QuestionWrapper>
			<QuestionContainer>
				{/*<Exist when={isPlayingUnit}>*/}
				{hasQuestions ? (
					<Form onSubmit={predictionSubmit} formBg={formBg}>
						<Exist when={!!currentBinaryQuestion[0]}>
							<QuestionBinary
								activeColor={colorSlider}
								question={currentBinaryQuestion[0]}
								setPrediction={setPrediction}
								unitId={id}
								stepClass={"first_step"}
							/>
						</Exist>
						<Exist when={Boolean(currentSliderQuestion)}>
							<SliderWrapper>
								{currentSliderQuestion?.map((question, index) => (
									<QuestionItem
										key={index}
										index={index}
										color={colorSlider}
										status={question.status}
										question={question}
										endValue={Number(question.maxValue)}
										unitId={id}
										setPrediction={setPrediction}
									/>
								))}
							</SliderWrapper>
						</Exist>
						<Exist when={!!currentBinaryQuestion[1]}>
							<QuestionBinary
								activeColor={colorSlider}
								question={currentBinaryQuestion[1]}
								setPrediction={setPrediction}
								bgColor={"transparent"}
								unitId={id}
							/>
						</Exist>
						<ButtonRow>
							<SubmitBtn type="submit" disabled={isDisabled} bgColor={colorSlider}>
								<IconLockFormButton color={colorSlider} />
								{submitButtonText}
							</SubmitBtn>
							<ClearBtn
								type="button"
								disabled={isDisabled}
								onClick={handlerResetForm}>
								Clear All
							</ClearBtn>
						</ButtonRow>
					</Form>
				) : (
					<PredictorWillBeSoon>
						<TopBox>
							<Title>PREDICTOR QUESTIONS ARE COMING SOON</Title>
						</TopBox>
						<BottomBox>
							<Exist when={isFutureTime}>
								<StartMatchDay>
									The prediction questions will be available in
								</StartMatchDay>
							</Exist>
							<BottomCountDownTimer time={Number(new Date(startDateTime))} />
						</BottomBox>
						<ComingSoonCounter
							roundId={roundId}
							startGameDay={Number(new Date(startDateTime))}
						/>
					</PredictorWillBeSoon>
				)}
				{/*</Exist>*/}
			</QuestionContainer>
		</QuestionWrapper>
	);
};
