import {IIconProps} from "modules/types";
import React from "react";

export const IconYoutube: React.FC<IIconProps> = ({width = 12, height = 8, color = "#fff"}) => (
	<svg
		width={`${width}px`}
		height={`${height}px`}
		viewBox="0 0 12 8"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg">
		<g id="Symbols" stroke="none" fill="none">
			<g id="3.0-Icons-/-Youtube" transform="translate(-4.000000, -6.000000)" fill={color}>
				<g id="YouTube-icon-01" transform="translate(0.000000, 0.000000)">
					<path d="M10.0437266,6.37232749 C11.0974214,6.37232749 12.153422,6.31929631 13.2048111,6.38385601 C14.5121306,6.46455563 15.0193797,6.94183625 15.0931614,8.2284188 C15.16158,9.47135575 15.1515689,10.7173699 15.0631876,11.9590471 C14.9894059,13.0104479 14.530576,13.5177026 13.4884095,13.5499825 C11.1642861,13.623765 8.83324562,13.623765 6.50912218,13.5499825 C5.47617843,13.5177026 5.00120876,12.9920022 4.93203842,11.9590471 C4.84882799,10.7487254 4.83881849,9.53448168 4.9020646,8.32295265 C4.9758463,6.93953055 5.4900125,6.45302711 6.88264202,6.3815503 C7.9340312,6.32851912 8.99003173,6.3815503 10.0437266,6.3815503 L10.0437266,6.37232749 Z M8.78943774,11.4448752 L11.5032208,9.98997627 L8.78943774,8.553523 L8.78943774,11.4448752 Z" />
				</g>
			</g>
		</g>
	</svg>
);
