import {SagaIterator} from "redux-saga";
import {call, put} from "typed-redux-saga";
import Api from "modules/utils/Api";
import {fetchRoundsJSONSuccess, showGlobalError} from "modules/actions";

export const fetchRoundsJSONSaga = function* (): SagaIterator {
	try {
		const response = yield* call(Api.JSON.rounds);
		yield* put(fetchRoundsJSONSuccess(response.rounds));
	} catch (err) {
		yield* put(showGlobalError(err as Error));
	}
};
