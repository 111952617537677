import {SagaIterator} from "redux-saga";
import {call, put} from "typed-redux-saga";
import {fetchPlayersJSONSuccess, showGlobalError} from "modules/actions";
import {Api} from "modules/utils/Api";

export const fetchPlayersJSONSaga = function* (): SagaIterator {
	try {
		const response = yield* call(Api.JSON.players);
		yield* put(fetchPlayersJSONSuccess(response.players));
	} catch (err) {
		yield* put(showGlobalError(err as Error));
	}
};
