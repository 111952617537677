import React, {Fragment, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isSessionChecked} from "modules/selectors/user";
import {GlobalPreloader} from "components/Preloader";
import {requestCurrentUser} from "modules/actions/user";

export const Session: React.FC = ({children}) => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(requestCurrentUser());
	}, [dispatch]);

	return useSelector(isSessionChecked) ? (
		<Fragment>{children}</Fragment>
	) : (
		<GlobalPreloader isVisible={true} />
	);
};
