import {createAction} from "redux-act";

export interface IVenues {
	venues: IVenue[];
}

export interface IVenue {
	id: number;
	name: string;
	city: string;
	country: string;
}

export const fetchVenuesJSON = createAction();
export const fetchVenuesJSONSuccess = createAction<IVenue[]>();
