import {createAction} from "redux-act";

export interface IPlayers {
	players: IPlayer[];
}

export interface IPlayer {
	id: number;
	squadId: number;
	name: string;
	firstName: string;
	lastName: string;
	image: string;
}

export const fetchPlayersJSON = createAction();
export const fetchPlayersJSONSuccess = createAction<IPlayer[]>();
