import {IIconProps} from "modules/types";
import React from "react";

export const IconIncorrect: React.FC<IIconProps> = ({width = 11, height = 11, color = "red"}) => (
	<svg
		width={`${width}px`}
		height={`${height}px`}
		viewBox="0 0 11 11"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg">
		<title>Path</title>
		<g id="Symbols" stroke="none" fill="none">
			<g id="3.0-Icons-/-Incorrect" transform="translate(-10.000000, -9.000000)" fill={color}>
				<path
					d="M19.978157,9.97942738 C19.6823663,9.68363671 19.2045506,9.68363671 18.90876,9.97942738 L15.2,13.680603 L11.49124,9.971843 C11.1954494,9.67605233 10.7176337,9.67605233 10.421843,9.971843 C10.1260523,10.2676337 10.1260523,10.7454494 10.421843,11.04124 L14.130603,14.75 L10.421843,18.45876 C10.1260523,18.7545506 10.1260523,19.2323663 10.421843,19.528157 C10.7176337,19.8239477 11.1954494,19.8239477 11.49124,19.528157 L15.2,15.819397 L18.90876,19.528157 C19.2045506,19.8239477 19.6823663,19.8239477 19.978157,19.528157 C20.2739477,19.2323663 20.2739477,18.7545506 19.978157,18.45876 L16.269397,14.75 L19.978157,11.04124 C20.2663633,10.7530338 20.2663633,10.2676337 19.978157,9.97942738 Z"
					id="Path"
				/>
			</g>
		</g>
	</svg>
);
