import React, {Fragment, useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getCreatedLeagueID, getLeagueByIdSelector} from "modules/selectors/leagues";
import {Link, useParams} from "react-router-dom";
import useClipboard from "react-use-clipboard";
import {Exist} from "components/Exist";
import {Description, SubTitle, SubTitleLeft, Title} from "components/BaseElements";
import styled from "styled-components";
import {Button} from "components/Buttons";
import {IconFacebook, IconTwitter} from "components/Icons";
import theme from "assets/css/theme";
import {partial} from "lodash";
import {ShareNet} from "modules/enums";
import {getUser} from "modules/selectors/user";
import {share} from "modules/utils/Share";
import {InvitesViaEmail} from "components/League/InvitesViaEmail";
import {clearInvites, postInvite} from "modules/actions";
import share_navigator from "assets/img/icon/share-navigator.svg";

const LeagueCode = styled.strong`
	font-family: "StyreneA-Bold", sans-serif;
	color: #000;
	font-size: 20px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 24px;
	display: flex;
	justify-content: flex-start;
	width: 100%;
	margin-top: 10px;
`;
const LeagueName = styled.strong`
	font-family: "StyreneA-Bold", sans-serif;
	font-weight: bold;
	color: #000;
	font-size: 16px;
	letter-spacing: 0;
	line-height: 18px;
`;
const CopyCodeWrapper = styled.div`
	margin: 30px 0;
	font-family: ${theme.baseFont};
	font-size: 12px;
`;
const CopyButton = styled(Button)`
	text-transform: uppercase;
	max-width: 156px;
	color: #ffffff;
	font-family: "StyreneA-Bold", sans-serif;
	font-size: 12px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 15px;
	text-align: center;
`;
const ShareWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	color: #000;
	font-family: ${theme.baseFont};
	font-size: 12px;
	letter-spacing: 0;
	line-height: 16px;
`;
const InviteButton = styled.button`
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background: #000;
	border: none;
	outline: none;
	margin: 0 5px;
	transition: all 0.25s ease-out;
	cursor: pointer;
	color: #fff;
	display: inline-flex;
	justify-content: center;
	align-items: center;

	svg {
		path {
			transition: all 0.25s ease-out;
		}
	}

	&:hover {
		svg {
			path {
				fill: ${theme.secondary};
			}
		}
	}

	& > * {
		pointer-events: none;
	}
`;
const Other = styled.div`
	color: #000000;
	font-family: "StyreneA-Bold", sans-serif;
	font-size: 16px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 19px;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 30px 0;
`;
const LinkStyled = styled(Link)`
	color: #000;
	font-family: "StyreneA-Bold", sans-serif;
	width: max-content;
	text-transform: none;
	font-size: 14px;
	letter-spacing: 0;
	line-height: 20px;
	text-decoration: underline;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	position: relative;
	margin: 30px auto 0;
`;
const ShareNavigatorButton = styled(InviteButton)`
	background: url(${share_navigator}) no-repeat 50% 50%;
`;

interface ISkipButtonProps {
	leagueId?: number;
}

const SkipButton: React.FC<ISkipButtonProps> = ({leagueId = 0}) => {
	return <LinkStyled to={`/leagues/${leagueId}/about`}>Skip this step</LinkStyled>;
};

interface IRouteParams {
	id?: string;
}

export const LeagueInviteComponent: React.FC = () => {
	const dispatch = useDispatch();
	const user = useSelector(getUser);
	const leagueCreateId = useSelector(getCreatedLeagueID);
	const {id: routeLeagueID} = useParams<IRouteParams>();
	const leagueID = parseInt(String(routeLeagueID || leagueCreateId), 10);
	const league = useSelector(getLeagueByIdSelector)(leagueID);
	const leagueCode = league?.code || "";
	const isCommissioner = league?.commissioner?.id === user!.id;

	// const link = `${window.location.origin}/join/${leagueCode}`;

	// const [isLinkCopied, setLinkCopied] = useClipboard(link, {
	// 	successDuration: 3000,
	// });

	const [isCopied, setIsCopied] = useClipboard(leagueCode, {
		successDuration: 3000,
	});

	useEffect(
		() => () => {
			dispatch(clearInvites());
		},
		[dispatch]
	);

	const onSendInvites = useCallback(
		(emails: string[]) => {
			dispatch(
				postInvite({
					emails,
					leagueId: leagueID,
				})
			);
		},
		[dispatch, leagueID]
	);

	const shareHandler = (sc: ShareNet) => {
		share({
			sc,
			unitId: league ? league.startRoundId : 0,
			leagueId: leagueID,
		});
	};

	if (!isCommissioner) {
		return (
			<Exist when={Boolean(routeLeagueID)}>
				<Fragment>
					<Title>You are not commissioner!</Title>
					<Description>
						<p>
							You can`t invite to this league <LeagueName>{league?.name}</LeagueName>{" "}
							is:
						</p>
					</Description>
				</Fragment>
			</Exist>
		);
	}

	return (
		<Fragment>
			<Exist when={Boolean(routeLeagueID)}>
				<Description>
					<Exist when={Boolean(league)}>
						<p>
							Invites other to join. Your unique code for{" "}
							<LeagueName>{league?.name}</LeagueName> is:
						</p>
						<LeagueCode>{league?.code}</LeagueCode>
					</Exist>
				</Description>
			</Exist>
			<Exist when={!routeLeagueID}>
				<Title>Step 2</Title>
				<Description>
					<Exist when={Boolean(league)}>
						<p>
							Great, now you have successfully created your league you can invite
							others to join. Your unique code for{" "}
							<LeagueName>{league?.name}</LeagueName> is:
						</p>
						<LeagueCode>{league?.code}</LeagueCode>
					</Exist>
				</Description>
			</Exist>
			<CopyCodeWrapper>
				<Exist when={isCopied}>
					<Fragment>The league code has been copied into your clipboard</Fragment>
				</Exist>
				<Exist when={!isCopied}>
					<CopyButton color={"#000"} onClick={setIsCopied}>
						Copy code
					</CopyButton>
				</Exist>
			</CopyCodeWrapper>
			<SubTitleLeft>Invite via email</SubTitleLeft>

			<InvitesViaEmail onSendInvites={onSendInvites} />

			<Other>Or</Other>
			<SubTitle>Invite via social</SubTitle>
			<ShareWrapper>
				{"share" in navigator ? (
					<Fragment>
						<ShareNavigatorButton onClick={partial(shareHandler, ShareNet.Navigator)} />
					</Fragment>
				) : (
					<Fragment>
						<InviteButton
							onClick={partial(shareHandler, ShareNet.Facebook)}
							id="link-share-fb">
							<IconFacebook height={32} width={32} />
						</InviteButton>
						<InviteButton
							onClick={partial(shareHandler, ShareNet.Twitter)}
							id="link-share-tw">
							<IconTwitter height={32} width={32} />
						</InviteButton>
					</Fragment>
				)}
			</ShareWrapper>
			<Exist when={!routeLeagueID}>
				<SkipButton leagueId={league?.id} />
			</Exist>
		</Fragment>
	);
};
