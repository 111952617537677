import {
	fetchLeaguePlayers,
	fetchLeaguePlayersError,
	fetchLeaguePlayersSuccess,
	fetchShowForJoinLeagues,
	fetchShowForJoinLeaguesError,
	fetchShowForJoinLeaguesSuccess,
	fetchShowMyLeagues,
	fetchShowMyLeaguesError,
	fetchShowMyLeaguesSuccess,
	getH2HFixture,
	getH2HFixtureError,
	getH2HFixtureSuccess,
	loadMoreLeaguePlayers,
	loadMoreLeaguePlayersSuccess,
	loadMoreLeaguesForJoin,
	loadMoreLeaguesForJoinSuccess,
	postCreateLeague,
	postCreateLeagueError,
	postCreateLeagueSuccess,
	postInvite,
	postInviteError,
	postInviteSuccess,
	postJoinLeague,
	postJoinLeagueError,
	postJoinLeagueSuccess,
	postLeaveLeague,
	postLeaveLeagueError,
	postLeaveOrRemoveLeagueSuccess,
	postRemoveLeague,
	postRemoveLeagueError,
	postRemovePlayer,
	postRemovePlayerError,
	postRemovePlayerSuccess,
	postUpdateLeague,
	postUpdateLeagueError,
	postUpdateLeagueSuccess,
	setGlobalPreloaderState,
	showGlobalError,
} from "modules/actions";
import {call, put} from "typed-redux-saga";
import Api from "modules/utils/Api";
import {SagaIterator} from "redux-saga";
import {isEmpty, size} from "lodash";

export const fetchShowMyLeaguesSaga = function* ({
	payload,
}: ReturnType<typeof fetchShowMyLeagues>): SagaIterator {
	try {
		yield* put(setGlobalPreloaderState(true));
		const response = yield* call(Api.Leagues.show_my_leagues, payload);
		if (!isEmpty(response.success)) {
			yield* put(fetchShowMyLeaguesSuccess(response.success));
		} else {
			yield* put(showGlobalError({message: "You don't have a single league"}));
		}
	} catch (err) {
		yield* put(fetchShowMyLeaguesError(err as Error));
		yield* put(showGlobalError(err as Error));
	}
	yield* put(setGlobalPreloaderState(false));
};

export const fetchShowForJoinLeaguesSaga = function* ({
	payload,
}: ReturnType<typeof fetchShowForJoinLeagues>): SagaIterator {
	try {
		yield* put(setGlobalPreloaderState(true));
		const response = yield* call(Api.Leagues.show_for_join_leagues, payload);
		const isEmptyLeagues = isEmpty(response.success.leagues);
		const searchSize = size(payload.search) > 7;

		if (searchSize && isEmptyLeagues) {
			yield* put(showGlobalError({message: "League not found"}));
		}
		if (!isEmptyLeagues) {
			yield* put(fetchShowForJoinLeaguesSuccess(response.success));
		}
	} catch (err) {
		yield* put(fetchShowForJoinLeaguesError(err as Error));
	}
	yield* put(setGlobalPreloaderState(false));
};

export const postJoinLeagueSaga = function* ({
	payload,
}: ReturnType<typeof postJoinLeague>): SagaIterator {
	try {
		yield* put(setGlobalPreloaderState(true));
		const response = yield* call(Api.Leagues.join_league, payload);
		yield* put(postJoinLeagueSuccess(response.success.league));
	} catch (err) {
		yield* put(postJoinLeagueError(err as Error));
		yield* put(
			showGlobalError({
				message:
					"Unfortunately, you can only join one Official Fan League. However, you can leave your current one and join another fan league instead",
			})
		);
	}
	yield* put(setGlobalPreloaderState(false));
};

export const loadMoreLeaguesForJoinSaga = function* ({
	payload,
}: ReturnType<typeof loadMoreLeaguesForJoin>): SagaIterator {
	try {
		yield* put(setGlobalPreloaderState(true));
		const response = yield* call(Api.Leagues.show_for_join_leagues, payload);
		yield* put(loadMoreLeaguesForJoinSuccess(response.success));
	} catch (err) {
		yield* put(fetchShowForJoinLeaguesError(err as Error));
		yield* put(showGlobalError(err as Error));
	}
	yield* put(setGlobalPreloaderState(false));
};

export const postCreateLeagueSaga = function* ({
	payload,
}: ReturnType<typeof postCreateLeague>): SagaIterator {
	try {
		yield* put(setGlobalPreloaderState(true));
		const response = yield* call(Api.Leagues.create_league, payload);
		yield* put(postCreateLeagueSuccess(response.success.league));
	} catch (err) {
		yield* put(postCreateLeagueError(err as Error));
		yield* put(showGlobalError(err as Error));
	}
	yield* put(setGlobalPreloaderState(false));
};

export const postInviteSaga = function* ({payload}: ReturnType<typeof postInvite>): SagaIterator {
	try {
		yield* put(setGlobalPreloaderState(true));
		const response = yield* call(Api.Leagues.invite, payload);
		yield* put(postInviteSuccess(response));
	} catch (err) {
		yield* put(postInviteError(err as Error));
		yield* put(showGlobalError(err as Error));
	}
	yield* put(setGlobalPreloaderState(false));
};

export const postLeaveLeagueSaga = function* ({
	payload,
}: ReturnType<typeof postLeaveLeague>): SagaIterator {
	try {
		yield* put(setGlobalPreloaderState(true));
		yield* call(Api.Leagues.leave_league, payload);
		yield* put(postLeaveOrRemoveLeagueSuccess(payload));
	} catch (err) {
		yield* put(postLeaveLeagueError(err as Error));
		yield* put(showGlobalError(err as Error));
	}
	yield* put(setGlobalPreloaderState(false));
};
export const postRemoveLeagueSaga = function* ({
	payload,
}: ReturnType<typeof postRemoveLeague>): SagaIterator {
	try {
		yield* put(setGlobalPreloaderState(true));
		yield* call(Api.Leagues.remove_league, payload);
		yield* put(postLeaveOrRemoveLeagueSuccess(payload));
	} catch (err) {
		yield* put(postRemoveLeagueError(err as Error));
		yield* put(showGlobalError(err as Error));
	}
	yield* put(setGlobalPreloaderState(false));
};

export const postUpdateLeagueSaga = function* ({
	payload,
}: ReturnType<typeof postUpdateLeague>): SagaIterator {
	try {
		yield* put(setGlobalPreloaderState(true));
		const response = yield* call(Api.Leagues.update_league, payload);

		yield* put(postUpdateLeagueSuccess(response.success.league));
	} catch (err) {
		yield* put(postUpdateLeagueError(err as Error));
		yield* put(showGlobalError(err as Error));
	}
	yield* put(setGlobalPreloaderState(false));
};

export const fetchLeaguePlayersSaga = function* ({
	payload,
}: ReturnType<typeof fetchLeaguePlayers>): SagaIterator {
	try {
		yield* put(setGlobalPreloaderState(true));
		const response = yield* call(Api.Leagues.joined_users, payload);

		yield* put(fetchLeaguePlayersSuccess(response.success));
	} catch (err) {
		yield* put(fetchLeaguePlayersError(err as Error));
		yield* put(showGlobalError(err as Error));
	}
	yield* put(setGlobalPreloaderState(false));
};

export const loadMoreLeaguePlayersSaga = function* ({
	payload,
}: ReturnType<typeof loadMoreLeaguePlayers>): SagaIterator {
	try {
		yield* put(setGlobalPreloaderState(true));
		const response = yield* call(Api.Leagues.joined_users, payload);

		yield* put(loadMoreLeaguePlayersSuccess(response.success));
	} catch (err) {
		yield* put(fetchLeaguePlayersError(err as Error));
		yield* put(showGlobalError(err as Error));
	}
	yield* put(setGlobalPreloaderState(false));
};

export const postRemovePlayerSaga = function* ({
	payload,
}: ReturnType<typeof postRemovePlayer>): SagaIterator {
	try {
		yield* put(setGlobalPreloaderState(true));
		yield* call(Api.Leagues.remove_player, payload);

		yield* put(postRemovePlayerSuccess(payload.userIds[0]));
	} catch (err) {
		yield* put(postRemovePlayerError(err as Error));
		yield* put(showGlobalError(err as Error));
	}
	yield* put(setGlobalPreloaderState(false));
};

export const getH2HFixtureSaga = function* ({
	payload,
}: ReturnType<typeof getH2HFixture>): SagaIterator {
	try {
		yield* put(setGlobalPreloaderState(true));
		const response = yield* call(Api.Leagues.h2h_fixture, payload);

		yield* put(getH2HFixtureSuccess(response.success));
	} catch (err) {
		yield* put(getH2HFixtureError(err as Error));
		yield* put(showGlobalError(err as Error));
	}
	yield* put(setGlobalPreloaderState(false));
};
