import {createSelector} from "reselect";
import {IStore} from "modules/types/store";
import {RequestState} from "modules/enums";

export const getUserState = ({user}: IStore) => user;

export const isSessionChecked = createSelector(
	getUserState,
	({sessionCheckedState}) => sessionCheckedState === RequestState.Received
);

export const getUser = createSelector(getUserState, ({user}) => user);
export const getIsLoggedIn = createSelector(getUser, (user) => Boolean(user));

export const getIsRecover = createSelector(getUser, (user) => user?.isRecovered);

export const getIsTutorialViewed = createSelector(getUser, (user) => user?.isTutorialViewed);
export const getIsTutorialViewedForce = createSelector(
	getUserState,
	({isTutorialViewedForced}) => isTutorialViewedForced
);

export const getGodModeEnable = createSelector([getUserState], ({godMode}) => godMode);
