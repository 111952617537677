import React from "react";
import {Redirect, Route, RouteProps} from "react-router-dom";
import {useSelector} from "react-redux";
import {getIsLoggedIn} from "modules/selectors/user";

export const PrivateRoute: React.FC<RouteProps> = (props) => {
	const isLoggedIn = useSelector(getIsLoggedIn);
	return isLoggedIn ? <Route {...props} /> : <Redirect to={"/login"} />;
};

export const NotAuthOnlyRoute: React.FC<RouteProps> = (props) => {
	const isLoggedIn = useSelector(getIsLoggedIn);
	return isLoggedIn ? <Redirect to={"/"} /> : <Route {...props} />;
};
