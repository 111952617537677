export enum LeagueType {
	open = "open",
	headToHead = "head_to_head",
}

export enum LeagueClass {
	club = "club",
	celebrity = "celebrity",
	predefined = "predefined",
	regular = "regular",
}

export enum LeaguePrivacy {
	public = "public",
	private = "private",
}

export enum RoundStatus {
	scheduled = "scheduled",
	active = "active",
	complete = "complete",
}
export enum UnitStatus {
	scheduled = "scheduled",
	playing = "playing",
	canceled = "canceled",
	postponed = "postponed",
	complete = "complete",
}
export enum MatchDayStatus {
	Live = "live",
	Lockout = "lockout",
	Complete = "complete",
}

export enum RequestState {
	IDLE,
	Requested,
	Received,
}

export enum NavigationSide {
	Prev = "prev",
	Next = "next",
}

export enum ShareNet {
	Facebook = "fb",
	Twitter = "tw",
	Navigator = "navigator",
}

export enum ShareSource {
	Prediction = "prediction",
	League = "league",
}

export enum QuestionType {
	Women = "women",
	Men = "men",
}

export enum PredictionStatus {
	OnHold = "on_hold",
	Live = "live",
	Locked = "locked",
	Complete = "complete",
}

export enum AnswerType {
	Binary = "binary",
	NumberInput = "number_input",
}

export enum QuestionOption {
	Team = "team",
	Player = "player",
}

export enum Direction {
	ASC = "ASC",
	DESC = "DESC",
}
export enum OrderRanking {
	Rank = "rank",
	Points = "points",
	RoundPoints = "roundPoints",
}
export enum OrderH2HRanking {
	Rank = "rank",
	Points = "points",
	Rounds = "rounds",
	Wins = "wins",
	Ties = "ties",
	Losses = "losses",
}
