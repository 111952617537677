import {SagaIterator} from "redux-saga";
import {call, put} from "typed-redux-saga";
import {fetchSquadsJSONSuccess, showGlobalError} from "modules/actions";
import {Api} from "modules/utils/Api";

export const fetchSquadsJSONSaga = function* (): SagaIterator {
	try {
		const response = yield* call(Api.JSON.squads);
		yield* put(fetchSquadsJSONSuccess(response.squads));
	} catch (err) {
		yield* put(showGlobalError(err as Error));
	}
};
