import {Title} from "components/BaseElements";
import React, {SyntheticEvent, useCallback} from "react";
import styled from "styled-components";
import {FormRadioButtons} from "components/FormElemets/RadioButton";
import {IQuestion, ISetAnswer} from "modules/actions";
import {useSelector} from "react-redux";
import {getSquadById} from "modules/selectors/squads";
import {getPlayerById} from "modules/selectors/players";
import {PredictionStatus, QuestionOption} from "modules";
import {getPredictionAnswerByUnitId} from "modules/selectors/prediction";
import {eq} from "lodash";

interface IQuestionWrapper {
	bgColor: string;
}

const QuestionWrapper = styled.div<IQuestionWrapper>`
	width: 100%;
	box-sizing: border-box;
	padding: 20px 15px;
	background-color: ${(props) => props.bgColor};
	${Title} {
		color: ${(props) => (props.bgColor === "transparent" ? "#FFF" : "#000")};
	}
`;

interface IProps {
	activeColor?: string;
	bgColor?: string;
	question: IQuestion;
	unitId: number;
	setPrediction: (answer: ISetAnswer) => void;
	stepClass?: string;
}
// eslint-disable-next-line sonarjs/cognitive-complexity, complexity
export const QuestionBinary: React.FC<IProps> = ({
	activeColor,
	bgColor = "#FFF",
	question,
	setPrediction,
	unitId,
	stepClass,
}) => {
	const {title, status, id} = question;
	const predictionAnswer = useSelector(getPredictionAnswerByUnitId)(unitId) || [];
	const answerData = predictionAnswer.find(({questionId}) => eq(questionId, id));
	const answer = answerData?.answer || 0;

	const isTeamTypeQuestion = eq(question.optionType, QuestionOption.Team);
	const isLocked = eq(status, PredictionStatus.Locked);

	const getSquadSelector = useSelector(getSquadById);
	const getPlayerSelector = useSelector(getPlayerById);

	const onSetPrediction = useCallback(
		(answer: ISetAnswer) => {
			setPrediction({...answer, unitId});
		},
		[setPrediction, unitId]
	);

	if (!question.options) {
		return null;
	}

	const squadHome = getSquadSelector(question.options.homeId);
	const squadAway = getSquadSelector(question.options.awayId);

	const playerHome = getPlayerSelector(question.options.homeId);
	const playerAway = getPlayerSelector(question.options.awayId);

	const options = [
		{
			value: isTeamTypeQuestion ? squadHome?.id : playerHome?.id,
			text: isTeamTypeQuestion ? squadHome?.name : playerHome?.name,
		},
		{
			value: isTeamTypeQuestion ? squadAway?.id : playerAway?.id,
			text: isTeamTypeQuestion ? squadAway?.name : playerAway?.name,
		},
	];

	const onSelectWinner = (e: SyntheticEvent<HTMLInputElement>) => {
		const {value} = e.currentTarget;
		onSetPrediction({unitId, questionId: id, answer: Number(value)});
	};

	return (
		<QuestionWrapper bgColor={bgColor} className={stepClass}>
			<Title>{title}</Title>
			<FormRadioButtons
				onChange={onSelectWinner}
				form_value={answer}
				name={`question-${id}`}
				activeColor={activeColor}
				disabled={isLocked}
				options={[
					{value: options[0].value || "", text: options[0].text || ""},
					{value: options[1].value || "", text: options[1].text || ""},
				]}
			/>
		</QuestionWrapper>
	);
};
