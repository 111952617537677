import React, {Fragment} from "react";
import styled from "styled-components";
import {eq, range} from "lodash";
import {TooltipRenderProps} from "react-joyride";
import theme from "assets/css/theme";
import {IconArrow, IconClose} from "components/Icons";
import {Exist} from "components/Exist";
import logo from "assets/img/logo-horizont.svg";
import welcomeBg from "assets/img/cs_top_bg.png";
import {Link} from "react-router-dom";

interface ITooltipDotProps {
	isActive: boolean;
}

const CloseButton = styled.button`
	position: absolute;
	top: 8px;
	right: 8px;
	cursor: pointer;
	background: none;
	border-radius: 0;
	border: none;
	width: 30px;
	z-index: 2;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
`;
const TourArrow = styled.button`
	height: 16px;
	width: 16px;
	border-radius: 50%;
	background: none !important;
	border: none;
	outline: none;
	display: flex;
	align-items: center;
	justify-content: center;
	//background-color: ${theme.primary};
	transform: rotate(90deg);
	padding: 0;
	cursor: pointer;

	&.second {
		transform: rotate(-90deg);
	}

	&:disabled {
		background-color: #ced4da;
	}
`;

interface ITooltipBodyProps {
	isWelcomeStep: boolean;
}

const TooltipBody = styled.div<ITooltipBodyProps>`
	box-sizing: border-box;
	width: 100%;
	max-width: 550px;
	min-width: ${({isWelcomeStep}) => (isWelcomeStep ? "320px" : "unset")};
	position: relative;
	padding: ${({isWelcomeStep}) => (isWelcomeStep ? "0" : "40px 30px 20px")};
	background: ${({isWelcomeStep}) =>
		isWelcomeStep ? "#fff" : `url(${welcomeBg}) no-repeat -1px 0 #FFF`};
	border-radius: 10px;
	text-align: center;
	overflow: hidden;

	@media screen and (max-width: 480px) {
		max-width: ${({isWelcomeStep}) => (isWelcomeStep ? "100%" : "320px")};
	}
`;
const ImageContainer = styled.div`
	width: 100%;
	max-width: 260px;
	margin: 20px auto;

	img {
		display: block;
		width: 100%;
	}
`;
const TooltipTitle = styled.h2`
	color: #000000;
	font-size: 16px;
	font-family: ${theme.boldFont};
	margin-top: 20px;
	margin-bottom: 12px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 1.2;
	text-align: center;
`;
const TooltipMessage = styled.p`
	color: #000000;
	font-family: ${theme.baseFont};
	font-size: 14px;
	line-height: 1.2;
	margin-bottom: 12px;
	letter-spacing: 0;
	text-align: center;
`;
const TooltipControls = styled.div`
	width: 90%;
	//width: 100%;
	margin: auto;
	padding: 20px 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
const TooltipDots = styled.div`
	//width: 90px;
	display: flex;
	justify-content: space-between;
`;
const TooltipDot = styled.span<ITooltipDotProps>`
	box-sizing: border-box;
	height: 16px;
	width: 16px;
	border-radius: 50%;
	background: ${({isActive}) => (isActive ? theme.secondary : "#DBDBDB")};
	margin: 0 3px;
`;
const PlayButton = styled.button`
	border: none;
	background-color: ${theme.secondary};
	color: #ffffff;
	cursor: pointer;
	font-size: 12px;
	height: 48px;
	min-width: 200px;
	width: 90%;
	transition: all ease 0.3s;
	box-sizing: border-box;
	font-family: ${theme.boldFont};
	outline: 0;
	margin-bottom: 15px;
	margin-top: 10px;
	letter-spacing: 0;
	line-height: 1.1;
	text-align: center;
	text-transform: uppercase;

	&:hover {
		background-color: ${theme.secondaryActive};
	}

	&:disabled {
		cursor: default;
		opacity: 1;
		background: grey;
		color: #fff;

		&:hover {
			background: grey;
		}
	}
`;
const WelcomeContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	//border: 1px solid #fff;
`;
const GameLogo = styled.div`
	background: url(${welcomeBg}) no-repeat -1px 0 #000;
	min-height: 215px;
	border-radius: 10px 10px 0 0;
`;
const WelcomeText = styled.h3`
	display: flex;
	justify-content: center;
	align-items: center;
	color: #32c84b;
	font-family: ${theme.secondFont};
	font-size: 37.45px;
	letter-spacing: 0;
	line-height: 1.1;
	text-align: center;
	text-transform: uppercase;
	padding: 0 22px;
	box-sizing: border-box;
`;
const WelcomeDescription = styled.div`
	//min-height: 215px;
	padding: 25px 20px 10px;
	box-sizing: border-box;
`;
const LogoWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 25px;
	//position: absolute;
	//top: 50%;
	//left: 55%;
	//transform: translate(-50%, -50%);
`;
const LogoLink = styled(Link)`
	display: block;
	width: 220px;
	margin-right: 10px;
`;
const Logo = styled.img`
	display: block;
	width: 100%;
`;
const LogoAfter = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: row wrap;
	width: 60px;
	font-family: ${theme.secondFont};

	div {
		width: 100%;
		background: ${theme.secondary};
		color: #000;
		text-align: center;
		text-transform: uppercase;
		font-size: 13px;
		padding: 2px 0;
	}

	p {
		width: 100%;
		color: #fff;
		text-transform: uppercase;
		font-size: 10px;
		letter-spacing: 0.8px;
		text-align: center;
	}
`;

interface ITooltipContentProps {
	isFirstStep?: boolean;
	image?: string;
	title: string;
	message: string;
}

export const TooltipContent: React.FC<ITooltipContentProps> = ({
	isFirstStep,
	image,
	title,
	message,
}) => {
	return isFirstStep ? (
		<WelcomeContainer>
			<GameLogo>
				<LogoWrapper>
					<LogoLink to="/">
						<Logo src={logo} alt="ecb-hundred-predictor" />
					</LogoLink>
					<LogoAfter>
						<div>20-ball</div>
						<p>Predictor</p>
					</LogoAfter>
				</LogoWrapper>
				<WelcomeText>Welcome to the game</WelcomeText>
			</GameLogo>
			<WelcomeDescription>
				<TooltipTitle>{title}</TooltipTitle>
				<TooltipMessage>{message}</TooltipMessage>
			</WelcomeDescription>
		</WelcomeContainer>
	) : (
		<Fragment>
			<Exist when={!!image}>
				<ImageContainer>
					<img src={image} alt={title} />
				</ImageContainer>
			</Exist>
			<TooltipTitle>{title}</TooltipTitle>
			<TooltipMessage>{message}</TooltipMessage>
		</Fragment>
	);
};

export const TourTooltip: React.FC<TooltipRenderProps> = ({
	index,
	isLastStep,
	size,
	step,
	backProps,
	closeProps,
	skipProps,
	primaryProps,
	tooltipProps,
}) => {
	const isFirstStep = eq(index, 0);
	const dots = range(0, size).map((value, i) => {
		const isActive = index === i;
		return <TooltipDot key={value} isActive={isActive} />;
	});
	const closeColor = isFirstStep ? "#FFF" : "#000";
	const playCopy = "Next";

	return (
		<TooltipBody {...tooltipProps} isWelcomeStep={isFirstStep}>
			<CloseButton {...skipProps}>
				<IconClose width={20} height={20} color={closeColor} />
			</CloseButton>
			{step.content}
			<Exist when={index > 0}>
				<TooltipControls>
					<TourArrow {...backProps} disabled={index <= 0}>
						<IconArrow height={28} width={18} color={"#000"} />
					</TourArrow>
					<TooltipDots>{dots}</TooltipDots>
					<TourArrow {...primaryProps} disabled={isLastStep} className={"second"}>
						<IconArrow height={28} width={18} color={"#000"} />
					</TourArrow>
				</TooltipControls>
			</Exist>
			<Exist when={isLastStep}>
				<PlayButton {...closeProps}>Play now</PlayButton>
			</Exist>
			<Exist when={!isLastStep}>
				<PlayButton {...primaryProps}>{playCopy}</PlayButton>
			</Exist>
		</TooltipBody>
	);
};
