import {
	fetchEntryData,
	fetchEntryDataError,
	fetchEntryDataSuccess,
	setGlobalPreloaderState,
} from "modules/actions/global";
import {createReducer} from "redux-act";
import {RequestState} from "modules/enums";

export interface IGlobalReducer {
	loadingState: RequestState;
	isShowGlobalPreloader: boolean;
}

const defaultState: IGlobalReducer = {
	loadingState: RequestState.IDLE,
	isShowGlobalPreloader: false,
};

export const global = createReducer<IGlobalReducer>({}, defaultState)
	.on(setGlobalPreloaderState, (state, payload) => ({
		...state,
		isShowGlobalPreloader: payload,
	}))
	.on(fetchEntryData, (state) => ({
		...state,
		loadingState: RequestState.Requested,
	}))
	.on(fetchEntryDataSuccess, (state) => ({
		...state,
		loadingState: RequestState.Received,
	}))
	.on(fetchEntryDataError, (state) => ({
		...state,
		loadingState: RequestState.IDLE,
	}));
