import {
	getPrediction,
	getPredictionSuccess,
	postPrediction,
	postPredictionSuccess,
} from "modules/actions/prediction";
import {SagaIterator} from "redux-saga";
import {call, put} from "typed-redux-saga";
import {setGlobalPreloaderState, showGlobalError} from "modules/actions";
import {Api} from "modules/utils/Api";
import {groupBy} from "lodash";

export const getPredictionSaga = function* ({
	payload,
}: ReturnType<typeof getPrediction>): SagaIterator {
	try {
		yield* put(setGlobalPreloaderState(true));

		const response = yield* call(Api.Prediction.get_prediction, payload);

		const answers = groupBy(response.success.answers, (answer) => answer.unitId);

		if (response.success.answers) {
			yield* put(getPredictionSuccess({answers: answers}));
		}
	} catch (err) {
		yield* put(showGlobalError(err as Error));
	}
	yield* put(setGlobalPreloaderState(false));
};

export const postPredictionSaga = function* ({
	payload,
}: ReturnType<typeof postPrediction>): SagaIterator {
	try {
		yield* put(setGlobalPreloaderState(true));

		const response = yield* call(Api.Prediction.post_prediction, payload);

		if (response.success.answers) {
			yield* put(
				postPredictionSuccess({
					answers: response.success.answers,
					unitId: String(payload.unitId),
				})
			);
		}
	} catch (err) {
		yield* put(showGlobalError(err as Error));
	}
	yield* put(setGlobalPreloaderState(false));
};
