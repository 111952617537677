import React, {Fragment} from "react";
import {NavLink, useParams} from "react-router-dom";
import styled from "styled-components";
import theme from "assets/css/theme";
import {useSelector} from "react-redux";
import {getLeagueByIdSelector} from "modules/selectors/leagues";
import {getUser} from "modules/selectors/user";
import {Exist} from "components/Exist";
import {LeagueType} from "modules";

const SectionNav = styled.section`
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	height: 36px;
	border: 1px solid #cdcdcd;
`;
const MenuItem = styled(NavLink)`
	flex: 1 1 33%;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	color: #000000;
	font-family: "StyreneA-Regular", sans-serif;
	font-size: 10px;
	text-transform: uppercase;
	letter-spacing: 0;
	line-height: 13px;
	text-align: center;
	border-right: 1px solid #cdcdcd;
	&.active {
		color: ${theme.secondary};
	}
	&:last-child {
		border-right: none;
	}
`;

interface IRouteParams {
	id?: string;
}

export const LeagueNavigation: React.FC = () => {
	const user = useSelector(getUser);
	const {id: routeLeagueID} = useParams<IRouteParams>();
	const leagueID = parseInt(String(routeLeagueID), 10);
	const league = useSelector(getLeagueByIdSelector)(leagueID);

	if (!league) {
		return null;
	}
	const isCommissioner = league?.commissioner?.id === user!.id;
	const isH2H = league.type === LeagueType.headToHead;

	return (
		<Fragment>
			<SectionNav>
				<MenuItem to={`/leagues/${leagueID}/rankings`}>Rankings</MenuItem>
				<Exist when={isH2H}>
					<MenuItem to={`/leagues/${leagueID}/match-ups`}>Match Ups</MenuItem>
				</Exist>
				<Exist when={isCommissioner}>
					<MenuItem to={`/leagues/${leagueID}/invites`}>Invite</MenuItem>
				</Exist>
				<MenuItem exact={true} to={`/leagues/${leagueID}/about`}>
					{isCommissioner ? "Manage" : "About"}
				</MenuItem>
			</SectionNav>
		</Fragment>
	);
};
