import {IIconProps} from "modules/types";
import React from "react";

export const IconTriangle: React.FC<IIconProps> = ({width = 13, height = 9, color = "#FFF"}) => (
	<svg width={`${width}px`} height={`${height}px`} viewBox="0 0 13 9">
		<g stroke="none" fill="none">
			<g transform="translate(-8.000000, -12.000000)" fill={color}>
				<polygon
					transform="translate(14.500000, 16.500000) rotate(180.000000) translate(-14.500000, -16.500000) "
					points="14.5 12 21 21 8 21"
				/>
			</g>
		</g>
	</svg>
);
