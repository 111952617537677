import React, {useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import {IconArrow} from "components/Icons";
import theme from "assets/css/theme";

export const AccordionWrapper = styled.div`
	width: 100%;
	.accordion__section {
		display: flex;
		flex-direction: column;
		margin-bottom: 20px;
	}

	.accordion {
		background-color: #fff;
		color: #000000;
		font-family: "StyreneA-Bold", sans-serif;
		font-size: 12px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 15px;
		text-align: center;
		cursor: pointer;
		padding: 5px 25px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid #000;
		width: 100%;
		height: 48px;
		outline: none;
		text-transform: uppercase;
		position: relative;
		transition: all 0.4s ease;

		svg {
			position: absolute;
			right: 20px;
			top: 50%;
			transform: translateY(-50%);
			g {
				fill: #000000 !important;
			}
		}

		&.active {
			svg {
				transform: translateY(-50%) rotate(180deg);
				g {
					fill: #fff !important;
				}
			}
			+ .accordion__content {
				max-height: max-content;
			}
		}
	}

	.active {
		background-color: ${theme.secondary};
		border: 1px solid ${theme.secondary};
		color: #fff;
	}

	.accordion__title {
		font-family: "StyreneA-Bold", sans-serif;
		font-weight: bold;
		font-size: 14px;
		text-align: center;
	}

	.accordion__content {
		background-color: white;
		max-height: 0;
		overflow: auto;
		transition: max-height 0.6s ease;
	}

	.accordion__text {
		font-family: "StyreneA-Regular", sans-serif;
		color: #000000;
		font-size: 12px;
		letter-spacing: 0;
		line-height: 15px;

		h1,
		h2,
		h3,
		h4,
		h5 {
			display: block;
			line-height: 100%;
			margin: 15px 0;
		}

		p {
			margin-top: 15px;
		}

		strong {
			font-weight: bold;
		}

		a {
			font-weight: bold;
			text-decoration: none;
			color: ${theme.primary};

			&:hover {
				text-decoration: underline;
			}
		}

		ul,
		ol {
			padding-left: 15px;
			margin-left: 15px;
			box-sizing: border-box;

			li {
				margin-bottom: 8px;
			}
		}

		ul {
			list-style: disc;
		}
		ol {
			list-style-type: decimal;
		}
	}
`;

interface IProps {
	title: string;
	content: string;
	active?: boolean;
}
/*
 * Data obtained from a trusted source from CMS Help pages JSON
 * */
/* eslint-disable */
export const AccordionItem: React.FC<IProps> = ({title, content, active}) => {
	const [isActive, setActiveState] = useState("");

	const toggleAccordion = useCallback(() => {
		setActiveState(isActive === "" ? "active" : "");
	}, [isActive]);

	useEffect(() => {
		if (active) {
			setActiveState("active");
		}
	}, [active]);

	return (
		<div className="accordion__section">
			<button className={`accordion ${isActive}`} onClick={toggleAccordion}>
				<p className="accordion__title">{title}</p>
				<IconArrow width={20} height={20} color={"#fff"} />
			</button>
			<div className="accordion__content">
				<div className="accordion__text" dangerouslySetInnerHTML={{__html: content}} />
			</div>
		</div>
	);
};

interface IPropsContact {
	title: string;
}

export const AccordionItemContact: React.FC<IPropsContact> = ({title, children}) => {
	const [isActive, setActiveState] = useState("");

	const toggleAccordion = useCallback(() => {
		setActiveState(isActive === "" ? "active" : "");
	}, [isActive]);

	return (
		<div className="accordion__section">
			<button className={`accordion ${isActive}`} onClick={toggleAccordion}>
				<p className="accordion__title">{title}</p>
				<IconArrow width={20} height={20} color={"#fff"} />
			</button>
			<div className="accordion__content">
				<div className="accordion__text">{children}</div>
			</div>
		</div>
	);
};
