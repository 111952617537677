import {createAction} from "redux-act";
import {ApiError} from "modules/utils/Api";
import {IUser} from "modules/reducers/user";

export interface IUserLoginByBackdoor {
	email: string;
}

export const userLoginByBackdoor = createAction<IUserLoginByBackdoor>();
export const userLoginSuccess = createAction<IUser>();
export const userLoginError = createAction<ApiError>();

export interface IUserLoginByTerms {
	terms?: boolean;
	isNotificationsEnabled?: boolean;
}

export const userLoginByTerms = createAction<IUserLoginByTerms>();
export const userLoginByTermsSuccess = createAction<IUser>();
export const userLoginByTermsError = createAction<ApiError>();

export const requestCurrentUser = createAction();
export const requestCurrentUserSuccess = createAction<IUser>();
export const requestCurrentUserFailed = createAction();

export interface IUserRecover {
	isNotificationsEnabled: boolean;
}

export const userRecover = createAction<IUserRecover>();
export const userRecoverSuccess = createAction<IUser>();
export const userRecoverError = createAction<ApiError>();

export const setTutorialViewed = createAction();
export const setTutorialViewedSuccess = createAction<IUser>();
export const setTutorialViewedError = createAction<ApiError>();

export const openTutorialViewedForce = createAction();
export const closeTutorialViewedForce = createAction();

export const actionEnableGodMode = createAction();
