import React from "react";
import {IIconProps} from "modules/types";

export const IconHelp: React.FC<IIconProps> = ({width = 27, height = 21, color = "#FFF"}) => (
	<svg
		width={`${width}px`}
		height={`${height}px`}
		viewBox="0 0 27 21"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg">
		<g id="Symbols" stroke="none">
			<g
				id="6.0-Components-/-Navigation--/-Leagues"
				transform="translate(-300.000000, -10.000000)"
				stroke={color}>
				<path
					d="M302.815475,29.9859295 C302.180211,29.8869401 301.692221,29.4236146 301.56675,28.803952 C301.541242,28.6797025 301.541242,16.2599085 301.56675,16.1345405 C301.652107,15.7071709 301.908844,15.3487537 302.285969,15.1314337 C302.396834,15.0681671 302.531829,15.0144546 302.686512,14.9726032 L302.80341,14.9405155 L309.939748,14.9368803 L317.076562,14.9345967 L317.606993,14.4187005 C317.899237,14.1344787 318.793565,13.262682 319.594437,12.4819053 C320.650883,11.4512548 321.068383,11.0525707 321.10966,11.0309458 C321.157208,11.0070607 321.18642,11.0011418 321.259261,11 C321.377322,11 321.469852,11.0404999 321.53317,11.1217561 C321.617126,11.2305326 321.612257,11.1037898 321.612257,13.115899 L321.612257,14.9345967 L322.779353,14.9345967 C323.757875,14.9345967 323.962434,14.9368803 324.049881,14.9523532 C324.422375,15.0156198 324.754495,15.2090546 324.977388,15.4898509 C325.153898,15.7130898 325.251059,15.9411057 325.287705,16.2157734 C325.296279,16.2838169 325.29977,18.1753352 325.296279,22.5347848 C325.292574,28.3620182 325.29141,28.7609587 325.271935,28.8374145 C325.215814,29.05816 325.128129,29.245676 325.011469,29.4020131 C324.785108,29.7005659 324.500299,29.8810445 324.101133,29.9763755 C324.020645,29.9968584 323.402552,29.9980003 313.464455,29.9991421 C304.950192,30.0016355 302.894562,29.9991188 302.815475,29.9859295 Z"
					id="Path"
					transform="translate(313.422619, 20.500000) rotate(180.000000) translate(-313.422619, -20.500000) "
				/>
			</g>
		</g>
	</svg>
);
