import {identity} from "lodash";
import {ShareNet, ShareSource} from "modules/enums";
import {IShare} from "modules/types/share";

const FB_SHARE_URL = process.env.REACT_APP_FB_SHARE_URL || "";

/**
 * in order to reduce the size of the string for sharing
 */
const last_four_number = -4;
const getTimestamp = () => Date.now().toString().substr(last_four_number);

interface IShareObject {
	t: string;
	sc: string;
	unitId: number;
	leagueId?: number;
}

const getEncodedJson = (object: IShareObject) => btoa(JSON.stringify(object));

const getLinkForShare = (object: IShareObject) => {
	const type = object.leagueId ? ShareSource.League : ShareSource.Prediction;
	return `${FB_SHARE_URL}${type}/${getEncodedJson(object)}`;
};

export const share = (data: IShare) => {
	if (data.sc === ShareNet.Navigator) {
		void shareNavigator(data);
	}
	if (data.sc === ShareNet.Twitter) {
		shareTwitter(data);
	}

	if (data.sc === ShareNet.Facebook) {
		shareFB(data);
	}
};

export const shareNavigator = async (data: IShare): Promise<void> => {
	try {
		const {sc, unitId, leagueId} = data;
		const object: IShareObject = {
			sc,
			unitId,
			leagueId,
			t: getTimestamp(),
		};

		const href = getLinkForShare(object);

		const shareData = {
			url: href,
		};

		if (window.navigator.share) {
			await window.navigator.share(shareData);
		}
	} catch (err) {
		console.log(err);
	}
};

export const shareFB = (data: IShare) => {
	const FB_API = window.FB;
	const {sc, unitId, leagueId} = data;

	const object: IShareObject = {
		sc,
		unitId,
		leagueId,
		t: getTimestamp(),
	};

	FB_API.ui(
		{
			method: "share",
			display: "popup",
			href: getLinkForShare(object),
		},
		identity
	);
};

export const shareTwitter = (data: IShare) => {
	const {sc, unitId, leagueId, message = ""} = data;
	const object: IShareObject = {
		sc,
		unitId,
		leagueId,
		t: getTimestamp(),
	};

	const href = getLinkForShare(object);

	window.open(
		"https://twitter.com/share?url=" +
			encodeURIComponent(href) +
			"&text=" +
			encodeURIComponent(message),
		"twitter-share-dialog",
		"width=626,height=436"
	);
};
