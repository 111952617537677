import {fetchEntryDataError, fetchEntryDataSuccess, setGlobalPreloaderState} from "modules/actions";
import {all, call, put} from "typed-redux-saga";
import {fetchRoundsJSONSaga} from "modules/sagas/rounds";
import {fetchSquadsJSONSaga} from "modules/sagas/squads";
import {fetchPlayersJSONSaga} from "modules/sagas/players";
import {fetchVenuesJSONSaga} from "modules/sagas/venues";
import {fetchTournamentJSONSaga} from "modules/sagas/tournament";

export const fetchEntryDataSaga = function* () {
	try {
		yield* put(setGlobalPreloaderState(true));

		const sagas = [
			fetchRoundsJSONSaga,
			fetchSquadsJSONSaga,
			fetchPlayersJSONSaga,
			fetchVenuesJSONSaga,
			fetchTournamentJSONSaga,
		];

		yield* all(sagas.map(call));

		yield* put(fetchEntryDataSuccess());
	} catch (err) {
		yield* put(fetchEntryDataError(err as Error));
	}
	yield* put(setGlobalPreloaderState(false));
};
