import {IIconProps} from "modules/types";
import React from "react";

export const IconClose: React.FC<IIconProps> = ({width = 11, height = 11, color}) => (
	<svg
		width={`${width}px`}
		height={`${height}px`}
		viewBox="0 0 11 11"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg">
		<g id="Symbols" stroke="none" fill="none">
			<g id="3.0-Icons-/-Remove" transform="translate(-5.000000, -4.000000)" fill={color}>
				<path
					d="M14.978157,4.97942738 C14.6823663,4.68363671 14.2045506,4.68363671 13.90876,4.97942738 L10.2,8.68060296 L6.49124005,4.971843 C6.19544937,4.67605233 5.71763367,4.67605233 5.421843,4.971843 C5.12605233,5.26763367 5.12605233,5.74544937 5.421843,6.04124005 L9.13060296,9.75 L5.421843,13.45876 C5.12605233,13.7545506 5.12605233,14.2323663 5.421843,14.528157 C5.71763367,14.8239477 6.19544937,14.8239477 6.49124005,14.528157 L10.2,10.819397 L13.90876,14.528157 C14.2045506,14.8239477 14.6823663,14.8239477 14.978157,14.528157 C15.2739477,14.2323663 15.2739477,13.7545506 14.978157,13.45876 L11.269397,9.75 L14.978157,6.04124005 C15.2663633,5.75303375 15.2663633,5.26763367 14.978157,4.97942738 Z"
					id="Path"
				/>
			</g>
		</g>
	</svg>
);
