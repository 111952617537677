import React from "react";
import styled from "styled-components";
import {NavLink} from "react-router-dom";

const BannerWrapper = styled.div`
	//width: 100%;
	width: 320px;
	height: 50px;
	margin: 15px auto;
	background: #fff;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	overflow: hidden;

	img {
		display: block;
		width: 100%;
	}
`;

const BannerLink = styled(NavLink)`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
`;

interface IProps {
	link?: string;
	isVisible?: boolean;
}

export const Banner: React.FC<IProps> = ({link = "", isVisible = false, children}) => {
	return isVisible ? (
		<BannerWrapper>
			<BannerLink to={link}>{children}</BannerLink>
		</BannerWrapper>
	) : null;
};
