import {createAction} from "redux-act";
import {
	AnswerType,
	PredictionStatus,
	QuestionOption,
	QuestionType,
	RoundStatus,
	UnitStatus,
} from "modules/enums";

export interface IRound {
	id: number;
	number: number;
	date: string;
	status: RoundStatus;
	units: IUnit[];
}

export interface IUnit {
	id: number;
	type: QuestionType;
	venueId: number;
	homeSquadId: number;
	awaySquadId: number;
	startDateTime: string;
	status: UnitStatus;
	isEarlyVictory: boolean;
	ballsCountdown: number;
	questions?: IQuestion[];
}

export interface IQuestion {
	id: number;
	position: number;
	title: string;
	maxValue?: number | null;
	maxPoints: number;
	answerType: AnswerType;
	answerLabel: string;
	correctAnswer: number;
	correctAnswerTitle: string;
	optionType: QuestionOption;
	options?: IQuestionOptions | null;
	status: PredictionStatus;
}

export interface IQuestionOptions {
	homeId: number;
	awayId: number;
}

export const fetchRoundsJSON = createAction();
export const fetchRoundsJSONSuccess = createAction<IRound[]>();
export const fetchRoundsJSONError = createAction<Error>();

export const setCurrentSliderRound = createAction<{roundId: number}>();
