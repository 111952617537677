import {SagaIterator} from "redux-saga";
import {all, takeEvery, takeLatest} from "redux-saga/effects";
import {
	fetchChecksumsJSON,
	fetchEntryData,
	fetchHelpPageJSON,
	fetchLeaguePlayers,
	fetchPlayersJSON,
	fetchRoundsJSON,
	fetchShowForJoinLeagues,
	fetchShowMyLeagues,
	fetchSquadsJSON,
	fetchTournamentJSON,
	fetchVenuesJSON,
	getH2HFixture,
	getH2HRankings,
	getOverallRankings,
	getRegularLeagueRankings,
	getRoundPoints,
	globalError,
	loadMoreLeaguePlayers,
	loadMoreLeagueRankings,
	loadMoreLeaguesForJoin,
	loadMoreRankings,
	postContactForm,
	postCreateLeague,
	postInvite,
	postJoinLeague,
	postLeaveLeague,
	postRemoveLeague,
	postRemovePlayer,
	postUpdateLeague,
} from "modules/actions";
import {
	fetchLeaguePlayersSaga,
	fetchShowForJoinLeaguesSaga,
	fetchShowMyLeaguesSaga,
	getH2HFixtureSaga,
	loadMoreLeaguePlayersSaga,
	loadMoreLeaguesForJoinSaga,
	postCreateLeagueSaga,
	postInviteSaga,
	postJoinLeagueSaga,
	postLeaveLeagueSaga,
	postRemoveLeagueSaga,
	postRemovePlayerSaga,
	postUpdateLeagueSaga,
} from "./leagues";
import {
	requestCurrentUser,
	setTutorialViewed,
	userLoginByBackdoor,
	userLoginByTerms,
	userRecover,
} from "modules/actions/user";
import {
	getCurrentUserSaga,
	setTutorialViewedSaga,
	userLoginByBackdoorSaga,
	userLoginByTermsSaga,
	userRecoverSaga,
} from "./user";
import {fetchHelpPageJSONSaga, postContactFormSaga} from "./help";
import {errorsManagerSaga} from "modules/sagas/error";
import {getPrediction, postPrediction} from "modules/actions/prediction";
import {getPredictionSaga, postPredictionSaga} from "modules/sagas/prediction";
import {
	getH2HRankingsSaga,
	getOverallRankingsSaga,
	getRegularLeagueRankingsSaga,
	getRoundPointsSaga,
	loadMoreLeagueRankingsSaga,
	loadMoreRankingsSaga,
} from "./rankings";
import {fetchChecksumsJSONSaga} from "modules/sagas/checksums";
import {fetchEntryDataSaga} from "./global";
import {fetchRoundsJSONSaga} from "./rounds";
import {fetchSquadsJSONSaga} from "./squads";
import {fetchPlayersJSONSaga} from "./players";
import {fetchVenuesJSONSaga} from "./venues";
import {fetchTournamentJSONSaga} from "./tournament";

/**
 * Root saga that connect sagas with actions.
 */
export const rootSaga = function* (): SagaIterator {
	yield all([
		/**
		 * Global Error
		 */
		takeLatest(globalError, errorsManagerSaga),
		/**
		 * Fetch EntryData JSONS
		 */
		takeLatest(fetchEntryData, fetchEntryDataSaga),
		/**
		 * JSON
		 */
		takeLatest(fetchRoundsJSON, fetchRoundsJSONSaga),
		takeLatest(fetchSquadsJSON, fetchSquadsJSONSaga),
		takeLatest(fetchPlayersJSON, fetchPlayersJSONSaga),
		takeLatest(fetchVenuesJSON, fetchVenuesJSONSaga),
		takeLatest(fetchTournamentJSON, fetchTournamentJSONSaga),
		takeLatest(fetchHelpPageJSON, fetchHelpPageJSONSaga),
		/**
		 * Prediction
		 */
		takeEvery(getPrediction, getPredictionSaga),
		takeLatest(postPrediction, postPredictionSaga),
		/**
		 * Rankings
		 */
		takeLatest(getRoundPoints, getRoundPointsSaga),
		takeLatest(getOverallRankings, getOverallRankingsSaga),
		takeLatest(getRegularLeagueRankings, getRegularLeagueRankingsSaga),
		takeLatest(loadMoreRankings, loadMoreRankingsSaga),
		takeLatest(loadMoreLeagueRankings, loadMoreLeagueRankingsSaga),
		takeLatest(getH2HRankings, getH2HRankingsSaga),
		/**
		/**
		 * Auth
		 */
		takeLatest(userLoginByBackdoor, userLoginByBackdoorSaga),
		/**
		 * User
		 */
		takeLatest(requestCurrentUser, getCurrentUserSaga),
		takeLatest(userLoginByTerms, userLoginByTermsSaga),
		takeLatest(userRecover, userRecoverSaga),
		takeLatest(setTutorialViewed, setTutorialViewedSaga),
		/**
		 * leagues
		 */
		takeLatest(fetchShowMyLeagues, fetchShowMyLeaguesSaga),
		takeLatest(fetchShowForJoinLeagues, fetchShowForJoinLeaguesSaga),
		takeLatest(postJoinLeague, postJoinLeagueSaga),
		takeLatest(loadMoreLeaguesForJoin, loadMoreLeaguesForJoinSaga),
		takeLatest(postCreateLeague, postCreateLeagueSaga),
		takeLatest(postInvite, postInviteSaga),
		takeLatest(postLeaveLeague, postLeaveLeagueSaga),
		takeLatest(postRemoveLeague, postRemoveLeagueSaga),
		takeLatest(postUpdateLeague, postUpdateLeagueSaga),
		takeLatest(fetchLeaguePlayers, fetchLeaguePlayersSaga),
		takeLatest(loadMoreLeaguePlayers, loadMoreLeaguePlayersSaga),
		takeLatest(postRemovePlayer, postRemovePlayerSaga),
		takeEvery(getH2HFixture, getH2HFixtureSaga),
		/**
		 * Help
		 */
		takeLatest(postContactForm, postContactFormSaga),
		/**
		 * Checksums
		 */
		takeLatest(fetchChecksumsJSON, fetchChecksumsJSONSaga),
	]);
};
