import {checksumsSelector} from "modules/selectors/checksums";
import Api from "modules/utils/Api";
import {SagaIterator} from "redux-saga";
import {call, put, select} from "typed-redux-saga";
import {fetchChecksumsJSONFailed, fetchChecksumsJSONSuccess} from "modules/actions";
import {fetchRoundsJSONSaga} from "modules/sagas/rounds";

export const fetchChecksumsJSONSaga = function* (): SagaIterator {
	try {
		const checksums = yield* select(checksumsSelector);
		const response = yield* call(Api.JSON.checksums);

		if (checksums.rounds === response.rounds) {
			return;
		} else {
			yield* call(fetchRoundsJSONSaga);
			yield* put(fetchChecksumsJSONSuccess(response));
		}
	} catch (err) {
		yield* put(fetchChecksumsJSONFailed(err as Error));
	}
};
