import {
	getH2HFixture,
	getH2HFixtureSuccess,
	getH2HRankingsSuccess,
	IFixture,
	IPlayerRanking,
	updateH2HFilter,
} from "modules/actions";
import {createReducer} from "redux-act";
import {Direction, OrderH2HRanking} from "modules/enums";

export interface IMatchUpReducer {
	matchUp: IFixture[];
	currentUserRanking?: IPlayerRanking;
	rankings: IPlayerRanking[];
	nextPage: boolean;
	filter: IFilterH2HRankings;
	isFetched: boolean;
}

export interface IFilterH2HRankings {
	limit: number;
	page: number;
	order: OrderH2HRanking;
	direction: Direction;
}

const defaultFilter: IFilterH2HRankings = {
	limit: 100,
	page: 1,
	order: OrderH2HRanking.Rank,
	direction: Direction.ASC,
};

const initialState: IMatchUpReducer = {
	matchUp: [],
	currentUserRanking: {
		userId: 0,
		firstName: "",
		lastName: "",
		points: 0,
		rank: 0,
		wins: 0,
		ties: 0,
		losses: 0,
		rounds: 0,
	},
	rankings: [],
	nextPage: false,
	filter: defaultFilter,
	isFetched: false,
};

export const matchUp = createReducer<IMatchUpReducer>({}, initialState)
	.on(getH2HFixture, (state) => ({
		...state,
		isFetched: false,
	}))
	.on(getH2HFixtureSuccess, (state, payload) => ({
		...state,
		matchUp: payload.fixtures,
		isFetched: true,
	}))
	.on(updateH2HFilter, (state, payload) => ({
		...state,
		filter: {
			...state.filter,
			...payload,
		},
	}))
	.on(getH2HRankingsSuccess, (state, payload) => ({
		...state,
		currentUserRanking: payload.currentUserRanking,
		rankings: payload.rankings,
		nextPage: payload.nextPage,
	}));
