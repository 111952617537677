import {IStore} from "modules/types/store";
import {createSelector} from "reselect";

export const getPlayers = (state: IStore) => state.players.players;

export const getPlayerById = createSelector(
	getPlayers,
	(players) => (playerId: number) => players.find((s) => s.id === playerId)
);

export const getPlayerBySquadId = createSelector(
	getPlayers,
	(players) => (squadId: number) => players.find((player) => player.squadId === squadId)
);
