import React from "react";
import styled from "styled-components";
import bannerBg from "assets/img/banner_league_bg.svg";

const BannerWrapper = styled.div`
	background: url(${bannerBg}) no-repeat 0 0;
	background-size: cover;
	height: 130px;
	position: relative;
`;

const BannerContainer = styled.div`
	padding: 0 20px;
	box-sizing: border-box;
`;

export const LeagueBanner: React.FC = ({children}) => (
	<BannerWrapper>
		<BannerContainer>{children}</BannerContainer>
	</BannerWrapper>
);
