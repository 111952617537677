import React, {Fragment, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchChecksumsJSON} from "modules/actions";
import {getActiveRounds, getCurrentSliderRound} from "modules/selectors/rounds";

export const LiveScoreWrapper: React.FC = ({children}) => {
	const dispatch = useDispatch();
	const activeRound = useSelector(getActiveRounds);
	const currentSliderRound = useSelector(getCurrentSliderRound);
	const activeRoundId = activeRound ? activeRound.id : null;
	const waitTime = 60000;

	useEffect(() => {
		if (activeRoundId === currentSliderRound) {
			const waitInterval = setInterval(() => {
				dispatch(fetchChecksumsJSON());
			}, waitTime);

			return () => {
				clearInterval(waitInterval);
			};
		}
	}, [dispatch, activeRoundId, currentSliderRound]);

	return <Fragment>{children}</Fragment>;
};
