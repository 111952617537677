import theme from "assets/css/theme";
import React from "react";
import styled from "styled-components";

export interface IButtonProps {
	/**
	 * Define button size. Font-size, width and height will vary depending on this field.
	 */
	size?: "big" | "medium" | "small";
	color?: string;
	hover_color?: string;
	activeColor?: string;
	onClick?: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	is_white_background?: true;
	background_image?: string;
}

export const Button = styled.button<IButtonProps>`
	background-color: ${({color}) => (color ? color : "#fff")};
	border: 2px solid ${({color}) => (color ? color : "transparent")};
	cursor: pointer;
	color: #fff;
	padding: 0 10px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	text-overflow: ellipsis;
	height: 48px;
	width: 100%;
	max-width: unset;
	border-radius: 0;
	box-sizing: border-box;
	outline: 0;
	font-family: ${theme.baseFont}, sans-serif;
	font-size: 16px;
	letter-spacing: 0;
	line-height: 24px;
	transition: all ease 0.4s;

	&:hover {
		background-color: ${({hover_color}) => (hover_color ? hover_color : "transparent")};
		color: ${(props) => (props.color ? "#000" : "#fff")};
	}

	&:disabled {
		cursor: initial;
		opacity: 0.2;

		&:hover {
			background-color: ${({color}) => (color ? color : theme.primary)};
			color: ${({color}) => (color ? color : "#FFF")};
		}
	}
`;

export const QuestionButton = styled.button<IButtonProps>`
	width: 48%;
	max-width: 160px;
	height: 48px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 2px solid #000;
	cursor: pointer;

	color: #000000;
	font-family: "StyreneA-Bold", sans-serif;
	font-size: 12px;
	font-weight: bold;
	letter-spacing: 0;
	text-align: center;
	text-transform: uppercase;
	box-sizing: border-box;
	padding: 10px 15px;
	background-color: #fff;
	transition: all ease 0.3s;

	&:hover {
		background-color: ${(props) => (props.activeColor ? props.activeColor : "#000")};
		border: 2px solid ${(props) => (props.activeColor ? props.activeColor : "#000")};
		color: #fff;
	}
`;
