import {Title as BaseTitle} from "components/BaseElements";
import {Exist} from "components/Exist";
import React, {Fragment, SyntheticEvent, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import styled from "styled-components";
import {LeaguePrivacy, LeagueType} from "modules";
import {
	clearLeague,
	ILeagueCreatePayload,
	ILeagueUpdatePayload,
	postCreateLeague,
	postUpdateLeague,
} from "modules/actions";
import {FormField, FormRadioButtons, Input, Select} from "components/FormElemets";
import theme from "assets/css/theme";
import {Button} from "components/Buttons";
import {useDispatch, useSelector} from "react-redux";
import {
	getCreatedLeagueID,
	getLeagueByIdSelector,
	getUpdateLeagueState,
} from "modules/selectors/leagues";
import {range} from "lodash";
import {getScheduledRounds} from "modules/selectors/rounds";

const Title = styled(BaseTitle)`
	font-family: "StyreneA-Bold", sans-serif;
	color: #000000;
	font-size: 14px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 17px;
	text-transform: uppercase;
`;
const Form = styled.form`
	${FormField} {
		> input,
		select {
			font-size: 14px !important;
		}
		> label {
			display: block;
			width: 100%;
			font-size: 14px;
			color: #000;
			line-height: 18px;
			text-align: left;
			height: 18px;
			margin-bottom: 2px;
		}

		&.privacy-cell {
			> div {
				margin-top: 10px;
				max-width: 600px;
				position: relative;

				label {
					width: 50%;
					height: 34px;
					max-width: unset;
					display: flex;
					justify-content: center;
					align-items: center;
					font-family: "StyreneA-Regular", sans-serif;
					font-size: 12px;
					font-weight: normal;
					letter-spacing: 0;
					line-height: 15px;
					color: #000;
					margin-bottom: 0;
					background-color: #eeeeef;
					border: 0.5px solid #eeeeef;
					text-transform: none;
				}

				input:checked + label {
					border: 0.5px solid rgba(0, 0, 0, 0.04);
					background-color: #000000;
					box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.12), 0 3px 1px 0 rgba(0, 0, 0, 0.04);
					color: #ffffff;
				}
			}
		}
	}
`;
const ConfirmButton = styled(Button)`
	color: #ffffff;
	font-family: "StyreneA-Bold", sans-serif;
	font-size: 12px;
	letter-spacing: 0;
	line-height: 15px;
	text-align: center;
	text-transform: uppercase;
	display: flex;
	justify-content: center;
	align-items: center;
	&:hover {
		color: #fff !important;
	}
`;
const RemoveLeagueButton = styled(Button)`
	color: #ffffff;
	font-family: "StyreneA-Bold", sans-serif;
	font-size: 12px;
	letter-spacing: 0;
	line-height: 15px;
	text-align: center;
	text-transform: uppercase;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 15px;
	&:hover {
		background-color: black !important;
		color: #fff !important;
	}
`;
const RemoveText = styled.p`
	color: #000000;
	font-family: "StyreneA-Regular", sans-serif;
	font-size: 11px;
	letter-spacing: 0;
	line-height: 13px;
	margin: 10px 0;
`;

interface IRouteParams {
	id?: string;
}

const typeOfLeague = [
	{
		name: "Classic",
		value: "open",
	},
	{
		name: "H2H",
		value: "head_to_head",
	},
];

interface IProps {
	toggleRemoveModal?: () => void;
}
/*
 * A complex component that is used both for the league management page and as a league creation component
 * */
// eslint-disable-next-line sonarjs/cognitive-complexity, complexity
export const LeagueCreateComponent: React.FC<IProps> = ({toggleRemoveModal}) => {
	const dispatch = useDispatch();
	const {id: routeLeagueID} = useParams<IRouteParams>();
	const leagueID = parseInt(String(routeLeagueID), 10);
	const league = useSelector(getLeagueByIdSelector)(leagueID);
	const [isCreated, setIsCreated] = useState(false);
	const [errors, handleErrors] = useState({name: ""});
	const rounds = useSelector(getScheduledRounds);
	const defaultRoundId = rounds[0].id;
	const createdLeagueID = useSelector(getCreatedLeagueID);
	const isUpdateLeagueState = useSelector(getUpdateLeagueState);
	const [isDisabledUpdate, setIsDisabledUpdate] = useState(true);

	const defaultFormState: Required<ILeagueCreatePayload | ILeagueUpdatePayload> = {
		startRoundId: league?.startRoundId || defaultRoundId,
		name: league?.name || "",
		privacy: league?.privacy || LeaguePrivacy.private,
		type: league?.type || LeagueType.open,
		size: Number(league?.size),
	};
	const [formState, changeFormState] = useState(defaultFormState);
	const isDisabled = Boolean(formState.name !== "");
	const isH2HLeague = formState.type === LeagueType.headToHead;
	const createBtnLabel = isCreated ? "Update Your League Settings" : "Create League";
	const updateButtonLabel = isUpdateLeagueState
		? "League is Updated"
		: "Update Your League Settings";
	const evenOrOdd = isH2HLeague ? 2 : 1;
	const leagueMaxSize = isH2HLeague ? 34 : 100;

	useEffect(() => {
		setIsCreated(Boolean(createdLeagueID));
	}, [createdLeagueID]);

	useEffect(() => {
		dispatch(clearLeague());
	}, [dispatch]);

	const setValue = (e: SyntheticEvent<HTMLInputElement | HTMLSelectElement>) => {
		const {value, name} = e.currentTarget;
		const isNanValue = isNaN(parseInt(value, 10));
		const intOrString = isNanValue ? value : Number(value);
		setIsDisabledUpdate(false);

		if (name === "type") {
			const isH2H = value === "head_to_head";
			changeFormState({
				...formState,
				[name as keyof typeof defaultFormState]: intOrString,
				size: isH2H ? 34 : 100,
				privacy: LeaguePrivacy.private,
			});
		} else {
			changeFormState({
				...formState,
				[name]: intOrString,
			});
		}

		if (formState.name !== "") {
			return handleErrors({
				...errors,
				name: "",
			});
		}
	};

	const handlerCreateLeague = (e: React.SyntheticEvent<HTMLButtonElement | HTMLFormElement>) => {
		e.preventDefault();

		if (formState.name === "") {
			return handleErrors({
				...errors,
				name: "Error",
			});
		}

		dispatch(postCreateLeague(formState));
	};

	const handlerUpdateLeague = (e: React.SyntheticEvent<HTMLButtonElement | HTMLFormElement>) => {
		e.preventDefault();

		if (formState.name === "") {
			return handleErrors({
				...errors,
				name: "Error",
			});
		}
		if (leagueID) {
			dispatch(
				postUpdateLeague({
					...formState,
					leagueId: leagueID,
				})
			);
		}
	};

	return (
		<Fragment>
			<Exist when={Boolean(routeLeagueID)}>
				<Title>League settings</Title>
			</Exist>
			<Exist when={!routeLeagueID}>
				<Title>Step 1</Title>
			</Exist>
			<Form>
				<FormField>
					<Input
						onChange={setValue}
						name={"name"}
						label={"Give your league a name"}
						type={"text"}
						placeholder={"Name"}
						defaultValue={formState.name}
						required={true}
						minLength={3}
						maxLength={40}
					/>
				</FormField>
				<FormField className={"privacy-cell"}>
					<label>{"Privacy settings"}</label>
					<FormRadioButtons
						onChange={setValue}
						disabled={isH2HLeague}
						form_value={formState.privacy}
						name={"privacy"}
						activeColor={"#000"}
						options={[
							{value: LeaguePrivacy.private, text: "Private"},
							{value: LeaguePrivacy.public, text: "Public"},
						]}
					/>
				</FormField>
				<FormField>
					<Select
						onChange={setValue}
						name={"startRoundId"}
						label={"Starting round"}
						// defaultValue={formState.startRoundId}
						defaultValue={formState.startRoundId}
						className={"select"}>
						<option value={""} disabled={true}>
							{"Select league start round"}
						</option>
						{rounds.map((round) => (
							<option key={round.id} value={round.id}>
								{`Match Day ${round.number}`}
							</option>
						))}
					</Select>
				</FormField>
				<FormField>
					<Select
						onChange={setValue}
						name={"type"}
						label={"Type of League"}
						defaultValue={formState.type}
						className={"select"}>
						<option value={""} disabled={true}>
							{"Select type of your league"}
						</option>
						{typeOfLeague.map((type, index) => (
							<option key={index} value={type.value}>
								{type.name}
							</option>
						))}
					</Select>
				</FormField>
				<FormField>
					<Select
						key={Number(isH2HLeague)}
						onChange={setValue}
						name={"size"}
						label={"League size"}
						defaultValue={formState.size}
						className={"select"}>
						<option value={""} disabled={true}>
							{"Number"}
						</option>
						{range(evenOrOdd, leagueMaxSize + evenOrOdd, evenOrOdd).map((el, index) => (
							<option key={index} value={el}>
								{el}
							</option>
						))}
					</Select>
				</FormField>
				<Exist when={!routeLeagueID}>
					<Exist when={isCreated}>
						<ConfirmButton
							type="submit"
							disabled={isCreated}
							color={theme.secondary}
							hover_color={theme.secondaryActive}
							onClick={handlerUpdateLeague}>
							{createBtnLabel}
						</ConfirmButton>
					</Exist>
					<Exist when={!isCreated}>
						<ConfirmButton
							type="submit"
							disabled={!isDisabled}
							color={theme.secondary}
							hover_color={theme.secondaryActive}
							onClick={handlerCreateLeague}>
							{createBtnLabel}
						</ConfirmButton>
					</Exist>
				</Exist>
				<Exist when={Boolean(routeLeagueID)}>
					<ConfirmButton
						type="submit"
						disabled={isDisabledUpdate}
						color={theme.secondary}
						hover_color={theme.secondaryActive}
						onClick={handlerUpdateLeague}>
						{updateButtonLabel}
					</ConfirmButton>
					<RemoveLeagueButton
						color={"#000"}
						hover_color={"#000"}
						type={"button"}
						onClick={toggleRemoveModal}>
						Remove this league
					</RemoveLeagueButton>
					<RemoveText>If you remove this league this action is permanent</RemoveText>
				</Exist>
			</Form>
		</Fragment>
	);
};
