import {IIconProps} from "modules/types";
import React from "react";

export const IconDragArrow: React.FC<IIconProps> = ({width = 14, height = 10, color = "#000"}) => (
	<svg
		width={`${width}px`}
		height={`${height}px`}
		viewBox="0 0 14 10"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg">
		<title>Group</title>
		<g id="Symbols" stroke="none" fill="none">
			<g id="3.0-Icons-/-Trade" transform="translate(-3.000000, -5.000000)" fill={color}>
				<g id="Group" transform="translate(3.000000, 5.000000)">
					<g id="Trade-Out-Arrow" transform="translate(4.000000, 0.000000)">
						<polygon
							id="Path-Copy-10"
							points="5 0 4.125 0.875 7.625 4.375 0 4.375 0 5.625 7.625 5.625 4.125 9.125 5 10 10 5"
						/>
					</g>
					<g id="Trade-In-Arrow">
						<polygon
							id="Path-Copy-10"
							transform="translate(5.000000, 5.000000) scale(-1, 1) translate(-5.000000, -5.000000) "
							points="5 0 4.125 0.875 7.625 4.375 0 4.375 0 5.625 7.625 5.625 4.125 9.125 5 10 10 5"
						/>
					</g>
				</g>
			</g>
		</g>
	</svg>
);
