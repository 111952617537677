import React, {AllHTMLAttributes, SyntheticEvent, useCallback, useState} from "react";
import logo_modal from "assets/img/logo-horizont.svg";
import theme from "assets/css/theme";
import {Modal} from "components/Modal";
import {Form, Label, LoginButton, ModalLogo, ModalText, RowCheckbox} from "pages/Login";
import {userLoginByTerms} from "modules/actions/user";
import {useDispatch} from "react-redux";
import styled from "styled-components";
import checkMarkIcon from "assets/img/icon/checked.svg";

const ModalBody = styled.div``;
const ModalTitle = styled.h3`
	display: block;
	width: 100%;
	max-width: 225px;
	margin: 0 auto 10px;
	color: #000000;
	font-family: "StyreneA-Bold", sans-serif;
	font-size: 14px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 17px;
	text-align: center;
`;

const BaseCheckbox: React.FC<AllHTMLAttributes<HTMLInputElement>> = ({id, className, ...rest}) => (
	<BaseCheckboxLabel className={className} htmlFor={id}>
		<input id={id} {...rest} />
		<span />
	</BaseCheckboxLabel>
);

const BaseCheckboxLabel = styled.label`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	position: relative;

	span {
		cursor: pointer;
		background-color: #fff;
		border: 1px solid #000;
		display: block;
		width: 25px;
		height: 25px;
		box-sizing: border-box;
	}

	input {
		position: absolute;
		margin: 0;
		padding: 0;
		opacity: 0;
		cursor: pointer;

		&:checked + span {
			background: url(${checkMarkIcon}) no-repeat center center ${theme.primary};
			border: 1px solid ${theme.primary};
		}

		&:disabled + span {
			opacity: 0.75;
			cursor: default;
		}
	}
`;

const Checkbox = styled(BaseCheckbox)`
	margin-right: 10px;
`;

Checkbox.defaultProps = {
	type: "checkbox",
};

export const LoginByTermsModal: React.FC = () => {
	const dispatch = useDispatch();
	const [isDisabled, setIsDisabled] = useState(true);
	const [formState, changeFormState] = useState({
		terms: false,
		isNotificationsEnabled: false,
	});

	// useEffect(() => {
	// 	dispatch(userLoginByTerms({}));
	// }, [dispatch]);

	const handleInputChange = (e: SyntheticEvent<HTMLInputElement>) => {
		const {name} = e.currentTarget;

		changeFormState({
			...formState,
			[name]: e.currentTarget.checked,
		});
	};

	const handlerFormChange = useCallback((e: React.FormEvent<HTMLFormElement>) => {
		const validForm = e.currentTarget.checkValidity();
		if (validForm) {
			setIsDisabled(false);
		} else {
			setIsDisabled(true);
		}
	}, []);

	const handlerLogin = useCallback(
		(e: React.FormEvent<HTMLFormElement>) => {
			e.preventDefault();
			dispatch(userLoginByTerms(formState));
		},
		[dispatch, formState]
	);

	return (
		<Modal
			hide_close={true}
			outerStyles={{alignItems: "center"}}
			modalStyles={{maxHeight: "460px"}}>
			<ModalBody>
				<ModalLogo src={logo_modal} alt={"Logo"} />
				<ModalTitle>To continue, please accept the terms and conditions</ModalTitle>
				<ModalText>
					Welcome to the 20-Ball Predictor! A game in which you can predict the events of
					every Hundred match that is played this summer. For each match in the men and
					women’s competition of the Hundred, we will provide you with the opportunity to
					answer questions, collect points and put yourself in the running to win exciting
					prizes! To take part in the 20-Ball Predictor, you must accept the{" "}
					<a href={"/help:terms"} rel="noreferrer">
						Terms & Conditions
					</a>
					{"."}
				</ModalText>
				<Form onChange={handlerFormChange} onSubmit={handlerLogin}>
					<RowCheckbox>
						<Checkbox
							name="terms"
							id="terms"
							required={true}
							onChange={handleInputChange}
						/>
						<Label htmlFor="terms">
							By selecting this box I agree to the{" "}
							<a href={"/help:terms"} rel="noreferrer">
								Terms & Conditions
							</a>{" "}
							for the 20-Ball Predictor.
						</Label>
					</RowCheckbox>
					<RowCheckbox>
						<Checkbox
							name="isNotificationsEnabled"
							id="notification"
							required={false}
							onChange={handleInputChange}
						/>
						<Label htmlFor="notification">
							I would like to receive offers, promotions and services (the sponsor of
							the game) and its group companies by email.
						</Label>
					</RowCheckbox>
					<RowCheckbox>
						<Checkbox name="data" id="data" required={false} value="true" />
						<Label htmlFor="data">
							I would like to receive offers, promotions, and services from Genius
							Sports Group (the provider of the game) and its group companies by
							email. Visit{" "}
							<a
								href={"https://geniussports.com/"}
								target={"_blank"}
								rel="noreferrer">
								geniussports.com
							</a>{" "}
							for more information.
						</Label>
					</RowCheckbox>
					<LoginButton color={theme.primary} disabled={isDisabled} type={"submit"}>
						Yes accept
					</LoginButton>
				</Form>
			</ModalBody>
		</Modal>
	);
};
