import {SagaIterator} from "redux-saga";
import {call, put} from "typed-redux-saga";
import {fetchTournamentJSONSuccess, showGlobalError} from "modules/actions";
import {Api} from "modules/utils/Api";

export const fetchTournamentJSONSaga = function* (): SagaIterator {
	try {
		const response = yield* call(Api.JSON.tournament);
		yield* put(fetchTournamentJSONSuccess(response));
	} catch (err) {
		yield* put(showGlobalError(err as Error));
	}
};
