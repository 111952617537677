import {createAction} from "redux-act";
import {ApiError} from "modules/utils/Api";
import {Direction, OrderRanking} from "modules/enums";
import {
	ICurrentUserRanking,
	IFilterH2HRankings,
	IFilterRankings,
	IOverallRankingResponse,
} from "modules/reducers";
import {ILeagueId} from "modules/actions/leagues";

export interface IRoundId {
	roundId: number;
}

export interface IRankingResponse {
	ranking: IRanking;
}

export interface IRanking {
	userId: number;
	roundId: number;
	firstName: string;
	lastName: string;
	roundPoints: number;
	points: number;
	rank: number;
}

export const getRoundPoints = createAction<IRoundId>();
export const getRoundPointsSuccess = createAction<IRanking>();
export const getRoundPointsError = createAction<ApiError>();

export interface IOverallRankingPayload {
	// leagueId?: number;
	roundId?: number;
	limit?: number;
	page?: number;
	order?: OrderRanking;
	direction?: Direction;
}

export const getOverallRankings = createAction<IOverallRankingPayload>();
export const getOverallRankingsSuccess = createAction<IOverallRankingResponse>();
export const getOverallRankingsError = createAction<ApiError>();

export interface IRegularLeagueRankingPayload {
	leagueId: number;
	roundId?: number;
	limit?: number;
	page?: number;
	order?: OrderRanking;
	direction?: Direction;
}

export const getRegularLeagueRankings = createAction<IRegularLeagueRankingPayload>();
export const getRegularLeagueRankingsSuccess = createAction<IOverallRankingResponse>();
export const getRegularLeagueRankingsError = createAction<ApiError>();

export interface IOverallRankingResponseMore {
	currentUserRanking?: ICurrentUserRanking;
	rankings: IRanking[];
	nextPage: boolean;
	filter: Partial<IFilterRankings>;
}

export interface ILeagueH2HRankings {
	currentUserRanking?: IPlayerRanking;
	rankings: IPlayerRanking[];
	nextPage: boolean;
}

export interface IPlayerRanking {
	userId: number;
	firstName: string;
	lastName: string;
	points: number;
	rank: number;
	wins: number;
	ties: number;
	losses: number;
	rounds: number;
}

export const getH2HRankings = createAction<ILeagueId>();
export const getH2HRankingsSuccess = createAction<ILeagueH2HRankings>();
export const getH2HRankingsError = createAction<ApiError>();

export const updateFilter = createAction<Partial<IFilterRankings>>();
export const updateH2HFilter = createAction<Partial<IFilterH2HRankings>>();
export const loadMoreRankings = createAction<Partial<IOverallRankingPayload>>();
export const loadMoreLeagueRankings = createAction<IRegularLeagueRankingPayload>();
export const loadMoreRankingsSuccess = createAction<IOverallRankingResponseMore>();
export const clearFilter = createAction();
export const clearRankings = createAction();
