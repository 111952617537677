import {partial, size} from "lodash";
import {QuestionType, UnitStatus} from "modules/enums";
import React, {Fragment, useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import theme from "assets/css/theme";
import {useDispatch, useSelector} from "react-redux";
import {getRoundById} from "modules/selectors/rounds";
import {resetPostStatus} from "modules/actions";
import {MatchDaySectionUnits} from "components/MatchDaySectionUnits";

interface IProps {
	changeTabs: (tabsStatus: QuestionType) => void;
	activeTab: QuestionType;
}

interface IItemActive {
	isActive: boolean;
}

const TabContainer = styled.div`
	width: 100%;
	&.double_unit {
		position: relative;
		padding-top: 62px;
	}
`;
const TabNav = styled.nav`
	width: 100%;
	height: 48px;
	display: flex;
	justify-content: center;
	background-color: #fff;
	position: relative;
	z-index: 2;
`;
const NavItem = styled.div<IItemActive>`
	cursor: pointer;
	color: #000000;
	height: 100%;
	font-family: "StyreneA-Regular", sans-serif;
	font-size: 14px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 17px;

	display: flex;
	flex: 1 1 50%;
	justify-content: center;
	align-items: center;
	padding: 15px 15px;
	box-sizing: border-box;

	&.women {
		background-color: ${(props) => (props.isActive ? theme.secondary : "transparent")};
		color: ${(props) => (props.isActive ? "#FFF" : theme.secondary)};
		border-bottom: 4px solid ${theme.secondary};
	}

	&.men {
		background-color: ${(props) => (props.isActive ? theme.primary : "transparent")};
		color: ${(props) => (props.isActive ? "#FFF" : theme.primary)};
		border-bottom: 4px solid ${theme.primary};
	}
`;
const TabSection = styled.div<IItemActive>`
	box-sizing: border-box;
	width: 100%;
	max-width: unset;
	display: ${(props) => (props.isActive ? "block" : "none")};
`;

const TabsNavigation: React.FC<IProps> = ({changeTabs, activeTab}) => {
	const isActive = (tab: QuestionType) => {
		return activeTab === tab;
	};

	return (
		<TabNav>
			<NavItem
				className={QuestionType.Women}
				onClick={partial(changeTabs, QuestionType.Women)}
				isActive={isActive(QuestionType.Women)}>
				Women
			</NavItem>
			<NavItem
				className={QuestionType.Men}
				onClick={partial(changeTabs, QuestionType.Men)}
				isActive={isActive(QuestionType.Men)}>
				Men
			</NavItem>
		</TabNav>
	);
};

interface ITabsProps {
	matchDayId?: number;
	onErrorModal: (errorSend: boolean) => void;
	onEarlyVictory: (finish: boolean) => void;
	getTabStatus: (tabsStatus: QuestionType) => void;
}

export const TabsMenWomen: React.FC<ITabsProps> = ({
	matchDayId = 0,
	onErrorModal,
	onEarlyVictory,
	getTabStatus,
}) => {
	const dispatch = useDispatch();
	const currentRound = useSelector(getRoundById)(matchDayId);
	const [tabsStatus, setTabsStatus] = useState(QuestionType.Women);

	const unitsCurrentRound = currentRound ? currentRound.units : [];

	const womenUnits = unitsCurrentRound.filter((unit) => unit.type === QuestionType.Women);
	const menUnits = unitsCurrentRound.filter((unit) => unit.type === QuestionType.Men);

	const hasPlayingWomenUnits = size(
		womenUnits.filter((unit) => unit.status === UnitStatus.playing)
	);

	const onSetTabStatus = useCallback(
		(tabsStatus) => {
			getTabStatus(tabsStatus);
		},
		[getTabStatus]
	);

	const onChange = useCallback(
		(tabsStatus) => {
			setTabsStatus(tabsStatus);
			onSetTabStatus(tabsStatus);
			dispatch(resetPostStatus());
		},
		[dispatch, setTabsStatus, onSetTabStatus]
	);

	useEffect(() => {
		if (hasPlayingWomenUnits) {
			setTabsStatus(QuestionType.Women);
		} else {
			setTabsStatus(QuestionType.Men);
		}
	}, [hasPlayingWomenUnits]);

	return (
		<Fragment>
			<TabContainer>
				{unitsCurrentRound.length > 1 ? (
					<Fragment>
						<TabsNavigation changeTabs={onChange} activeTab={tabsStatus} />
						<TabSection isActive={tabsStatus === QuestionType.Women}>
							{womenUnits.map((womenUnit, index) => (
								<Fragment key={index}>
									<MatchDaySectionUnits
										unit={womenUnit}
										roundId={matchDayId}
										index={index}
										onErrorModal={onErrorModal}
										onEarlyVictory={onEarlyVictory}
									/>
								</Fragment>
							))}
						</TabSection>
						<TabSection isActive={tabsStatus === QuestionType.Men}>
							{menUnits.map((menUnit, index) => (
								<Fragment key={index}>
									<MatchDaySectionUnits
										unit={menUnit}
										roundId={matchDayId}
										index={index}
										onErrorModal={onErrorModal}
										onEarlyVictory={onEarlyVictory}
									/>
								</Fragment>
							))}
						</TabSection>
					</Fragment>
				) : (
					<Fragment>
						<TabSection isActive={true}>
							{unitsCurrentRound.map((unit, index) => (
								<Fragment key={index}>
									<MatchDaySectionUnits
										unit={unit}
										roundId={matchDayId}
										index={index}
										onErrorModal={onErrorModal}
										onEarlyVictory={onEarlyVictory}
										class={"unit_one"}
									/>
								</Fragment>
							))}
						</TabSection>
					</Fragment>
				)}
			</TabContainer>
		</Fragment>
	);
};
