import React, {useCallback} from "react";
import styled from "styled-components";
import {Container} from "components/BaseElements";
import {Link, useHistory} from "react-router-dom";
import theme from "assets/css/theme";
import logo from "assets/img/logo-horizont.svg";
import headerBg from "assets/img/header_bg.png";
import {IconArrow} from "components/Icons";
import {ReactComponent as IconTutorialButton} from "assets/img/icon/icon_tutorial_btn.svg";
import {useDispatch} from "react-redux";
import {openTutorialViewedForce} from "modules/actions/user";
import {useLocation} from "react-router";
import {eq} from "lodash";
import {Exist} from "components/Exist";

const HeaderWrapper = styled.header`
	height: 76px;
	width: 100%;
	background: #000;
`;
const HeaderContainer = styled(Container)`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 5px 0 15px;
	height: 100%;
	position: relative;
	background: url(${headerBg}) no-repeat right bottom transparent;
`;
const MoveBack = styled.div`
	position: relative;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-top: 5px;
	margin-left: 20px;
	cursor: pointer;

	&:hover {
		p {
			color: ${theme.primary};
		}
	}

	p {
		margin-left: 6px;
		color: #ffffff;
		font-family: "StyreneA-Regular", sans-serif;
		font-size: 12px;
		letter-spacing: 0;
		line-height: 15px;
		transition: all ease 0.3s;
	}
`;
const IconBack = styled.div`
	transform: rotate(90deg);
`;
const LogoWrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	position: absolute;
	top: 50%;
	left: 48%;
	transform: translate(-50%, -50%);
`;
const LogoLink = styled(Link)`
	display: block;
	width: 140px;
	margin-right: 10px;
`;
const Logo = styled.img`
	display: block;
	width: 100%;
`;
const LogoAfter = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: row wrap;
	width: 60px;
	font-family: "Hundred-Display-Web", sans-serif;

	div {
		width: 100%;
		background: ${theme.secondary};
		color: #000;
		text-align: center;
		text-transform: uppercase;
		font-size: 13px;
		padding: 2px 0;
	}

	p {
		width: 100%;
		color: #fff;
		text-transform: uppercase;
		font-size: 10px;
		letter-spacing: 0.8px;
		text-align: center;
	}
`;
const TutorialButton = styled.div`
	position: absolute;
	right: 12px;
	top: 40%;
	transform: translateY(-50%);
	width: 26px;
	height: 26px;
	cursor: pointer;
`;

export const Header: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const goBack = () => {
		history.goBack();
	};
	const isHomePage = eq(location.pathname, "/");

	const openTutorialForce = useCallback(() => {
		dispatch(openTutorialViewedForce());
	}, [dispatch]);

	return (
		<HeaderWrapper>
			<HeaderContainer>
				<Exist when={!isHomePage}>
					<MoveBack onClick={goBack}>
						<IconBack>
							<IconArrow color={theme.primary} />
						</IconBack>
						{/*<p>Back</p>*/}
					</MoveBack>
				</Exist>
				<LogoWrapper>
					<LogoLink to="/">
						<Logo src={logo} alt="ecb-hundred-predictor" />
					</LogoLink>
					<LogoAfter>
						<div>20-ball</div>
						<p>Predictor</p>
					</LogoAfter>
				</LogoWrapper>
				<Exist when={isHomePage}>
					<TutorialButton onClick={openTutorialForce}>
						<IconTutorialButton />
					</TutorialButton>
				</Exist>
			</HeaderContainer>
		</HeaderWrapper>
	);
};
