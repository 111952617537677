import {IIconProps} from "modules/types";
import React from "react";

interface IProps extends IIconProps {
	fillPercent?: number;
}

export const IconCircle: React.FC<IProps> = (props) => {
	const {width = 24, height = 24, color = "#000", fillPercent = 0} = props;
	const dashArray = 69.11503837897544;
	const filledOffset = dashArray - (dashArray / 100) * fillPercent;

	return (
		<svg
			width={`${width}px`}
			height={`${height}px`}
			viewBox="0 0 24 24"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg">
			<title>Group</title>
			<g id="Symbols" stroke="none" fill="none">
				<circle
					id="Oval"
					cx="12"
					cy="12"
					r="11"
					stroke="#cdcdcd"
					strokeDasharray={dashArray}
				/>
				<circle
					id="Oval"
					cx="12"
					cy="12"
					r="11"
					stroke={color}
					strokeDashoffset={filledOffset}
					strokeDasharray={dashArray}
					transform="translate(0 24) rotate(-90 0 0)"
				/>
				<path
					d="M1,12 C1,18.0751322 5.92486775,23 12,23 C18.0751322,23 23,18.0751322 23,12 C23,5.92486775 18.0751322,1 12,1"
					id="Path"
				/>
			</g>
		</svg>
	);
};
