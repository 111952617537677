const theme = {
	primary: "#32C84B",
	primaryActive: "#289F3C",
	secondary: "#FF0082",
	secondaryActive: "#D6006D",
	accent: "#0000FF",
	contentWidth: "1024px",
	baseFont: "'StyreneA-Regular', sans-serif",
	boldFont: "'StyreneA-Bold', sans-serif",
	secondFont: "'Hundred-Display-Web', sans-serif",
};

export type ITheme = typeof theme;
export default theme;
