import React from "react";
import styled from "styled-components";
import {Direction, OrderRanking} from "modules";
import {partial} from "lodash";
import {IconOrderArrow} from "components/Icons";

interface ICellProps {
	isActive?: boolean;
}

const Cell = styled.div<ICellProps>`
	box-sizing: border-box;
	padding: 5px 10px;
	color: #000000;
	font-family: "StyreneA-Regular", sans-serif;
	font-size: 10px;
	letter-spacing: 0;
	line-height: 13px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 0 0 auto;
	background-color: ${(props) => (props.isActive ? "#f1f1f1" : "transparent")};
	transition: all ease 0.3s;

	//&:hover {
	//	background-color: #442A70;
	//}
`;
const TableHeader = styled.div`
	height: 54px;
	//background-color: #6e5a92;
	width: 100%;
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid #dbdbdb;
`;
const PositionCell = styled(Cell)`
	//font-weight: bold;
	text-transform: uppercase;
	justify-self: flex-start;
	flex: 2 1 175px;
	justify-content: flex-start;
`;
const PointsCell = styled(Cell)`
	//font-weight: bold;
	text-transform: uppercase;
	justify-self: flex-start;
	flex: 1 1 75px;
`;
const TotalPointsCell = styled(Cell)`
	font-family: "StyreneA-Bold", sans-serif;
	text-transform: uppercase;
	justify-self: flex-start;
	flex: 1 1 75px;
`;

interface IPropsOrder {
	order: OrderRanking;
	orderDirection: Direction;
	onChangeOrder: (value: OrderRanking) => void;
}

export const RankingsTableHeader: React.FC<Readonly<IPropsOrder>> = (props) => {
	const {order, orderDirection, onChangeOrder} = props;

	const isActiveOrder = (checkValue: OrderRanking) => {
		return checkValue === order;
	};

	return (
		<TableHeader>
			<PositionCell
				isActive={isActiveOrder(OrderRanking.Rank)}
				onClick={partial(onChangeOrder, OrderRanking.Rank)}>
				<IconOrderArrow
					isActive={isActiveOrder(OrderRanking.Rank)}
					sortOrder={orderDirection}>
					Rank username
				</IconOrderArrow>
			</PositionCell>
			<PointsCell
				isActive={isActiveOrder(OrderRanking.RoundPoints)}
				onClick={partial(onChangeOrder, OrderRanking.RoundPoints)}>
				<IconOrderArrow
					isActive={isActiveOrder(OrderRanking.RoundPoints)}
					sortOrder={orderDirection}>
					Match pts
				</IconOrderArrow>
			</PointsCell>
			<TotalPointsCell
				isActive={isActiveOrder(OrderRanking.Points)}
				onClick={partial(onChangeOrder, OrderRanking.Points)}>
				<IconOrderArrow
					isActive={isActiveOrder(OrderRanking.Points)}
					sortOrder={orderDirection}>
					Total pts
				</IconOrderArrow>
			</TotalPointsCell>
		</TableHeader>
	);
};
