import {fetchVenuesJSONSuccess, IVenue} from "modules/actions";
import {createReducer} from "redux-act";

export interface IVenuesReducer {
	venues: IVenue[];
}

const initialState: IVenuesReducer = {
	venues: [],
};

export const venues = createReducer<IVenuesReducer>({}, initialState).on(
	fetchVenuesJSONSuccess,
	(state, venues) => ({
		...state,
		venues,
	})
);
