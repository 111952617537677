import React, {AllHTMLAttributes, SyntheticEvent, useCallback, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getIsRecover} from "modules/selectors/user";
import logo_modal from "assets/img/logo-horizont.svg";
import theme from "assets/css/theme";
import {Modal, ModalBody, ModalTitle} from "components/Modal";
import styled from "styled-components";
import checkMarkIcon from "assets/img/icon/checked.svg";
import {Form, Label, LoginButton, ModalLogo, ModalText, RowCheckbox} from "pages/Login";
import {userRecover} from "modules/actions/user";

const BaseCheckbox: React.FC<AllHTMLAttributes<HTMLInputElement>> = ({id, className, ...rest}) => (
	<BaseCheckboxLabel className={className} htmlFor={id}>
		<input id={id} {...rest} />
		<span />
	</BaseCheckboxLabel>
);

const BaseCheckboxLabel = styled.label`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	position: relative;

	span {
		cursor: pointer;
		background-color: #fff;
		border: 1px solid #000;
		display: block;
		width: 25px;
		height: 25px;
		box-sizing: border-box;
	}

	input {
		position: absolute;
		margin: 0;
		padding: 0;
		opacity: 0;
		cursor: pointer;

		&:checked + span {
			background: url(${checkMarkIcon}) no-repeat center center ${theme.primary};
			border: 1px solid ${theme.primary};
		}

		&:disabled + span {
			opacity: 0.75;
			cursor: default;
		}
	}
`;

const Checkbox = styled(BaseCheckbox)`
	margin-right: 10px;
`;

Checkbox.defaultProps = {
	type: "checkbox",
};

export const RecoverModal: React.FC = () => {
	const dispatch = useDispatch();
	const isRecover = useSelector(getIsRecover);
	const [formState, changeFormState] = useState({
		isNotificationsEnabled: false,
	});

	const handleInputChange = (e: SyntheticEvent<HTMLInputElement>) => {
		const {name} = e.currentTarget;

		changeFormState({
			...formState,
			[name]: e.currentTarget.checked,
		});
	};

	const handlerRecover = useCallback(
		(e: React.FormEvent<HTMLFormElement>) => {
			e.preventDefault();
			dispatch(userRecover(formState));
		},
		[dispatch, formState]
	);

	return isRecover ? null : (
		<Modal hide_close={true}>
			<ModalBody>
				<ModalLogo src={logo_modal} alt={"Logo"} />
				<ModalTitle>To continue, please accept the terms and conditions</ModalTitle>
				<ModalText>
					Welcome to the 20-Ball Predictor! A game in which you can predict the events of
					every Hundred match that is played this summer. For each match in the men and
					women’s competition of the Hundred, we will provide you with the opportunity to
					answer questions, collect points and put yourself in the running to win exciting
					prizes! To take part in the 20-Ball Predictor, you must accept the Terms and
					Conditions
				</ModalText>
				<Form onSubmit={handlerRecover}>
					{/*<RowCheckbox>*/}
					{/*	<Checkbox*/}
					{/*		name="terms"*/}
					{/*		id="terms"*/}
					{/*		required={true}*/}
					{/*		onChange={handleInputChange}*/}
					{/*	/>*/}
					{/*	<Label htmlFor="terms">*/}
					{/*		By selecting this box I agree to the Terms and Conditions for the*/}
					{/*		20-Ball Predictor for the Hundred.*/}
					{/*	</Label>*/}
					{/*</RowCheckbox>*/}
					<RowCheckbox>
						<Checkbox
							name="isNotificationsEnabled"
							id="notification"
							required={false}
							onChange={handleInputChange}
						/>
						<Label htmlFor="notification">
							I would like to receive offers, promotions and services (the sponsor of
							the game) and its group companies by email.
						</Label>
					</RowCheckbox>
					{/*<RowCheckbox>*/}
					{/*	<Checkbox name="data" id="data" required={false} value="true" />*/}
					{/*	<Label htmlFor="data">*/}
					{/*		I would like to receive offers, promotions and services from FanHub*/}
					{/*		Media UK Ltd (the provider of the game) and its group companies by*/}
					{/*		email. Visit{" "}*/}
					{/*		<a*/}
					{/*			href={"https://fanhubmedia.com"}*/}
					{/*			target={"_blank"}*/}
					{/*			rel="noreferrer">*/}
					{/*			fanhubmedia.com*/}
					{/*		</a>{" "}*/}
					{/*		for more information about FanHub.*/}
					{/*	</Label>*/}
					{/*</RowCheckbox>*/}
					<LoginButton color={theme.primary} type={"submit"}>
						Accept
					</LoginButton>
				</Form>
			</ModalBody>
		</Modal>
	);
};
