import React from "react";
import styled from "styled-components";
import {Exist} from "components/Exist";
import {differenceInDays, differenceInHours, differenceInMinutes, isBefore} from "date-fns";

const StartMatchDay = styled.div`
	width: 100%;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	font-family: "StyreneA-Regular", sans-serif;
	font-size: 20px;
	color: #fff;
	padding: 0 20px;
	text-transform: uppercase;
	text-align: center;
	strong {
		font-family: "StyreneA-Bold", sans-serif;
		font-weight: bold;
		display: inline-block;
	}
`;

interface IProps {
	time: number;
}

export const BottomCountDownTimer: React.FC<IProps> = ({time = 0}) => {
	const days = differenceInDays(time, Date.now());
	const hours = !days && differenceInHours(time, Date.now());
	const minutes = !hours && !days && differenceInMinutes(time, Date.now());
	const isFutureTime = isBefore(Date.now(), time);

	return (
		<StartMatchDay>
			<p>
				<strong>
					<Exist when={isFutureTime}>
						<Exist when={!!days}>
							{days} {days === 1 ? "Day" : "Days"}
						</Exist>
						<Exist when={!!hours}>
							{hours} {hours === 1 ? "Hour" : "Hours"}
						</Exist>
						<Exist when={!!minutes}>
							{minutes} {minutes === 1 ? "Minute" : "Minutes"}
						</Exist>
					</Exist>

					<Exist when={!isFutureTime}>
						Questions for the 20-Ball Predictor will be available shortly
					</Exist>
				</strong>
			</p>
		</StartMatchDay>
	);
};
