import {
	getH2HRankings,
	getH2HRankingsSuccess,
	getOverallRankings,
	getOverallRankingsSuccess,
	getRegularLeagueRankings,
	getRegularLeagueRankingsSuccess,
	getRoundPoints,
	getRoundPointsSuccess,
	loadMoreLeagueRankings,
	loadMoreRankings,
	loadMoreRankingsSuccess,
	setGlobalPreloaderState,
	showGlobalError,
} from "modules/actions";
import {SagaIterator} from "redux-saga";
import {call, put} from "typed-redux-saga";
import {Api} from "modules/utils/Api";

export const getRoundPointsSaga = function* ({
	payload,
}: ReturnType<typeof getRoundPoints>): SagaIterator {
	try {
		const response = yield* call(Api.Rankings.get_round_rankings, payload);

		yield* put(getRoundPointsSuccess(response.success.ranking));
	} catch (err) {
		yield* put(showGlobalError(err as Error));
	}
};

export const getOverallRankingsSaga = function* ({
	payload,
}: ReturnType<typeof getOverallRankings>): SagaIterator {
	try {
		yield* put(setGlobalPreloaderState(true));
		const response = yield* call(Api.Rankings.get_overall_rankings, payload);

		yield* put(getOverallRankingsSuccess(response.success));
	} catch (err) {
		yield* put(showGlobalError(err as Error));
	}
	yield* put(setGlobalPreloaderState(false));
};

export const loadMoreRankingsSaga = function* ({
	payload,
}: ReturnType<typeof loadMoreRankings>): SagaIterator {
	try {
		yield* put(setGlobalPreloaderState(true));
		const response = yield* call(Api.Rankings.get_overall_rankings, payload);

		yield* put(loadMoreRankingsSuccess({...response.success, filter: {...payload}}));
	} catch (err) {
		yield* put(showGlobalError(err as Error));
	}
	yield* put(setGlobalPreloaderState(false));
};

export const loadMoreLeagueRankingsSaga = function* ({
	payload,
}: ReturnType<typeof loadMoreLeagueRankings>): SagaIterator {
	try {
		const response = yield* call(Api.Rankings.get_league_rankings, payload);

		yield* put(loadMoreRankingsSuccess({...response.success, filter: {...payload}}));
	} catch (err) {
		yield* put(showGlobalError(err as Error));
	}
	yield* put(setGlobalPreloaderState(false));
};

export const getRegularLeagueRankingsSaga = function* ({
	payload,
}: ReturnType<typeof getRegularLeagueRankings>): SagaIterator {
	try {
		yield* put(setGlobalPreloaderState(true));
		const response = yield* call(Api.Rankings.get_league_rankings, payload);

		yield* put(getRegularLeagueRankingsSuccess(response.success));
	} catch (err) {
		yield* put(showGlobalError(err as Error));
	}
	yield* put(setGlobalPreloaderState(false));
};

export const getH2HRankingsSaga = function* ({
	payload,
}: ReturnType<typeof getH2HRankings>): SagaIterator {
	try {
		yield* put(setGlobalPreloaderState(true));
		const response = yield* call(Api.Rankings.h2h_rankings, payload);

		console.log("getH2HRANKINGS---", response);

		yield* put(getH2HRankingsSuccess(response.success));
	} catch (err) {
		yield* put(showGlobalError(err as Error));
	}
	yield* put(setGlobalPreloaderState(false));
};
