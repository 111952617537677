import {
	actionEnableGodMode,
	closeTutorialViewedForce,
	openTutorialViewedForce,
	requestCurrentUser,
	requestCurrentUserFailed,
	requestCurrentUserSuccess,
	setTutorialViewed,
	setTutorialViewedSuccess,
	userLoginByBackdoor,
	userLoginByTermsSuccess,
	userLoginError,
	userLoginSuccess,
	userRecover,
	userRecoverSuccess,
} from "modules/actions/user";
import {RequestState} from "modules/enums";
import {ApiError} from "modules/utils/Api";
import {createReducer} from "redux-act";

export interface IUser {
	id: number;
	oktaId: string;
	firstName: string;
	lastName: string;
	email: string;
	createdAt: string;
	isNotificationsEnabled: boolean;
	isRecovered: boolean;
	isTutorialViewed: boolean;
}

export interface IUserReducer {
	user?: IUser;
	error?: string;
	requestState: RequestState;
	sessionCheckedState: RequestState;
	logoutWasCalled: boolean;
	passwordUpdated: boolean;
	userInfoUpdated: boolean;
	isTutorialViewedForced: boolean;
	godMode: boolean;
}

const defaultState: IUserReducer = {
	requestState: RequestState.IDLE,
	sessionCheckedState: RequestState.IDLE,
	logoutWasCalled: false,
	passwordUpdated: false,
	userInfoUpdated: false,
	isTutorialViewedForced: false,
	godMode: false,
};

const onError = (state: IUserReducer, error: ApiError) => ({
	...state,
	error: error.message,
	requestState: RequestState.Received,
});

const onAuthRequest = (state: IUserReducer) => ({
	...state,
	requestState: RequestState.Requested,
	logoutWasCalled: false,
});

const onUserLoginSuccess = (state: IUserReducer, user: IUser) => ({
	...state,
	user,
	isAuthorized: true,
	error: undefined,
	requestState: RequestState.Received,
});

export const user = createReducer<IUserReducer>({}, defaultState)
	/**
	 * Get User Login just BACKDOOR Now
	 */
	.on(userLoginByBackdoor, onAuthRequest)
	.on(userLoginSuccess, onUserLoginSuccess)
	.on(userLoginByTermsSuccess, onUserLoginSuccess)
	.on(userLoginError, onError)
	/**
	 * Get current user
	 */
	.on(requestCurrentUser, (state) => ({
		...state,
		sessionCheckedState: RequestState.Requested,
		logoutWasCalled: false,
	}))
	.on(requestCurrentUserSuccess, (state, user) => ({
		...state,
		user,
		error: undefined,
		sessionCheckedState: RequestState.Received,
	}))
	.on(requestCurrentUserFailed, (state) => ({
		...state,
		sessionCheckedState: RequestState.Received,
	}))
	.on(userRecover, (state) => ({
		...state,
		sessionCheckedState: RequestState.Requested,
	}))
	.on(userRecoverSuccess, (state, user) => ({
		...state,
		user,
		sessionCheckedState: RequestState.Received,
	}))
	.on(setTutorialViewed, (state) => ({
		...state,
	}))
	.on(setTutorialViewedSuccess, (state, user) => ({
		...state,
		user,
		isTutorialViewedForced: false,
	}))
	.on(openTutorialViewedForce, (state) => ({
		...state,
		isTutorialViewedForced: true,
	}))
	.on(closeTutorialViewedForce, (state) => ({
		...state,
		isTutorialViewedForced: false,
	}))
	.on(actionEnableGodMode, (state) => ({
		...state,
		godMode: true,
	}));
