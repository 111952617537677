import React, {useRef, useState} from "react";
import styled from "styled-components";
import {CountdownCircleTimer} from "react-countdown-circle-timer";

const BallWrapper = styled.div`
	position: absolute;
	left: 50%;
	top: 50px;
	transform: translateX(-50%);
	width: 46px;
	height: 46px;
	border-radius: 50%;
	background-color: #fff;
	box-sizing: border-box;
	z-index: 29;
	display: flex;
	justify-content: center;
	align-items: center;
	.timer-wrapper {
		display: flex;
		justify-content: center;
	}

	.time-wrapper {
		position: relative;
		width: 38px;
		height: 38px;
		font-size: 16px;
		font-family: "StyreneA-Bold", sans-serif;
	}

	.time-wrapper .time {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		transform: translateY(0);
		opacity: 1;
		transition: all 0.5s;
	}

	.time-wrapper .time.up {
		opacity: 0;
		mask-image: linear-gradient(
			to bottom,
			transparent 0,
			black 75px,
			black calc(100% - 35px),
			transparent 100%
		);
		transform: translateY(-50%);
	}

	.time-wrapper .time.down {
		opacity: 0;
		mask-image: linear-gradient(
			to bottom,
			transparent 0,
			black 75px,
			black calc(100% - 35px),
			transparent 100%
		);
		transform: translateY(50%);
	}
`;

interface IRemainingTime {
	remainingTime: number;
}

const RenderTime: React.FC<IRemainingTime> = ({remainingTime}) => {
	const currentTime = useRef(remainingTime);
	const prevTime = useRef(0);
	const isNewTimeFirstTick = useRef(false);
	const [, setOneLastRerender] = useState(0);

	if (!currentTime) {
		return null;
	}

	if (currentTime.current !== remainingTime) {
		isNewTimeFirstTick.current = true;
		prevTime.current = currentTime.current;
		currentTime.current = remainingTime;
	} else {
		isNewTimeFirstTick.current = false;
	}

	// force one last re-render when the time is over to tirgger the last animation
	if (remainingTime === 0) {
		setTimeout(() => {
			setOneLastRerender((val) => val + 1);
		}, 20);
	}

	const isTimeUp = isNewTimeFirstTick.current;

	return (
		<div className="time-wrapper">
			<div key={remainingTime} className={`time ${isTimeUp ? "up" : ""}`}>
				{remainingTime}
			</div>
			{prevTime.current !== null && (
				<div key={prevTime.current} className={`time ${!isTimeUp ? "down" : ""}`}>
					{prevTime.current}
				</div>
			)}
		</div>
	);
};

interface IProps {
	duration?: number;
	playing?: boolean;
}

export const BallCounter: React.FC<IProps> = ({duration = 100, playing = true}) => {
	return (
		<BallWrapper>
			<CountdownCircleTimer
				isPlaying={playing}
				duration={duration}
				colors={"#000"}
				strokeWidth={2}
				size={38}>
				{RenderTime}
			</CountdownCircleTimer>
		</BallWrapper>
	);
};
