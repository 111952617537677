import styled, {css} from "styled-components";

export const FormField = styled.div`
	width: 100%;
	margin: 20px 0;
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
`;

export const Label = styled.label`
	display: block;
	width: 100%;
	font-size: 14px;
	color: #000;
	line-height: 18px;
	text-align: left;
	height: 18px;
	margin-bottom: 8px;
`;

export const ErrorText = styled.p`
	color: #e02020;
	font-size: 12px;
	font-weight: 500;
	letter-spacing: 0.5px;
	line-height: 18px;
`;

interface IElementWrapperProps {
	is_disabled?: boolean;
}

const getDisabledElementWrapper = ({is_disabled}: IElementWrapperProps) =>
	is_disabled &&
	css`
		> * {
			opacity: 0.7;
			cursor: not-allowed;
		}
	`;

export const ElementsWrapper = styled.div<IElementWrapperProps>`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	position: relative;
	flex-direction: row;
	flex-wrap: wrap;
	> input,
	select {
		width: 100%;
	}
	${getDisabledElementWrapper};
`;

export * from "./RadioButton";
export * from "./Input";
export * from "./Select";
export * from "./Textarea";
