import "assets/css/reset.css";
import "assets/css/fonts.css";
import "assets/css/core.css";
import React, {lazy, Suspense} from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";
import reportWebVitals from "./reportWebVitals";
import {ThemeProvider} from "styled-components";
import {Provider} from "react-redux";
import {store} from "modules/store";
import theme from "assets/css/theme";
import {BASE_URL} from "modules";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {GlobalPreloader} from "components/Preloader";
import {
	Advert,
	BaseWrapper,
	Header,
	HOCEntryData,
	MainMenu,
	NotAuthOnlyRoute,
	PrivateRoute,
	Session,
} from "components";
import {ErrorModal} from "components/ErrorModal";

Sentry.init({
	dsn: "https://7b676754a3834b61a97e12b4f612aa6d@o151969.ingest.sentry.io/5777120",
	environment: process.env.REACT_APP_SENTRY_ENV || "development",
	sampleRate: 0.1,
	allowUrls: [".thehundred.com", ".fanhubmedia.com"], // TODO: add prod URL
	integrations: [
		new Integrations.BrowserTracing(),
		new Sentry.Integrations.Breadcrumbs({
			console: false,
		}),
	],
	denyUrls: [
		"quantcast",
		"xsca",
		// browser's extensions
		/extensions\//i,
		/^chrome:\/\//i,
		/^moz-extension:\/\//i,
	],
	autoSessionTracking: true,
});

const Backdoor = lazy(() => import("pages/Backdoor"));
const Login = lazy(() => import("pages/Login"));
const Home = lazy(() => import("pages/Home"));
const Leagues = lazy(() => import("pages/Leagues"));
const LeaguesJoin = lazy(() => import("pages/leagues/LeaguesJoin"));
const LeagueCreate = lazy(() => import("pages/leagues/LeagueCreate"));
const LeagueAbout = lazy(() => import("pages/leagues/LeagueAbout"));
const LeagueMatchUp = lazy(() => import("pages/leagues/LeagueMatchUp"));
const InviteLeague = lazy(() => import("pages/leagues/InviteLeague"));
const LeagueRankings = lazy(() => import("pages/leagues/LeagueRankings"));
const Rankings = lazy(() => import("pages/Rankings"));
const Help = lazy(() => import("pages/Help"));
const Media = lazy(() => import("pages/Media"));
const Policy = lazy(() => import("pages/Policy"));
const Unsubscribe = lazy(() => import("pages/Unsubscribe"));
const ComingSoon = lazy(() => import("pages/ComingSoon"));
const NotFound = lazy(() => import("pages/NotFound"));

ReactDOM.render(
	<React.StrictMode>
		<ThemeProvider theme={theme}>
			<Provider store={store}>
				<Session>
					<Router basename={BASE_URL}>
						<HOCEntryData>
							<BaseWrapper>
								<Header />
								<Suspense fallback={<GlobalPreloader />}>
									<Switch>
										<NotAuthOnlyRoute
											exact={true}
											path="/backdoor"
											component={Backdoor}
										/>
										<NotAuthOnlyRoute
											exact={true}
											path="/login"
											component={Login}
										/>
										<PrivateRoute exact={true} path="/" component={Home} />
										<PrivateRoute
											exact={true}
											path="/leagues"
											component={Leagues}
										/>
										<PrivateRoute
											exact={true}
											path="/leagues/create"
											component={LeagueCreate}
										/>
										<PrivateRoute
											exact={true}
											path="/leagues/join/:code?"
											component={LeaguesJoin}
										/>
										<PrivateRoute
											exact={true}
											path="/leagues/:id/about"
											component={LeagueAbout}
										/>
										<PrivateRoute
											exact={true}
											path="/leagues/:id/invites"
											component={InviteLeague}
										/>
										<PrivateRoute
											exact={true}
											path="/leagues/:id/match-ups"
											component={LeagueMatchUp}
										/>
										<PrivateRoute
											exact={true}
											path="/leagues/:id/rankings"
											component={LeagueRankings}
										/>
										<PrivateRoute
											exact={true}
											path="/rankings"
											component={Rankings}
										/>
										<Route exact={true} path="/help:name?" component={Help} />
										<Route exact={true} path="/media" component={Media} />
										<Route exact={true} path="/policy" component={Policy} />
										<Route
											exact={true}
											path="/successfully-unsubscribed"
											component={Unsubscribe}
										/>
										<Route
											exact={true}
											path="/coming-soon"
											component={ComingSoon}
										/>
										<Route path="*" component={NotFound} />
									</Switch>
								</Suspense>
								<Advert />
								<MainMenu />
								<ErrorModal />
							</BaseWrapper>
						</HOCEntryData>
					</Router>
					<GlobalPreloader />
				</Session>
			</Provider>
		</ThemeProvider>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
