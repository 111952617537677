import React, {Fragment} from "react";
import {useSelector} from "react-redux";
import {getIsFetchedMatchUp, getMatchUpSelector} from "modules/selectors/matchUp";
import {IRound} from "modules/actions";
import {size} from "lodash";
import {MatchLine} from "components/League/MatchLine";
import styled from "styled-components";

const MatchContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	flex-flow: row wrap;
`;

interface IProps {
	round: IRound;
}

export const MatchUp: React.FC<IProps> = ({round}) => {
	const {id, status} = round;
	const matchUp = useSelector(getMatchUpSelector);
	const isFetchedMatchUp = useSelector(getIsFetchedMatchUp);

	return (
		<MatchContainer>
			{isFetchedMatchUp && size(matchUp)
				? matchUp.map((match, index) => {
						return (
							<Fragment key={index}>
								<MatchLine match={match} status={status} roundId={id} />
							</Fragment>
						);
				  })
				: null}
		</MatchContainer>
	);
};
