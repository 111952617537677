import {HTTPClient as HTTPClientCore, IRequestConfig} from "@fanhubmedia/fe-common-utils";
import axios, {AxiosError} from "axios";
import {isObject, set} from "lodash";
import ApiError from "./ApiError";
import {API_URL, JSON_URL} from "modules/constants";
import {CANCEL} from "redux-saga";
import {IApiResponse} from "modules/types";
import {
	IInviteEmails,
	ILeagueCreatePayload,
	ILeagueH2HPayload,
	ILeagueH2HResponse,
	ILeagueId,
	ILeagueJoinPayload,
	ILeagueRemovePlayerPayload,
	ILeagueUpdatePayload,
	ILeagueUsersJoinedParams,
	ILeagueUsersResponse,
	IShowMyLeaguesPayload,
} from "modules/actions/leagues";
import {IUserLoginByBackdoor, IUserLoginByTerms, IUserRecover} from "modules/actions/user";
import {IUser} from "modules/reducers/user";
import {ILeague, ILeagues, IOverallRankingResponse, ITournamentReducer} from "modules/reducers";
import {
	IContactData,
	ILeagueH2HRankings,
	IOverallRankingPayload,
	IPlayers,
	IRankingResponse,
	IRegularLeagueRankingPayload,
	IRound,
	IRoundId,
	ISquads,
	IVenues,
} from "modules/actions";
import {IHelpResponse} from "modules/reducers/help";
import {
	IPredictionAnswer,
	IPredictionPayload,
	IPredictionResponse,
} from "modules/actions/prediction";

class HTTPClient extends HTTPClientCore {
	/**
	 * Overridden method adds CancelToken symbol, that allow redux-saga'
	 * "takeLatest" function to cancel any requests automatically.
	 * http://fe-common-utils.s3-website-eu-west-1.amazonaws.com/classes/httpclient.html
	 */
	public makeRequest<T>(config: IRequestConfig): Promise<T> {
		const source = axios.CancelToken.source();

		const request = super.makeRequest<T>({
			...config,
			cancelToken: source.token,
		});

		return set<Promise<T>>(request, CANCEL, () => source.cancel());
	}
}

const onCatchNetworkError = ({response, message = "Network error"}: AxiosError<ApiError>) => {
	const data = response?.data;
	const error = isObject(data)
		? data
		: {
				errors: [new ApiError(message)],
		  };

	return Promise.reject(error).catch((err) => ApiError.CHECK(err));
};

const APIClient = new HTTPClient({
	baseURL: API_URL,
	withCredentials: true,
	onCatchNetworkError,
});

const JSONClient = new HTTPClient({
	baseURL: JSON_URL,
});

type TLeagueResponse = IApiResponse<{league: ILeague}>;
type TUserResponse = IApiResponse<{user: IUser}>;
type TRoundsResponse = {rounds: IRound[]};

export const Api = {
	JSON: {
		rounds: () => JSONClient.get<TRoundsResponse>("rounds.json"),
		squads: () => JSONClient.get<ISquads>("squads.json"),
		players: () => JSONClient.get<IPlayers>("players.json"),
		venues: () => JSONClient.get<IVenues>("venues.json"),
		tournament: () => JSONClient.get<ITournamentReducer>("tournament.json"),
		gameplay: () => JSONClient.get<IHelpResponse>("guidelines.json"),
		faq: () => JSONClient.get<IHelpResponse>("faq.json"),
		terms: () => JSONClient.get<IHelpResponse>("terms.json"),
		prizes: () => JSONClient.get<IHelpResponse>("prizes.json"),
		policy: () => JSONClient.get<IHelpResponse>("privacy.json"),
		cookies: () => JSONClient.get<IHelpResponse>("cookies.json"),
		checksums: () => JSONClient.get<{rounds: string}>("checksums.json"),
	},
	User: {
		backdoor: (params: IUserLoginByBackdoor) =>
			APIClient.post<TUserResponse>("auth/backdoor", params),
		login: (params: IUserLoginByTerms) => APIClient.post<TUserResponse>("auth/login", params),
		user: () => APIClient.get<TUserResponse>("user"),
		recover: (params: IUserRecover) => APIClient.post<TUserResponse>("auth/recover", params),
		tutorial_viewed: () => APIClient.get<TUserResponse>("user/view_tutorial"),
	},
	Leagues: {
		show_my_leagues: (params: IShowMyLeaguesPayload) =>
			APIClient.get<IApiResponse<ILeagues>>("league/show_my", params),
		show_for_join_leagues: (params: IShowMyLeaguesPayload) =>
			APIClient.get<IApiResponse<ILeagues>>("league/show_for_join", params),
		join_league: ({code}: ILeagueJoinPayload) =>
			APIClient.post<TLeagueResponse>(`league/${code}/join`),
		create_league: (params: ILeagueCreatePayload) =>
			APIClient.post<TLeagueResponse>("league", params),
		invite: ({leagueId, ...params}: IInviteEmails) =>
			APIClient.post<IApiResponse>(`league/${leagueId}/invite`, params),
		leave_league: ({leagueId}: ILeagueId) =>
			APIClient.post<IApiResponse>(`league/${leagueId}/leave`),
		remove_league: ({leagueId}: ILeagueId) =>
			APIClient.post<IApiResponse>(`league/${leagueId}/delete`),
		update_league: ({leagueId, ...params}: ILeagueUpdatePayload) =>
			APIClient.post<TLeagueResponse>(`league/${leagueId}`, params),
		joined_users: ({leagueId, ...params}: ILeagueUsersJoinedParams) =>
			APIClient.get<IApiResponse<ILeagueUsersResponse>>(`league/${leagueId}/users`, params),
		remove_player: ({leagueId, ...params}: ILeagueRemovePlayerPayload) =>
			APIClient.post<IApiResponse>(`league/${leagueId}/delete_users`, params),
		h2h_fixture: ({leagueId, roundId}: ILeagueH2HPayload) =>
			APIClient.get<IApiResponse<ILeagueH2HResponse>>(
				`league/${leagueId}/h2h_fixture/${roundId}`
			),
	},
	Help: {
		contact_us: (params: IContactData) => APIClient.post<IApiResponse>("contact", params),
	},
	Prediction: {
		get_prediction: ({roundId}: IPredictionPayload) =>
			APIClient.get<IApiResponse<IPredictionResponse>>(`prediction/${roundId}`),
		post_prediction: ({unitId, ...params}: IPredictionAnswer) =>
			APIClient.post<IApiResponse<IPredictionResponse>>(`prediction/${unitId}`, params),
	},
	Rankings: {
		get_round_rankings: ({roundId}: IRoundId) =>
			APIClient.get<IApiResponse<IRankingResponse>>(`ranking/${roundId}`),
		get_overall_rankings: (params: IOverallRankingPayload) =>
			APIClient.get<IApiResponse<IOverallRankingResponse>>("rankings", params),
		get_league_rankings: ({leagueId, ...params}: IRegularLeagueRankingPayload) =>
			APIClient.get<IApiResponse<IOverallRankingResponse>>(
				`league/${leagueId}/rankings`,
				params
			),
		h2h_rankings: ({leagueId}: ILeagueId) =>
			APIClient.get<IApiResponse<ILeagueH2HRankings>>(`league/${leagueId}/h2h_rankings`),
	},
};

export * from "./ApiError";

export default Api;
