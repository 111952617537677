import {IIconProps} from "modules/types";
import React from "react";

export const IconChecked: React.FC<IIconProps> = ({width = 12, height = 9, color}) => (
	<svg width={`${width}px`} height={`${height}px`} viewBox="0 0 12 9">
		<g stroke="none">
			<g transform="translate(-4.000000, -6.000000)" fill={color}>
				<g id="Tick" transform="translate(4.000000, 6.000000)">
					<g
						id="Mask"
						transform="translate(6.000000, 4.452149) scale(-1, 1) rotate(90.000000) translate(-6.000000, -4.452149) translate(1.547851, -1.547851)">
						<path d="M8.68128307,3.91253495 L4.50608758,0.22420485 C4.20714778,-0.07473495 3.71895988,-0.07473495 3.42002008,0.22420485 L3.28742578,0.35559365 C2.98969148,0.65453345 2.98969148,1.14392685 3.28742578,1.44166125 L6.77313108,4.47184165 L0.224204775,10.5580375 C-0.074734925,10.8569773 -0.074734925,11.3451652 0.224204775,11.644105 L0.355593675,11.7766993 C0.654533475,12.0744335 1.14272138,12.0744335 1.44166118,11.7766993 L8.68128307,5.01065655 C8.83195837,4.85998125 8.90548788,4.65988445 8.90429706,4.46099305 C8.90548788,4.26210165 8.83195837,4.06321025 8.68128307,3.91253495 Z" />
					</g>
				</g>
			</g>
		</g>
	</svg>
);
