import {QuestionType, UnitStatus} from "modules";
import React, {Fragment} from "react";
import {useSelector} from "react-redux";
import {getRoundById} from "modules/selectors/rounds";
import styled from "styled-components";
import {AnswerUnit} from "components";
import form_men_bg from "assets/img/form_men_bg.png";
import form_women_bg from "assets/img/form_women_bg.png";
import {getSquadById} from "modules/selectors/squads";
import theme from "assets/css/theme";
import {IUnit} from "modules/actions";
import {Exist} from "components/Exist";
import {eq} from "lodash";

interface IAnswers {
	answersBg?: string;
}

const AnswersWrapper = styled.div`
	width: 100%;
	position: relative;
	z-index: 4;
`;
const AnswersContainer = styled.div`
	width: 100%;
`;
const AnswersBlock = styled.div<IAnswers>`
	position: relative;
	padding: 20px 20px 5px;
	box-sizing: border-box;
	background: url(${(props) => props.answersBg}) no-repeat 100% 100% transparent;
`;
const MathHeader = styled.div`
	width: 100%;
	background-color: #000;
	min-height: 73px;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-flow: row wrap;
	padding: 6px 0 20px;
	position: relative;
`;
const MatchNumber = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto 8px;
	width: max-content;
	background-color: ${theme.secondary};
	color: #ffffff;
	font-family: "StyreneA-Bold", sans-serif;
	font-size: 8px;
	font-weight: bold;
	letter-spacing: 0.64px;
	text-align: center;
	padding: 2px;
	box-sizing: border-box;
	text-transform: uppercase;
`;
const MatchTitle = styled.div`
	width: 100%;
	padding: 2px 20px;
	box-sizing: border-box;
	text-transform: uppercase;
	color: #ffffff;
	font-family: "StyreneA-Bold", sans-serif;
	font-size: 12px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 15px;
	text-align: center;
`;
const CanceledUnit = styled.div`
	width: 100%;
	min-height: 380px;
	background-color: #000;
	color: #fff;
	padding: 20px 20px;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: column wrap;

	h5 {
		font-size: 18px;
		font-family: "StyreneA-Bold", sans-serif;
		text-align: center;
		display: block;
		margin-bottom: 30px;
	}

	p {
		font-family: "StyreneA-Regular", sans-serif;
		font-size: 12px;
		line-height: 16px;
		text-align: center;
	}
`;

interface IProps {
	questionType?: QuestionType;
	roundId: number;
	unit: IUnit;
}

export const MatchDayQuestionResult: React.FC<IProps> = ({
	questionType = QuestionType.Women,
	roundId = 0,
	unit,
}) => {
	const currentRound = useSelector(getRoundById)(roundId);
	const {questions, isEarlyVictory} = unit;
	const isPostponedUnit = eq(unit.status, UnitStatus.postponed);
	const getSquad = useSelector(getSquadById);
	const homeSquad = getSquad(unit.homeSquadId);
	const awaySquad = getSquad(unit.awaySquadId);

	if (!(currentRound && questions)) {
		return null;
	}

	if (!(homeSquad && awaySquad)) {
		return null;
	}

	const isWomen = eq(questionType, QuestionType.Women);
	const answersBg = isWomen ? form_men_bg : form_women_bg;

	return (
		<Fragment>
			<AnswersWrapper>
				<AnswersContainer>
					<AnswersBlock answersBg={answersBg}>
						<MathHeader>
							<MatchNumber>{unit.type}</MatchNumber>
							<MatchTitle>
								{homeSquad.name} VS {awaySquad.name}
							</MatchTitle>
						</MathHeader>
						<Exist when={isEarlyVictory}>
							<CanceledUnit>
								<h5>EARLY VICTORY</h5>
								<p>This match has been won before lockout.</p>
								<p>
									Unfortunately, this means you will not receive points for this
									match.
								</p>
							</CanceledUnit>
						</Exist>
						<Exist when={isPostponedUnit}>
							<CanceledUnit>
								<h5>MATCH POSTPONED</h5>
								<p>
									The match has been postponed. Unfortunately, you won’t be able
									to make predictions or receive any points for this match.
								</p>
							</CanceledUnit>
						</Exist>
						<Exist when={[!isEarlyVictory, !isPostponedUnit].some(Boolean)}>
							{questions.map((question, index) => {
								return (
									<Fragment key={index}>
										<AnswerUnit question={question} unit={unit} index={index} />
									</Fragment>
								);
							})}
						</Exist>
					</AnswersBlock>
				</AnswersContainer>
			</AnswersWrapper>
		</Fragment>
	);
};
