import React, {useEffect} from "react";
import styled from "styled-components";
import {ADVERT_SLOT} from "modules/constants";
import {isFunction} from "lodash";

const AdvertWrapper = styled.div`
	width: 320px;
	height: 50px;
	background: #f1f1f1;
	margin: 30px auto 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	position: relative;
	z-index: 9;
`;

export const Advert: React.FC = () => {
	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const GT = window.googletag;
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
		if (GT && isFunction(GT.pubads)) {
			//eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
			GT.pubads().refresh();
		}
	}, []);

	return <AdvertWrapper id={ADVERT_SLOT} />;
};
