import React from "react";
import {IIconProps} from "modules/types";

export const IconTwitter: React.FC<IIconProps> = ({width = 24, height = 24, color = "#fff"}) => (
	<svg width={width} height={height} viewBox="0 0 24 24">
		<title>Twitter</title>
		<path
			d="M18.9221,7.7062 C18.4121,7.9322 17.8651,8.0852 17.2911,8.1532 C17.8771,7.8022 18.3281,7.2462 18.5401,6.5822 C17.9911,6.9072 17.3841,7.1442 16.7361,7.2722 C16.2181,6.7192 15.4801,6.3742 14.6631,6.3742 C13.0941,6.3742 11.8221,7.6462 11.8221,9.2152 C11.8221,9.4382 11.8481,9.6542 11.8961,9.8622 C9.5351,9.7442 7.4431,8.6132 6.0411,6.8942 C5.7971,7.3142 5.6571,7.8022 5.6571,8.3222 C5.6571,9.3072 6.1581,10.1782 6.9201,10.6862 C6.4551,10.6722 6.0171,10.5442 5.6341,10.3312 L5.6341,10.3672 C5.6341,11.7432 6.6131,12.8912 7.9121,13.1522 C7.6741,13.2172 7.4231,13.2522 7.1641,13.2522 C6.9811,13.2522 6.8031,13.2342 6.6291,13.2002 C6.9911,14.3292 8.0401,15.1502 9.2821,15.1732 C8.3111,15.9352 7.0861,16.3892 5.7551,16.3892 C5.5261,16.3892 5.3001,16.3752 5.0781,16.3492 C6.3351,17.1552 7.8281,17.6252 9.4321,17.6252 C14.6561,17.6252 17.5131,13.2982 17.5131,9.5442 C17.5131,9.4212 17.5101,9.2992 17.5051,9.1762 C18.0591,8.7762 18.5401,8.2762 18.9221,7.7062"
			fill={color}
		/>
	</svg>
);
