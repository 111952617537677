import {fetchSquadsJSONSuccess, ISquad} from "modules/actions";
import {createReducer} from "redux-act";

export interface ISquadsReducer {
	squads: ISquad[];
}

const initialState: ISquadsReducer = {
	squads: [],
};

export const squads = createReducer<ISquadsReducer>({}, initialState).on(
	fetchSquadsJSONSuccess,
	(state, squads) => ({
		...state,
		squads,
	})
);
