import {IIconProps} from "modules/types";
import React from "react";

export const IconArrow: React.FC<IIconProps> = ({width = 16, height = 9, color}) => (
	<svg width={`${width}px`} height={`${height}px`} viewBox="0 0 16 9">
		<g id="Symbols" stroke="none">
			<g transform="translate(-7.000000, -11.000000)" fill={color}>
				<polygon
					transform="translate(15.055935, 15.500000) rotate(90.000000) translate(-15.055935, -15.500000) "
					points="11.9621849 8 10.6496849 9.3125 15.8996849 14.5625 16.9538574 15.5 15.8996849 16.4375 10.6496849 21.6875 11.9621849 23 19.4621849 15.5"
				/>
			</g>
		</g>
	</svg>
);
