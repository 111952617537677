import styled from "styled-components";
import theme from "assets/css/theme";

export const BaseWrapper = styled.div`
	flex-flow: column wrap;
	justify-content: unset;
	display: flex;
	flex: 1;
	background-color: #fff;
	padding: 0 0 60px 0;
	align-items: flex-start;
	font-family: ${theme.baseFont};
`;
export const Wrapper = styled.div`
	flex-flow: column wrap;
	justify-content: unset;
	display: flex;
	flex: 1;
	background-color: #fff;
	padding: 0 0 60px 0;
	align-items: flex-start;
	font-family: ${theme.baseFont};
`;
export const Container = styled.div`
	max-width: ${theme.contentWidth};
	width: 100%;
	padding: 0 10px;
	margin: 0 auto;
	box-sizing: border-box;
`;
export const MainTitle = styled.h1`
	font-family: ${theme.baseFont};
	margin-bottom: 6px;
	color: #000;
	font-size: 24px;
	letter-spacing: 0;
	line-height: 28px;
`;
export const Title = styled.h3`
	font-family: ${theme.baseFont};
	margin-bottom: 6px;
	color: #000;
	font-size: 16px;
	letter-spacing: 0;
	line-height: 20px;
`;
export const SubTitle = styled.h5`
	font-family: ${theme.baseFont};
	text-align: center;
	display: block;
	margin-bottom: 6px;
	color: #000000;
	font-size: 12px;
	letter-spacing: 0;
	line-height: 16px;
`;
export const SubTitleLeft = styled(SubTitle)`
	text-align: left;
`;
export const Description = styled.div`
	font-family: ${theme.baseFont};
	text-transform: unset;
	color: #000;
	font-size: 14px;
	letter-spacing: 0;
	line-height: 20px;
`;
export const PageTitle = styled.h1`
	display: block;
	width: 100%;
	color: #ffffff;
	font-family: "Hundred-Display-Web", sans-serif;
	font-size: 34px;
	letter-spacing: 0;
	line-height: 25px;
	text-transform: uppercase;
	left: 0;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	word-break: break-word;

	span {
		display: flex;
		justify-content: flex-end;
		width: max-content;
		max-width: 100%;
		padding-left: 20px;
		padding-right: 3px;
		background: ${theme.secondary};
	}

	p {
		padding-left: 40px;
		max-width: 75%;
	}
`;
