import React, {useCallback, useEffect, useState} from "react";
import {getRounds} from "modules/selectors/rounds";
import {useSelector} from "react-redux";
import {findIndex, partial} from "lodash";
import {NavigationSide} from "modules";
import styled from "styled-components";

const NavigationWrapper = styled.div`
	position: absolute;
	top: 90px;
	z-index: 9;
	width: 100%;
	background: transparent;
`;
const NavButton = styled.button`
	position: absolute;
	top: 0;
	background: transparent;

	&.prev {
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 9px 16px 9px 0;
		border-color: transparent #32c84b transparent transparent;
		left: 20px;

		&:disabled {
			border-color: transparent grey transparent transparent;
		}
	}

	&.next {
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 9px 0 9px 16px;
		border-color: transparent transparent transparent #32c84b;
		right: 20px;

		&:disabled {
			border-color: transparent transparent transparent grey;
		}
	}
`;

interface IProps {
	roundId: number;
	onChangeRound: (roundId: number) => void;
}

export const GameDayNavigation: React.FC<IProps> = ({roundId, onChangeRound}) => {
	const rounds = useSelector(getRounds);
	const [isActive, setIsActive] = useState(0);

	useEffect(() => {
		if (rounds.length) {
			setIsActive(findIndex(rounds, (round) => round?.id === roundId) || 0);
		}
	}, [rounds, roundId]);

	const prevArrowDisabled = isActive === 0;
	const nextArrowDisabled = isActive === rounds.length - 1;

	const changeRound = useCallback(
		(to: NavigationSide) => {
			let nextIndex = 0;

			if (to === NavigationSide.Prev) {
				nextIndex = isActive !== 0 ? isActive - 1 : 0;
			} else {
				nextIndex = rounds.length - 1 !== isActive ? isActive + 1 : rounds.length - 1;
			}

			const round = rounds.find((_el, index) => index === nextIndex);

			if (round) {
				// const direction = to === NavigationSide.Next ? "right" : "left";
				onChangeRound(round.id);
				setIsActive(nextIndex);
			}
		},
		[onChangeRound, rounds, isActive]
	);

	return (
		<NavigationWrapper>
			<NavButton
				className={NavigationSide.Prev}
				onClick={partial(changeRound, NavigationSide.Prev)}
				disabled={prevArrowDisabled}
			/>
			<NavButton
				className={NavigationSide.Next}
				onClick={partial(changeRound, NavigationSide.Next)}
				disabled={nextArrowDisabled}
			/>
		</NavigationWrapper>
	);
};
