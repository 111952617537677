import {getGlobalPreloaderState} from "modules/selectors/global";
import React from "react";
import {useSelector} from "react-redux";
import styled from "styled-components";

const PreloaderWrapper = styled.div`
	width: 200px;
	height: 200px;
	display: inline-block;
	overflow: hidden;
	background: none;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;
const PreloaderContent = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
	transform: translateZ(0) scale(1);
	backface-visibility: hidden;
	transform-origin: 0 0;
	box-sizing: content-box;

	@keyframes ldio-v4h5v0fyifm-o {
		0% {
			opacity: 1;
			transform: translate(0 0);
		}
		49.99% {
			opacity: 1;
			transform: translate(33.12px, 0);
		}
		50% {
			opacity: 0;
			transform: translate(33.12px, 0);
		}
		100% {
			opacity: 0;
			transform: translate(0, 0);
		}
	}
	@keyframes ldio-v4h5v0fyifm {
		0% {
			transform: translate(0, 0);
		}
		50% {
			transform: translate(33.12px, 0);
		}
		100% {
			transform: translate(0, 0);
		}
	}

	div {
		position: absolute;
		width: 33.12px;
		height: 33.12px;
		border-radius: 50%;
		top: 86.94px;
		left: 70.38px;

		&:nth-child(1) {
			background: #ff0082;
			animation: ldio-v4h5v0fyifm 0.9900990099009901s linear infinite;
			animation-delay: -0.49504950495049505s;
		}

		&:nth-child(2) {
			background: #32c84b;
			animation: ldio-v4h5v0fyifm 0.9900990099009901s linear infinite;
			animation-delay: 0s;
		}
		&:nth-child(3) {
			background: #ff0082;
			animation: ldio-v4h5v0fyifm-o 0.9900990099009901s linear infinite;
			animation-delay: -0.49504950495049505s;
		}
	}
`;

export const Preloader: React.FC = () => (
	<PreloaderWrapper>
		<PreloaderContent>
			<div />
			<div />
			<div />
		</PreloaderContent>
	</PreloaderWrapper>
);

const Overlay = styled.div`
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.79);
	z-index: 29;
`;

interface IProps {
	isVisible?: boolean;
}

export const GlobalPreloader: React.FC<IProps> = ({isVisible}) => {
	const isShowPreloader = useSelector(getGlobalPreloaderState);

	return isShowPreloader || isVisible ? (
		<Overlay>
			<Preloader />
		</Overlay>
	) : null;
};
