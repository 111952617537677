import React, {SyntheticEvent, useCallback, useMemo} from "react";
import {getTrackBackground, Range} from "react-range";
import styled from "styled-components";
import {IconDragArrow, IconLock} from "components/Icons";
import {PredictionStatus} from "modules";
import {IQuestion, ISetAnswer} from "modules/actions";
import {useSelector} from "react-redux";
import {getPredictionAnswerByUnitId} from "modules/selectors/prediction";
import {eq} from "lodash";

const RangeWrapper = styled.div`
	width: 100%;
	//height: 60px;
	margin-bottom: 20px;
	padding-bottom: 20px;
	box-sizing: border-box;
	border-bottom: 1px solid grey;
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	align-items: center;

	output,
	input {
		width: 60px;
		height: 36px;
		background-color: #fff;
		border: none;
		outline: none;
		font-size: 16px;
		line-height: 36px;
		padding: 3px 6px;
	}
`;
const QuestionRow = styled.div`
	width: 100%;
	color: #fff;
	margin-bottom: 15px;
	font-family: "StyreneA-Bold", sans-serif;
	font-size: 14px;
	letter-spacing: 1px;
	line-height: 18px;
	padding-right: 40px;
	box-sizing: border-box;
`;
const RangeLine = styled.div`
	width: calc(100% - 90px);
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 0;
	box-sizing: border-box;
	input[type="range"]::-webkit-slider-thumb {
		pointer-events: auto;
	}
`;
const RangeElement = styled.div`
	width: 92%;
	margin: 0 auto;
`;
const RangeBtn = styled.div`
	outline: none;
	border: none;
	div {
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;

interface IQuestionProps {
	index: number;
	color: string;
	question: IQuestion;
	status: PredictionStatus;
	startValue?: number;
	endValue?: number;
	unitId: number;
	setPrediction: (answer: ISetAnswer) => void;
}

export const QuestionItem: React.FC<IQuestionProps> = ({
	index,
	color,
	question,
	status = PredictionStatus.OnHold,
	startValue = 0,
	endValue = 100,
	unitId,
	setPrediction,
}) => {
	const {title, id} = question;
	const predictionAnswer = useSelector(getPredictionAnswerByUnitId)(unitId) || [];
	const answerData = predictionAnswer.find(({questionId}) => questionId === id);
	const answer = answerData?.answer || startValue;
	const sliderValues = useMemo(() => [answer], [answer]);
	const tutorialClass = eq(index, 0) ? "second_step" : "";

	const isDisabled = status === PredictionStatus.Locked;

	const step = 1;

	const onSetPrediction = useCallback(
		(answer: ISetAnswer) => {
			setPrediction({...answer, unitId});
		},
		[setPrediction, unitId]
	);

	const handleChangeInput = useCallback(
		({currentTarget: {value}}: SyntheticEvent<HTMLInputElement>) => {
			const inputValue = Number(/\d+/.exec(value)?.[0]) || startValue;
			const newValue = Math.min(inputValue, endValue);

			onSetPrediction({unitId, questionId: id, answer: newValue});
		},
		[startValue, endValue, unitId, id, onSetPrediction]
	);

	const handleSliderChange = useCallback(
		(values: number[]) => {
			onSetPrediction({unitId, questionId: id, answer: values[0]});
		},
		[id, unitId, onSetPrediction]
	);

	return (
		<RangeWrapper className={tutorialClass}>
			<QuestionRow>{title}</QuestionRow>
			<Range
				values={sliderValues}
				step={step}
				min={startValue}
				max={endValue}
				disabled={isDisabled}
				onChange={handleSliderChange}
				renderTrack={({props, children}) => (
					<RangeLine onMouseDown={props.onMouseDown} onTouchStart={props.onTouchStart}>
						<RangeElement
							ref={props.ref}
							style={{
								height: "3px",
								borderRadius: "2px",
								background: getTrackBackground({
									values: sliderValues,
									colors: [color, "grey"],
									min: startValue,
									max: endValue,
								}),
								alignSelf: "center",
							}}>
							{children}
						</RangeElement>
					</RangeLine>
				)}
				renderThumb={({props}) => (
					<RangeBtn
						{...props}
						style={{
							...props.style,
							height: "34px",
							width: "34px",
							borderRadius: "50%",
							backgroundColor: "#FFF",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}>
						<div>
							{isDisabled ? (
								<IconLock color={"#000"} />
							) : (
								<IconDragArrow color={color} width={28} height={16} />
							)}
						</div>
					</RangeBtn>
				)}
			/>
			<input
				style={{color: color}}
				type="text"
				disabled={isDisabled}
				onChange={handleChangeInput}
				name={`question_${id}`}
				value={sliderValues[0]}
			/>
		</RangeWrapper>
	);
};
