import {createAction} from "redux-act";

export interface ISquads {
	squads: ISquad[];
}
export interface ISquad {
	id: number;
	name: string;
	code: string;
	captainId: number;
	image: string;
}

export const fetchSquadsJSON = createAction();
export const fetchSquadsJSONSuccess = createAction<ISquad[]>();
