import {IIconProps} from "modules/types";
import React from "react";

export const IconFacebook: React.FC<IIconProps> = ({width = 24, height = 24, color = "#fff"}) => (
	<svg width={width} height={height} viewBox="0 0 24 24">
		<path
			d="M14.8624,12 L15.1834,9.667 L12.7194,9.667 L12.7194,8.536 C12.7194,7.816 12.9194,7.325 13.9534,7.325 L15.2694,7.324 L15.2694,5.098 C15.0424,5.068 14.2604,5 13.3494,5 C11.4494,5 10.1494,6.16 10.1494,8.29 L10.1494,9.667 L8.0004,9.667 L8.0004,12 L10.1494,12 L10.1494,19 L12.7194,19 L12.7194,12 L14.8624,12"
			fill={color}
		/>
	</svg>
);
