export const ENV = process.env.REACT_APP_ENVIRONMENT;
export const BASE_URL = process.env.REACT_APP_BASE_URL || "";

export const IS_SECRET = "false";
export const API_URL = process.env.REACT_APP_API_URL || "";
export const JSON_URL = process.env.REACT_APP_JSON_URL || "";
export const IMAGES_URL = process.env.REACT_APP_IMAGES_URL || "";

export const IS_COMING_SOON = process.env.REACT_APP_IS_COMING_SOON === "true";
export const ADVERT_LINK = process.env.REACT_APP_ADVERT_LINK || "";
export const ADVERT_SLOT = process.env.REACT_APP_ADVERT_SLOT || "";

export const STORAGE_VERSION = 1;

export const EMAIL_REGEXP = /\S+@\S+\.\S+/;
