import React from "react";
import {IUnit} from "modules/actions";
import {PointsRow} from "components/PointsRow";
import {Exist} from "components/Exist";
import theme from "assets/css/theme";
import {MatchDayQuestion} from "components/MatchDayQuestion";
import {MatchDayQuestionResult} from "components/MatchDayQuestionResult";
import {useSelector} from "react-redux";
import {getRoundById} from "modules/selectors/rounds";
import {getSquadById} from "modules/selectors/squads";
import {PredictionStatus, QuestionOption, QuestionType, RoundStatus, UnitStatus} from "modules";
import styled from "styled-components";
import man_banner_bg from "assets/img/man_banner_bg.png";
import woman_banner_bg from "assets/img/woomen_banner_bg.png";
import {getMediaImage} from "modules/utils/help";
import {getPlayerById} from "modules/selectors/players";
import {eq, find, gt, isNull} from "lodash";
import {IconLock} from "components";

const UnitContainer = styled.div`
	&.relative {
		position: relative;
		padding-top: 62px;
	}
`;
const UnitWrapp = styled.div`
	position: relative;
`;
const BallsWrapper = styled.div`
	position: absolute;
	left: 50%;
	top: -70px;
	transform: translateX(-50%);
	width: 46px;
	height: 46px;
	border-radius: 50%;
	background-color: #fff;
	box-sizing: border-box;
	z-index: 9;
	display: flex;
	justify-content: center;
	align-items: center;

	&.unit_1 {
		top: 58px;
	}

	&.unit_one {
		top: -22px;
	}
`;
const BallsContainer = styled.div`
	position: relative;
	width: 38px;
	height: 38px;
	border-radius: 50%;
	border: 3px solid #000;
	font-size: 16px;
	font-family: "StyreneA-Bold", sans-serif;
	display: flex;
	justify-content: center;
`;
const LockedBallsContainer = styled(BallsContainer)`
	align-items: center;
	//border: 3px solid transparent;
`;
const BallsNumber = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 1px;
	opacity: 1;
	transition: all 0.5s;
`;
const MatchHeader = styled.div`
	width: 100%;
	background-color: #000;
	min-height: 73px;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-flow: row wrap;
	padding: 6px 0 20px;
	position: relative;
`;
const MatchTitle = styled.div`
	width: 100%;
	padding: 2px 40px;
	box-sizing: border-box;
	text-transform: uppercase;
	color: #ffffff;
	font-family: "StyreneA-Bold", sans-serif;
	font-size: 12px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 15px;
	text-align: center;
`;
const MatchNumber = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto 8px;
	width: max-content;
	background-color: ${theme.secondary};
	color: #ffffff;
	font-family: "StyreneA-Bold", sans-serif;
	font-size: 8px;
	font-weight: bold;
	letter-spacing: 0.64px;
	text-align: center;
	padding: 2px;
	box-sizing: border-box;
	text-transform: uppercase;
`;
const GameBanner = styled.div<IGameBanner>`
	width: 100%;
	min-height: 120px;
	background: ${(props) =>
		props.bgColor === theme.primary
			? `url(${man_banner_bg}) no-repeat 0 100% ${props.bgColor}`
			: `url(${woman_banner_bg}) no-repeat 0 100% ${props.bgColor}`};
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;

	&:before {
		content: "VS";
		display: block;
		height: 32px;
		width: 50px;
		color: #000000;
		font-family: "Hundred-Display-Web", sans-serif;
		font-size: 42px;
		letter-spacing: 0;
		line-height: 32px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
`;
const PlayerLeft = styled.div<IPlayer>`
	width: 50%;
	min-height: 120px;
	height: 100%;
	position: relative;
	background: url(${(props) => props.playerImg}) no-repeat 5% 100%;
	background-size: contain;

	img {
		top: 10px;
		right: 20%;
	}
`;
const PlayerRight = styled.div<IPlayer>`
	width: 50%;
	min-height: 120px;
	height: 100%;
	position: relative;
	background: url(${(props) => props.playerImg}) no-repeat 95% 100%;
	background-size: contain;

	img {
		bottom: 10px;
		left: 20%;
	}
`;
const TeamLogo = styled.img`
	position: absolute;
	display: block;
	height: 48px;
`;
const CanceledUnit = styled.div`
	width: 100%;
	min-height: 380px;
	background-color: #000;
	color: #fff;
	padding: 20px 20px;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: column wrap;

	h5 {
		font-size: 18px;
		font-family: "StyreneA-Bold", sans-serif;
		text-align: center;
		display: block;
		width: 100%;
		margin-bottom: 30px;
	}

	p {
		width: 100%;
		font-family: "StyreneA-Regular", sans-serif;
		font-size: 12px;
		line-height: 16px;
		text-align: center;
	}
`;

interface IPlayer {
	playerImg: string;
}

interface IGameBanner {
	bgColor: string;
}

interface IProps {
	unit: IUnit;
	roundId: number;
	index: number;
	onErrorModal: (errorSend: boolean) => void;
	onEarlyVictory: (finish: boolean) => void;
	class?: string;
}

// eslint-disable-next-line sonarjs/cognitive-complexity, complexity
export const MatchDaySectionUnits: React.FC<IProps> = (props) => {
	const {unit, roundId, index, onErrorModal, onEarlyVictory, ...rest} = props;
	const {questions, type} = unit;
	const currentRound = useSelector(getRoundById)(roundId);
	const getPlayer = useSelector(getPlayerById);
	const getSquad = useSelector(getSquadById);
	const homeSquad = getSquad(unit.homeSquadId);
	const awaySquad = getSquad(unit.awaySquadId);
	const isWomenUnit = eq(type, QuestionType.Women);
	const bgGameBanner = isWomenUnit ? theme.secondary : theme.primary;
	const hasAnswer = find(questions, (q) => !isNull(q.correctAnswer));
	const isLockedQuestion = find(questions, (q) => eq(q.status, PredictionStatus.Locked));
	const hasClass = rest.class ? rest.class : `unit_${index}`;
	const isBallOver = eq(unit.ballsCountdown, 0);

	if (!currentRound) {
		return null;
	}

	const isActiveRound = eq(currentRound?.status, RoundStatus.active);
	const isScheduledUnit = eq(unit.status, UnitStatus.scheduled);
	// const isPlayingUnit = eq(unit.status, UnitStatus.playing);
	const isCompleteUnit = eq(unit.status, UnitStatus.complete);
	const isPostponedUnit = eq(unit.status, UnitStatus.postponed);
	const isDoubleHeader = gt(currentRound.units.length, 2);

	const playerQuestion = questions
		? questions.find((question) => eq(question.optionType, QuestionOption.Player))
		: null;

	const homePlayer =
		playerQuestion && playerQuestion.options ? getPlayer(playerQuestion.options.homeId) : "";
	const awayPlayer =
		playerQuestion && playerQuestion.options ? getPlayer(playerQuestion.options.awayId) : "";

	const homePlayerImg = homePlayer ? getMediaImage(homePlayer.image ?? "") : "";
	const awayPlayerImg = awayPlayer ? getMediaImage(awayPlayer.image) : "";

	const captainHomePlayer = homeSquad ? getPlayer(homeSquad.captainId) : "";
	const captainAwayPlayer = awaySquad ? getPlayer(awaySquad.captainId) : "";

	const captainHomePlayerImg = captainHomePlayer ? getMediaImage(captainHomePlayer.image) : "";
	const captainAwayPlayerImg = captainAwayPlayer ? getMediaImage(captainAwayPlayer.image) : "";

	const ballCounterValue = unit.ballsCountdown < 100 ? 100 - unit.ballsCountdown : 0;

	return (
		<UnitContainer key={index} className={index > 0 ? "relative" : ""}>
			<PointsRow roundId={roundId} unit={unit} />
			<UnitWrapp>
				<Exist when={[isActiveRound, !isBallOver].some(Boolean)}>
					<Exist when={!!isLockedQuestion}>
						<BallsWrapper className={hasClass}>
							<LockedBallsContainer>
								<IconLock />
							</LockedBallsContainer>
						</BallsWrapper>
					</Exist>
					<Exist when={!isLockedQuestion}>
						<BallsWrapper className={hasClass}>
							<BallsContainer>
								<BallsNumber>{ballCounterValue}</BallsNumber>
							</BallsContainer>
						</BallsWrapper>
					</Exist>
				</Exist>
				<Exist when={index > 0}>
					<MatchHeader>
						<Exist when={!isDoubleHeader}>
							<MatchNumber>Match day {currentRound.number}</MatchNumber>
						</Exist>
						<Exist when={isDoubleHeader}>
							<MatchNumber>
								Match day {currentRound.number} - double header
							</MatchNumber>
						</Exist>
						<MatchTitle>
							{homeSquad ? homeSquad.name : ""} VS {awaySquad ? awaySquad.name : ""}
						</MatchTitle>
					</MatchHeader>
				</Exist>
				<GameBanner bgColor={bgGameBanner}>
					<PlayerLeft playerImg={homePlayerImg || captainHomePlayerImg}>
						<TeamLogo src={homeSquad ? getMediaImage(homeSquad.image) : ""} alt="" />
					</PlayerLeft>
					<PlayerRight playerImg={awayPlayerImg || captainAwayPlayerImg}>
						<TeamLogo src={awaySquad ? getMediaImage(awaySquad.image) : ""} alt="" />
					</PlayerRight>
				</GameBanner>
				<Exist when={[!hasAnswer, isScheduledUnit].some(Boolean)}>
					<MatchDayQuestion
						roundId={roundId}
						unit={unit}
						setFormError={onErrorModal}
						setEarlyVictory={onEarlyVictory}
					/>
				</Exist>
				<Exist when={isPostponedUnit}>
					<CanceledUnit>
						<h5>MATCH POSTPONED</h5>
						<p>
							The match has been postponed. Unfortunately, you won’t be able to make
							predictions or receive any points for this match.
						</p>
					</CanceledUnit>
				</Exist>
				{/*<Exist when={isCompleteUnit}>*/}
				<Exist when={[hasAnswer, isCompleteUnit].some(Boolean)}>
					<MatchDayQuestionResult
						unit={unit}
						questionType={unit.type}
						roundId={roundId}
					/>
					{/*<NextMatchDayBanner />*/}
				</Exist>
			</UnitWrapp>
		</UnitContainer>
	);
};
