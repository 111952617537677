import {
	fetchHelpPageJSONError,
	fetchHelpPageJSONSuccess,
	postContactForm,
	postContactFormSuccess,
	setGlobalPreloaderState,
	showGlobalError,
} from "modules/actions";
import {Api} from "modules/utils/Api";
import {all, call, put} from "typed-redux-saga";

export const fetchHelpPageJSONSaga = function* () {
	try {
		yield* put(setGlobalPreloaderState(true));
		const {gameplay, terms, faq, prizes, policy, cookies} = yield* all({
			gameplay: call(Api.JSON.gameplay),
			terms: call(Api.JSON.terms),
			faq: call(Api.JSON.faq),
			prizes: call(Api.JSON.prizes),
			policy: call(Api.JSON.policy),
			cookies: call(Api.JSON.cookies),
		});

		yield* put(
			fetchHelpPageJSONSuccess({
				gameplay: gameplay,
				terms: terms,
				faq: faq,
				prizes: prizes,
				policy: policy,
				cookies: cookies,
			})
		);
	} catch (err) {
		fetchHelpPageJSONError(err as Error);
	}
	yield* put(setGlobalPreloaderState(false));
};

export const postContactFormSaga = function* ({payload}: ReturnType<typeof postContactForm>) {
	try {
		yield* put(setGlobalPreloaderState(true));
		const response = yield* call(Api.Help.contact_us, payload);
		yield* put(postContactFormSuccess(response));
	} catch (err) {
		yield* put(showGlobalError(err as Error));
	}
	yield* put(setGlobalPreloaderState(false));
};
