import {SagaIterator} from "redux-saga";
import {setGlobalPreloaderState} from "modules/actions";
import {call, put} from "typed-redux-saga";
import {
	requestCurrentUserFailed,
	requestCurrentUserSuccess,
	setTutorialViewedError,
	setTutorialViewedSuccess,
	userLoginByBackdoor,
	userLoginByTerms,
	userLoginByTermsError,
	userLoginByTermsSuccess,
	userLoginError,
	userLoginSuccess,
	userRecover,
	userRecoverError,
	userRecoverSuccess,
} from "modules/actions/user";
import Api from "modules/utils/Api";

export const userLoginByBackdoorSaga = function* ({
	payload,
}: ReturnType<typeof userLoginByBackdoor>): SagaIterator {
	try {
		yield* put(setGlobalPreloaderState(true));
		const response = yield* call(Api.User.backdoor, payload);
		yield* put(userLoginSuccess(response.success.user));
		localStorage.setItem("isAuthorized", "1"); // This required for uTagTracking
	} catch (err) {
		yield* put(userLoginError(err as Error));
	}
	yield* put(setGlobalPreloaderState(false));
};

export const getCurrentUserSaga = function* (): SagaIterator {
	try {
		// yield* put(setGlobalPreloaderState(true));
		const response = yield* call(Api.User.user);
		yield* put(requestCurrentUserSuccess(response.success.user));
	} catch (err) {
		yield* put(requestCurrentUserFailed());
	}
	// yield* put(setGlobalPreloaderState(false));
};

export const userLoginByTermsSaga = function* ({
	payload,
}: ReturnType<typeof userLoginByTerms>): SagaIterator {
	try {
		yield* put(setGlobalPreloaderState(true));
		const response = yield* call(Api.User.login, payload);
		yield* put(userLoginByTermsSuccess(response.success.user));
		// yield* put(showGlobalError({message: JSON.stringify(response)}));
	} catch (err) {
		yield* put(userLoginByTermsError(err as Error));
		// yield* put(showGlobalError(err));
	}
	yield* put(setGlobalPreloaderState(false));
};

export const userRecoverSaga = function* ({payload}: ReturnType<typeof userRecover>): SagaIterator {
	try {
		const result = yield* call(Api.User.recover, payload);

		yield* put(userRecoverSuccess(result.success.user));
	} catch (err) {
		yield* put(userRecoverError(err as Error));
	}
};

export const setTutorialViewedSaga = function* () {
	try {
		yield* put(setGlobalPreloaderState(true));
		const response = yield* call(Api.User.tutorial_viewed);

		yield* put(setTutorialViewedSuccess(response.success.user));
	} catch (err) {
		yield* put(setTutorialViewedError(err as Error));
	}
	yield* put(setGlobalPreloaderState(false));
};
