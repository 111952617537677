import {createReducer} from "redux-act";
import {fetchChecksumsJSONSuccess} from "modules/actions/checksums";

export interface IChecksum {
	rounds: string;
}

const initialState: IChecksum = {
	rounds: "",
};

export const checksums = createReducer<typeof initialState>({}, initialState).on(
	fetchChecksumsJSONSuccess,
	(state, payload) => ({
		...state,
		rounds: payload.rounds,
	})
);
