import {fetchPlayersJSONSuccess, IPlayer} from "modules/actions";
import {createReducer} from "redux-act";

export interface IPlayersReducer {
	players: IPlayer[];
}

const initialState: IPlayersReducer = {
	players: [],
};

export const players = createReducer<IPlayersReducer>({}, initialState).on(
	fetchPlayersJSONSuccess,
	(state, players) => ({
		...state,
		players,
	})
);
