import React, {ForwardedRef} from "react";
import styled from "styled-components";
import {IconType} from "./Input";
import {ElementsWrapper, ErrorText, Label} from "./index";

interface ITextAreaProps {
	onClick?: (e: React.SyntheticEvent<HTMLTextAreaElement>) => void;
	onChange?: (e: React.SyntheticEvent<HTMLTextAreaElement>) => void;
	icon_type?: IconType;
	label?: string;
	showPassword?: boolean;
	tooltipText?: string;
	errorText?: string;
	ref?: ForwardedRef<HTMLTextAreaElement>;
}

const TextareaWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-start;
	position: relative;
`;
const TextareaElement = styled.textarea`
	box-sizing: border-box;
	height: 196px;
	width: 100%;
	max-width: 480px;
	background-color: transparent;
	letter-spacing: 0;
	line-height: 18px;
	resize: none;
	text-transform: unset;
	outline: none;
	color: #000;
	border-radius: 5px;
	display: flex;
	font-family: "StyreneA-Regular", sans-serif;
	font-size: 12px;
	border: 2px solid #000;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	padding: 10px 30px 10px 15px;
`;

type IProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & ITextAreaProps;

export const Textarea: React.ForwardRefRenderFunction<HTMLTextAreaElement, IProps> = ({
	label,
	showPassword,
	tooltipText = "",
	errorText,
	maxLength,
	className,
	...props
}) => {
	return (
		<ElementsWrapper className={className}>
			{label ? <Label>{label}</Label> : null}
			<TextareaWrapper>
				<TextareaElement {...props} />
			</TextareaWrapper>
			{errorText ? <ErrorText>{errorText}</ErrorText> : null}
		</ElementsWrapper>
	);
};
