import {IIconProps} from "modules/types";
import React from "react";

export const IconInstagram: React.FC<IIconProps> = ({width = 20, height = 20, color = "#fff"}) => (
	<svg
		width={`${width}px`}
		height={`${height}px`}
		viewBox="0 0 20 20"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg">
		<g id="Symbols" stroke="none" fill="none">
			<g id="3.0-Icons-/-Instagram" fill={color}>
				<g id="Instagram-icon-01">
					<path
						d="M9.90542058,4.80179605 C10.6544277,4.80179605 11.4034348,4.80179605 12.1547466,4.80179605 C13.8394364,4.82488651 15.0908545,5.96786424 15.150775,7.63499538 C15.2106956,9.30212653 15.2014771,10.9207677 15.150775,12.5601903 C15.1368328,14.1204114 13.8664429,15.3756824 12.3091573,15.3679901 C10.6959112,15.4164801 9.08266507,15.4234072 7.46941897,15.3679901 C5.7340271,15.3195002 4.58862237,14.1257234 4.55174817,12.3662305 C4.52178789,10.8376421 4.51487398,9.30443558 4.55174817,7.77584718 C4.59553628,5.94939187 5.8146894,4.799487 7.64457141,4.78794177 C8.40510171,4.79717796 9.15410883,4.80179605 9.90542058,4.80179605 Z M9.84780465,14.3681733 L9.84780465,14.3681733 C10.6521231,14.3681733 11.4610507,14.3981909 12.2607599,14.3681733 C13.3093698,14.3219923 14.0860326,13.6408238 14.1252114,12.5994441 C14.1897413,10.9323129 14.1943506,9.26056371 14.1252114,7.5957416 C14.0768141,6.53127144 13.2402307,5.83855766 12.1662698,5.82470339 C10.637527,5.80161293 9.11108893,5.80161293 7.58695547,5.82470339 C6.42080901,5.84317576 5.62340451,6.58207045 5.58883495,7.73890245 C5.54043757,9.32290795 5.5427422,10.9115315 5.58883495,12.493228 C5.62570915,13.6200424 6.39315336,14.3404647 7.52012099,14.3751004 C8.29217448,14.3935728 9.07114188,14.3681733 9.84780465,14.3681733 L9.84780465,14.3681733 Z"
						id="Shape"
					/>
					<path
						d="M10.0690498,12.8211125 C8.55249393,12.7920507 7.34231007,11.5445264 7.35649175,10.0248579 C7.41438164,8.53489254 8.64685048,7.36257775 10.1349713,7.38198976 C11.6230921,7.40140177 12.8246801,8.60546797 12.8438331,10.0964383 C12.8396056,10.8273999 12.5441254,11.5263859 12.0230686,12.0380331 C11.5020117,12.5496803 10.798553,12.8315936 10.0690498,12.8211125 Z M11.8666669,10.0918202 C11.8763196,9.12123326 11.1025259,8.32467369 10.1338859,8.30805881 C9.16524594,8.29144392 8.36469153,9.06099901 8.34117873,10.0313483 C8.31766592,11.0016975 9.08000454,11.8092685 10.0483081,11.839768 C10.5256368,11.8674449 10.9929098,11.6947193 11.3379209,11.3630678 C11.6829321,11.0314163 11.8745157,10.5707977 11.8666669,10.0918202 L11.8666669,10.0918202 Z"
						id="Shape"
					/>
					<path
						d="M12.9775021,6.30498494 C13.2563632,6.78988458 13.5398336,7.06466104 13.5006547,7.27709327 C13.4614759,7.48952549 13.1088664,7.67424916 12.8945351,7.86820902 C12.7009456,7.65115871 12.3713825,7.44565362 12.3529454,7.21474903 C12.3345083,6.98384444 12.6479389,6.75755794 12.9775021,6.30498494 Z"
						id="Path"
					/>
				</g>
			</g>
		</g>
	</svg>
);
