import React from "react";
import styled, {CSSObject, keyframes} from "styled-components";
import {IconClose} from "components/Icons";
import {Button} from "components/Buttons";
import theme from "assets/css/theme";
import {Title} from "components/BaseElements";

const ModalWrapper = styled.div<IProps>`
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.59);
	z-index: 10;
	display: flex;
	justify-content: center;
	align-items: center;
	${(props) => (props.outerStyles ? props.outerStyles : null)}
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const ModalInner = styled.div`
	width: 100%;
	max-width: 90%;
	max-height: 80vh;
	background: #fff;
	position: relative;
	border-radius: 4px;
	box-sizing: border-box;
	padding: 20px 15px 20px;
	animation: ${fadeIn} 0.5s linear;
	overflow: auto;
`;

const CrossWrapper = styled.div`
	width: 46px;
	height: 46px;
	position: absolute;
	top: 18px;
	right: 16px;
	display: flex;
	cursor: pointer;
	margin-left: auto;
	align-items: center;
	justify-content: center;
	z-index: 9;
	img {
		width: 20px;
		margin: 5px;
	}
`;

export const ModalBody = styled.div``;
export const ModalTitle = styled(Title)`
	color: ${theme.secondary};
	font-family: "StyreneA-Bold", sans-serif;
	font-size: 16px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 19px;
	text-align: center;
	text-transform: uppercase;
	margin-bottom: 30px;
`;
export const ModalDescription = styled.div`
	color: #000000;
	font-family: "StyreneA-Regular", sans-serif;
	font-size: 16px;
	letter-spacing: 0;
	line-height: 19px;
	text-align: center;
	margin-bottom: 30px;

	p {
		margin-bottom: 20px;
	}
`;
export const ModalActionButton = styled(Button)`
	color: #000;
	background: transparent;
	border: 1px solid #000;
	font-family: "StyreneA-Bold", sans-serif;
	font-size: 12px;
	letter-spacing: 0;
	line-height: 15px;
	text-align: center;
	text-transform: uppercase;
	display: flex;
	justify-content: center;
	align-items: center;
	max-width: 580px;
	margin: 10px auto 10px;

	&:hover {
		border: 1px solid ${theme.secondary};
		color: #fff !important;
	}
`;

interface IProps {
	children?: React.ReactNode;
	onClose?: () => null | void;
	hide_close?: boolean;
	modalStyles?: CSSObject;
	outerStyles?: CSSObject;
	closeIconColor?: string;
}

export const Modal: React.FC<IProps> = ({
	children = null,
	onClose,
	hide_close,
	modalStyles,
	closeIconColor = "#000",
	...rest
}: IProps) => (
	<ModalWrapper {...rest}>
		<ModalInner style={modalStyles}>
			{!hide_close && (
				<CrossWrapper onClick={onClose}>
					<IconClose width={20} height={20} color={closeIconColor} />
				</CrossWrapper>
			)}
			{children}
		</ModalInner>
	</ModalWrapper>
);
