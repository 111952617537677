import React, {Fragment, useState} from "react";
import {IFixture, IQuestion, IUnit} from "modules/actions";
import {AnswerType, QuestionOption, RoundStatus, UnitStatus} from "modules";
import {Exist} from "components/Exist";
import styled from "styled-components";
import {useSelector} from "react-redux";
import {getUser} from "modules/selectors/user";
import theme from "assets/css/theme";
import {isEmpty} from "lodash";
import {getRoundById} from "modules/selectors/rounds";
import {getSquadById} from "modules/selectors/squads";
import {getPlayerById} from "modules/selectors/players";
import form_women_bg from "assets/img/form_women_bg.png";
import {IconChecked, IconCircle, IconIncorrect} from "components/Icons";

interface IUserProps {
	isUser: boolean;
}

const MatchRow = styled.div`
	width: 100%;
	min-height: 40px;
	background-color: #fff;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: row wrap;
`;
const Cell = styled.div<IUserProps>`
	width: 50%;
	height: 100%;
	display: flex;
	align-items: center;
	padding: 0 20px;
	color: ${(props) => (props.isUser ? theme.primary : "#000")};
	font-family: "StyreneA-Regular", sans-serif;
	font-weight: ${(props) => (props.isUser ? "bold" : "normal")};
	font-size: 14px;
	letter-spacing: 0;
	line-height: 13px;
	text-align: right;
	position: relative;
`;
const PointCell = styled.div`
	height: 28.7px;
	width: 28.7px;
	border: 0.7px solid #d9d9d9;
	background-color: #ffffff;
	border-radius: 50%;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	color: ${theme.secondary};
	font-family: "Hundred-Display-Web", sans-serif;
	font-size: 12px;
	font-weight: normal;
	letter-spacing: 0;
	display: flex;
	justify-content: center;
	align-items: center;
`;
const LeftCell = styled(Cell)`
	justify-content: flex-start;

	${PointCell} {
		right: 10px;
	}
`;
const RightCell = styled(Cell)`
	background-color: #f1f1f1;
	justify-content: flex-end;

	${PointCell} {
		left: 10px;
	}
`;
const IconVS = styled.div`
	height: 28.7px;
	width: 28.7px;
	border: 0.7px solid #d9d9d9;
	background-color: #ffffff;
	border-radius: 50%;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	color: ${theme.secondary};
	font-family: "Hundred-Display-Web", sans-serif;
	font-size: 14px;
	letter-spacing: 0;
	display: flex;
	justify-content: center;
	align-items: center;
`;
const MathHeader = styled.div`
	width: 100%;
	background-color: #000;
	min-height: 73px;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-flow: row wrap;
	padding: 6px 0 20px;
	position: relative;
`;
const MatchNumber = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto 8px;
	width: max-content;
	background-color: ${theme.secondary};
	color: #ffffff;
	font-family: "StyreneA-Bold", sans-serif;
	font-size: 8px;
	font-weight: bold;
	letter-spacing: 0.64px;
	text-align: center;
	padding: 2px;
	box-sizing: border-box;
	text-transform: uppercase;
`;
const MatchTitle = styled.div`
	width: 100%;
	padding: 2px 20px;
	box-sizing: border-box;
	text-transform: uppercase;
	color: #ffffff;
	font-family: "StyreneA-Bold", sans-serif;
	font-size: 12px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 15px;
	text-align: center;
`;

interface IAnswerProps {
	isShow: boolean;
}

const PlayerRow = styled.div`
	width: 100%;
	height: 40px;
	display: flex;
	align-items: center;
	border-bottom: 1px solid #dbdbdb;
	cursor: pointer;
	position: relative;
`;
const AnswerContainer = styled.div<IAnswerProps>`
	display: ${(props) => (props.isShow ? "flex" : "none")};
	height: ${(props) => (props.isShow ? "auto" : "0px")};
	flex-flow: row wrap;
	width: 100%;
	padding: 20px;
	box-sizing: border-box;
	color: #000;
	background: url(${form_women_bg}) no-repeat 100% 50px;
`;
const AnswerCell = styled.div`
	min-height: 68px;
	width: 100%;
	background-color: #ffffff;
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
	margin-bottom: 15px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
`;
const AnswerRow = styled.div`
	width: 100%;
	min-height: 40px;
	display: flex;
	align-items: center;
	position: relative;
	padding: 0 10px;
`;
const LeftUserAnswer = styled.div`
	flex: 1 1 50%;
	padding-right: 50px;
	box-sizing: border-box;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	color: ${theme.secondary};
	font-family: "StyreneA-Bold", sans-serif;
	font-size: 11px;
	font-weight: bold;
	letter-spacing: 0.39px;
	line-height: 13px;
	text-align: left;
`;
const RightUserAnswer = styled.div`
	flex: 1 1 50%;
	padding-left: 50px;
	box-sizing: border-box;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	color: ${theme.secondary};
	font-family: "StyreneA-Bold", sans-serif;
	font-size: 11px;
	font-weight: bold;
	letter-spacing: 0.39px;
	line-height: 13px;
	text-align: right;
`;
const AnswerText = styled.div`
	font-family: "StyreneA-Bold", sans-serif;
	font-size: 11px;
	font-weight: bold;
	letter-spacing: 0.39px;
	line-height: 13px;
	flex: 1 1 50px;
`;
const AnswerLabel = styled.div`
	background: ${theme.secondary};
	padding: 6px 6px;
	box-sizing: border-box;
	color: #fff;
	font-family: "StyreneA-Bold", sans-serif;
	font-size: 11px;
	font-weight: bold;
	letter-spacing: 0.39px;
	line-height: 14px;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 40px;
	width: 98px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

const AnswerPoints = styled.div`
	flex: 1 1 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-flow: column;
	color: #000;
`;
const AnswerIcon = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 4px;
	position: relative;
`;
const Circle = styled.div`
	position: relative;

	svg {
		stroke-width: 1.5px;
	}
`;
const Check = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;
const Points = styled.div`
	font-family: "StyreneA-Bold", sans-serif;
	text-transform: uppercase;
	font-size: 12px;
	letter-spacing: 1px;
	line-height: 14px;
	width: 100%;
	text-align: center;
`;

interface IProps {
	match: IFixture;
	status: RoundStatus;
	roundId: number;
}

export const MatchLine: React.FC<IProps> = (props) => {
	const {match, roundId} = props;
	const user = useSelector(getUser);
	const [toggleAnswer, setToggleAnswer] = useState(false);
	const leftUserAnswers = match.leftUserPredictions.answers;
	const rightUserAnswers = match.rightUserPredictions.answers;
	const hasUsersAnswers = !(isEmpty(leftUserAnswers) && isEmpty(rightUserAnswers));
	const currentRound = useSelector(getRoundById)(roundId);
	const getSquad = useSelector(getSquadById);
	const unitsByCurrentRound = currentRound?.units || [];

	const handleToggleAnswer = () => {
		setToggleAnswer(!toggleAnswer);
	};

	return (
		<Fragment>
			<MatchRow>
				<PlayerRow onClick={handleToggleAnswer}>
					<LeftCell isUser={user?.id === match.leftUser?.id}>
						{match.leftUser?.firstName}
						<Exist when={hasUsersAnswers}>
							<PointCell>{match.leftUserPoints}</PointCell>
						</Exist>
					</LeftCell>
					<RightCell isUser={user?.id === match.rightUser?.id}>
						{match.rightUser?.firstName}
						<Exist when={hasUsersAnswers}>
							<PointCell>{match.rightUserPoints}</PointCell>
						</Exist>
					</RightCell>
					<Exist when={!hasUsersAnswers && currentRound?.status === RoundStatus.active}>
						<IconVS>VS</IconVS>
					</Exist>
				</PlayerRow>
				<AnswerContainer isShow={toggleAnswer}>
					<Exist when={hasUsersAnswers && !!unitsByCurrentRound.length}>
						{unitsByCurrentRound.map((unit, index) => {
							const homeSquad = getSquad(unit.homeSquadId);
							const awaySquad = getSquad(unit.awaySquadId);

							if (!(homeSquad && awaySquad)) {
								return null;
							}

							return (
								<Fragment key={index}>
									<Exist when={index > 0}>
										<MathHeader>
											<MatchNumber>{unit.type}</MatchNumber>
											<MatchTitle>
												{homeSquad.name} VS {awaySquad.name}
											</MatchTitle>
										</MathHeader>
									</Exist>
									<Exist when={unit.status === UnitStatus.complete}>
										<AnswerByUnitsH2H unit={unit} match={match} />
									</Exist>
								</Fragment>
							);
						})}
					</Exist>
					<Exist when={!hasUsersAnswers}>
						<MatchRow>No one player made any predictions</MatchRow>
					</Exist>
				</AnswerContainer>
			</MatchRow>
			{/*</Exist>*/}
		</Fragment>
	);
};

interface IAnswerByUnitProps {
	unit: IUnit;
	match: IFixture;
}

const AnswerByUnitsH2H: React.FC<IAnswerByUnitProps> = ({unit, match}) => {
	const {questions} = unit;

	if (!questions) {
		return null;
	}

	return (
		<Fragment>
			{questions.map((question, index) => {
				return (
					<Fragment key={index}>
						<AnswerH2HItem question={question} match={match} />
					</Fragment>
				);
			})}
		</Fragment>
	);
};

interface IAnswerItemProps {
	question?: IQuestion;
	match: IFixture;
}

// eslint-disable-next-line sonarjs/cognitive-complexity, complexity
const AnswerH2HItem: React.FC<IAnswerItemProps> = ({question, match}) => {
	const leftUserAnswers = match.leftUserPredictions.answers;
	const rightUserAnswers = match.rightUserPredictions.answers;
	const squadById = useSelector(getSquadById);
	const playerById = useSelector(getPlayerById);

	if (!question) {
		return null;
	}
	const isBinary = question.answerType === AnswerType.Binary;
	const isBinaryTeam = isBinary && question.optionType === QuestionOption.Team;
	const isBinaryPlayer = isBinary && question.optionType === QuestionOption.Player;

	const leftAnswer = leftUserAnswers.find((answer) => answer.questionId === question.id);
	const rightAnswer = rightUserAnswers.find((answer) => answer.questionId === question.id);

	const leftAnswerTeam = isBinaryTeam && leftAnswer ? squadById(leftAnswer.answer) : null;
	const leftAnswerPlayer = isBinaryPlayer && leftAnswer ? playerById(leftAnswer.answer) : null;
	const rightAnswerTeam = isBinaryTeam && rightAnswer ? squadById(rightAnswer.answer) : null;
	const rightAnswerPlayer = isBinaryPlayer && rightAnswer ? playerById(rightAnswer.answer) : null;

	const leftFillPercent = leftAnswer
		? Math.ceil((leftAnswer.points / question.maxPoints) * 100)
		: 0;
	const rightFillPercent = rightAnswer
		? Math.ceil((rightAnswer.points / question.maxPoints) * 100)
		: 0;
	const fillPercent = leftAnswer ? leftFillPercent : rightFillPercent;
	const isCorrectPrediction = fillPercent > 0;
	const circleColor = isCorrectPrediction ? theme.primary : "red";

	return (
		<Fragment>
			<AnswerCell>
				<AnswerRow>
					<LeftUserAnswer>
						<Exist when={isBinaryTeam}>
							<AnswerText>{leftAnswerTeam?.name || "-"}</AnswerText>
						</Exist>
						<Exist when={isBinaryPlayer}>
							<AnswerText>{leftAnswerPlayer?.name || "-"}</AnswerText>
						</Exist>
						<Exist when={question.answerType === AnswerType.NumberInput}>
							<AnswerText>{leftAnswer?.answer || "-"}</AnswerText>
						</Exist>
						<AnswerPoints>
							<AnswerIcon>
								<Circle>
									<IconCircle
										width={32}
										height={32}
										color={circleColor}
										fillPercent={isCorrectPrediction ? fillPercent : 100}
									/>
								</Circle>
								<Check>
									{isCorrectPrediction ? (
										<IconChecked color={theme.primary} />
									) : (
										<IconIncorrect />
									)}
								</Check>
							</AnswerIcon>
							<Points>
								{leftAnswer?.points ? leftAnswer.points : 0}/{question.maxPoints}
							</Points>
						</AnswerPoints>
					</LeftUserAnswer>
					<RightUserAnswer>
						<AnswerPoints>
							<AnswerIcon>
								<Circle>
									<IconCircle
										width={32}
										height={32}
										color={circleColor}
										fillPercent={isCorrectPrediction ? fillPercent : 100}
									/>
								</Circle>
								<Check>
									{isCorrectPrediction ? (
										<IconChecked color={theme.primary} />
									) : (
										<IconIncorrect />
									)}
								</Check>
							</AnswerIcon>
							<Points>
								{rightAnswer?.points ? rightAnswer.points : 0}/{question.maxPoints}
							</Points>
						</AnswerPoints>
						<Exist when={isBinaryTeam}>
							<AnswerText>{rightAnswerTeam?.name || "-"}</AnswerText>
						</Exist>
						<Exist when={isBinaryPlayer}>
							<AnswerText>{rightAnswerPlayer?.name || "-"}</AnswerText>
						</Exist>
						<Exist when={question.answerType === AnswerType.NumberInput}>
							<AnswerText>{rightAnswer?.answer || "-"}</AnswerText>
						</Exist>
					</RightUserAnswer>
					<AnswerLabel>{question.answerLabel}</AnswerLabel>
				</AnswerRow>
			</AnswerCell>
		</Fragment>
	);
};
