import {IStore} from "modules/types/store";
import {createSelector} from "reselect";
import {AnswerType, QuestionType, RoundStatus} from "modules/enums";
import {find, findLast, flatMap, includes} from "lodash";

export const getRoundsStore = (state: IStore) => state.rounds;
export const getRounds = createSelector([getRoundsStore], ({rounds}) => rounds);

// export const getIsFetchedRounds = (state: IStore) => state.rounds.isFetched;
export const getRoundsLoadingState = createSelector(
	[getRoundsStore],
	(rounds) => rounds.loadingState
);
export const getCurrentSliderRound = createSelector(
	[getRoundsStore],
	(rounds) => rounds.currentSliderRound
);

export const getMatchDayRounds = createSelector([getRounds], (rounds) =>
	rounds.filter((round) => round.status === (RoundStatus.active || RoundStatus.complete))
);

export const getActualOrCompleteRounds = createSelector([getRounds], (rounds) =>
	rounds.filter((round) => includes([RoundStatus.active, RoundStatus.complete], round.status))
);

export const getRoundById = createSelector(
	getRounds,
	(rounds) => (id: number) => rounds.find((round) => round.id === id)
);

export const getScheduledRounds = createSelector(getRounds, (rounds) =>
	rounds.filter((round) => round.status === RoundStatus.scheduled)
);
export const getFirstScheduledRound = createSelector([getRounds], (rounds) =>
	find(rounds, {status: RoundStatus.scheduled})
);

export const getActiveRounds = createSelector(getRounds, (rounds) => {
	return rounds.find((round) =>
		[RoundStatus.active, RoundStatus.scheduled].includes(round.status)
	);
});
// export const getActiveRound = createSelector([getRounds], (rounds) =>
// 	rounds.find((round) => round.status !== RoundStatus.Complete)
// );

export const getLastCompleteRound = createSelector([getRounds], (rounds) =>
	findLast(rounds, (round) => [RoundStatus.complete].includes(round.status))
);

export const getActualRound = createSelector(
	[getActiveRounds, getLastCompleteRound],
	(activeRound, lastCompleteRound) => activeRound || lastCompleteRound
);

export const getUnitsActiveRounds = createSelector(getActiveRounds, (round) => round?.units);

// Remove
export const getUnitsActiveRoundsWomen = createSelector(getUnitsActiveRounds, (unit) =>
	unit?.find((u) => u.type === QuestionType.Women)
);
export const getUnitsActiveRoundsMen = createSelector(getUnitsActiveRounds, (unit) =>
	unit?.find((u) => u.type === QuestionType.Men)
);

export const getUnitsActiveRoundsByType = createSelector(
	getUnitsActiveRounds,
	(unit) => (type: QuestionType) => unit?.filter((u) => u.type === type)
);

export const getWomenQuestionActiveRound = createSelector(
	getUnitsActiveRoundsWomen,
	(questions) => questions?.questions
);
export const getMenQuestionActiveRound = createSelector(
	getUnitsActiveRoundsMen,
	(questions) => questions?.questions
);

export const getBinaryQuestionWomenActiveRound = createSelector(
	getWomenQuestionActiveRound,
	(questions) => questions?.filter((q) => q.answerType === AnswerType.Binary)
);

export const getBinaryQuestionMenActiveRound = createSelector(
	getMenQuestionActiveRound,
	(questions) => questions?.filter((q) => q.answerType === AnswerType.Binary)
);

export const getBinaryQuestionById = createSelector(
	[getBinaryQuestionWomenActiveRound, getBinaryQuestionMenActiveRound],
	(questions) => (id: number) => questions?.find((q) => q.id === id)
);

export const getCompleteRounds = createSelector(getRounds, (rounds) =>
	rounds.filter((round) => round.status === RoundStatus.complete)
);

export const getAllQuestionFromActiveRound = createSelector(getUnitsActiveRounds, (units) =>
	flatMap(units, (unit) => unit.questions)
);

export const getAllQuestionFromRoundById = createSelector(
	getRoundById,
	(roundSelector) => (id: number) => flatMap(roundSelector(id)?.units, (unit) => unit.questions)
);

export const getQuestionFromActiveRoundById = createSelector(
	getAllQuestionFromActiveRound,
	(questions) => (id: number) => {
		return questions.find((q) => q?.id === id);
	}
);

// export const getNextMatchDay = createSelector(
// 	[getRounds, getCurrentSliderRound],
// 	(rounds, currentRoundId) => {
//
// 	}
// );
