import React from "react";
import styled from "styled-components";
import {Exist} from "components/Exist";
import theme from "assets/css/theme";
import CountdownTimer, {ICountdownState} from "components/CountDownTimer";
import moment from "moment";
import {useSelector} from "react-redux";
import {getRoundById} from "modules/selectors/rounds";

const CounterWrapper = styled.div`
	position: absolute;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	background: #000;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	color: #fff;

	&:before {
		display: block;
		content: "";
		width: 50px;
		height: 50px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border-radius: 50%;
		border: 1px solid #fff;
	}
`;
const CounterBody = styled.div`
	display: flex;
	width: 100%;
	max-width: 110px;
	margin: 5px auto 0;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	flex-direction: row;
`;
const CounterRow = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`;
const Cell = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: ${theme.boldFont};
	font-size: 16px;
	text-align: center;
	margin-bottom: 3px;
`;
const CounterValue = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;
const CounterTitle = styled.p`
	width: 100%;
	font-family: ${theme.baseFont};
	font-size: 10px;
	text-align: center;
	text-transform: uppercase;
`;

const getTimeCopy = (days: boolean, hours: boolean, minutes: boolean, seconds: boolean) => {
	if (days) {
		return "days";
	}
	if (hours) {
		return "hours";
	}
	if (minutes) {
		return "minutes";
	}
	if (seconds) {
		return "seconds";
	}
	return "";
};

const addLeadingZero = (num: number) => String(num).padStart(Number("2"), "0");

const TimerRenderer = ({days, hours, minutes, seconds}: ICountdownState) => {
	const counterTitle = getTimeCopy(!!days, !!hours, !!minutes, !!seconds);
	const showDays = [days, hours, minutes, seconds].every(Boolean);
	const showHours = [!days, hours, minutes, seconds].every(Boolean);
	const showMinutes = [!days, !hours, minutes, seconds].every(Boolean);
	const showSeconds = [!days, !hours, !minutes, seconds].every(Boolean);

	return (
		<CounterWrapper>
			<CounterBody>
				<CounterRow>
					<CounterValue>
						<Exist when={showDays}>
							<Cell>{addLeadingZero(days)}</Cell>
						</Exist>
						<Exist when={showHours}>
							<Cell>{addLeadingZero(hours)}</Cell>
						</Exist>
						{/*<Separator>:</Separator>*/}
						<Exist when={showMinutes}>
							<Cell>{addLeadingZero(minutes)}</Cell>
						</Exist>
						<Exist when={showSeconds}>
							<Cell>{addLeadingZero(seconds)}</Cell>
						</Exist>
					</CounterValue>
					<CounterTitle>{counterTitle}</CounterTitle>
				</CounterRow>
			</CounterBody>
		</CounterWrapper>
	);
};

interface IProps {
	roundId: number;
	// startGameDay: string;
}

export const NextMatchCounter: React.FC<IProps> = ({roundId}) => {
	const round = useSelector(getRoundById)(roundId);
	const startRoundDate = String(round?.date) || new Date();
	const firstUnitStartTime = round?.units[0]?.startDateTime
		? round.units[0].startDateTime
		: round?.date;
	const startUnitTime = firstUnitStartTime || new Date();
	const lockoutDate = moment(startUnitTime).toDate();

	const isTimeOut = Number(new Date(startRoundDate)) <= Number(new Date());

	return !isTimeOut ? (
		<CountdownTimer key={roundId} date={lockoutDate}>
			{TimerRenderer}
		</CountdownTimer>
	) : null;
};
