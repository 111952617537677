import React, {Fragment, useCallback, useState} from "react";
import {IRound} from "modules/actions";
import {Exist} from "components/Exist";
import {QuestionType, RoundStatus} from "modules";
import logo from "assets/img/logo-horizont.svg";
import {ComingSoonCounter} from "components/ComingSoonCounter";
import {Title} from "components/BaseElements";
import {TabsMenWomen} from "components/TabsMenWomen";
import {useSelector} from "react-redux";
import {getSquadById} from "modules/selectors/squads";
import {getRoundRankings} from "modules/selectors/rankings";
import styled from "styled-components";
import theme from "assets/css/theme";
import csTopBg from "assets/img/cs_top_bg.png";
import csBotBg from "assets/img/cs_bottom_bg.png";
import {Link} from "react-router-dom";
import {BottomCountDownTimer} from "components/BottomCountDownTimer";
import {PointsRow} from "components/PointsRow";
import {isBefore} from "date-fns";
import {find, isNull} from "lodash";
import {NextMatchDayBanner} from "components/NextMatchDayBanner";

const SliderItem = styled.div`
	width: 100%;
	position: relative;
	overflow: hidden;
	padding-top: 62px;

	&.no_padding {
		padding-top: 0;
	}
`;
const MathHeader = styled.div`
	width: 100%;
	background-color: #000;
	min-height: 73px;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-flow: row wrap;
	padding: 6px 0 20px;
	position: relative;
`;
const MatchNumber = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto 8px;
	width: max-content;
	background-color: ${theme.secondary};
	color: #ffffff;
	font-family: "StyreneA-Bold", sans-serif;
	font-size: 8px;
	font-weight: bold;
	letter-spacing: 0.64px;
	text-align: center;
	padding: 2px;
	box-sizing: border-box;
	text-transform: uppercase;
`;
const MatchTitle = styled.div`
	max-width: calc(100vw - 40px);
	width: 100%;
	padding: 2px 40px;
	box-sizing: border-box;
	text-transform: uppercase;
	color: #ffffff;
	font-family: "StyreneA-Bold", sans-serif;
	font-size: 12px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 15px;
	text-align: center;
`;
export const PredictorWillBeSoon = styled.div`
	width: 100%;
	position: relative;
	min-height: 585px;
	background: #000;
	display: flex;
	flex-flow: row wrap;

	@media (max-width: 565px) {
		min-height: 500px;
	}

	h3 {
		color: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;
export const TopBox = styled.div`
	min-height: 250px;
	width: 100%;
	background-color: #000;
	color: #fff;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;

	h3 {
		padding: 0 30px;
		text-align: center;
	}

	&:before,
	&:after {
		position: absolute;
		display: block;
		content: "";
		width: 144px;
		height: 60px;
	}

	&:before {
		left: 0;
		top: 0;
		background: url(${csTopBg}) no-repeat 50% 50%;
		background-size: cover;
	}

	&:after {
		right: 0;
		bottom: 0;
		background: url(${csBotBg}) no-repeat 50% 50%;
		background-size: cover;
		display: none;
	}
`;
export const BottomBox = styled.div`
	min-height: 250px;
	width: 100%;
	background-color: ${theme.primary};
	display: flex;
	justify-content: center;
	align-items: flex-end;
	flex-flow: row wrap;
	padding: 60px 30px 40px;
	box-sizing: border-box;

	div {
		color: black;
	}
`;
export const StartMatchDay = styled.div`
	width: 100%;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	font-family: "StyreneA-Regular", sans-serif;
	font-size: 20px;
	color: #fff;
	padding: 0 20px;
	text-transform: uppercase;
	text-align: center;

	strong {
		font-family: "StyreneA-Bold", sans-serif;
		font-weight: bold;
		display: inline-block;
	}
`;
const PointsRowEmpty = styled.div`
	width: 100%;
	height: 62px;
	background: #000;
	display: flex;
	justify-content: flex-start;
	align-items: center;
`;
const MatchPoints = styled.div`
	color: ${theme.primary};
	font-family: "StyreneA-Bold", sans-serif;
	font-size: 16px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 19px;
`;
const LogoWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	flex-flow: row wrap;
	padding: 5px 10px;
	box-sizing: border-box;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;
const LogoBox = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-bottom: 15px;
`;
const LogoLink = styled(Link)`
	display: block;
	width: 210px;
	margin-right: 5px;
`;
const Logo = styled.img`
	display: block;
	width: 100%;
`;
const LogoAfter = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: row wrap;
	width: 60px;
	//margin-top: 20px;
	//margin-left: -20px;
	font-family: "Hundred-Display-Web", sans-serif;

	div {
		width: 100%;
		background: ${theme.secondary};
		color: #000;
		text-align: center;
		text-transform: uppercase;
		font-size: 13px;
		padding: 2px 0;
	}

	p {
		width: 100%;
		color: #fff;
		text-transform: uppercase;
		font-size: 10px;
		letter-spacing: 0.8px;
		text-align: center;
	}
`;
const LogoPageName = styled.div`
	color: ${theme.primary};
	font-family: "Hundred-Display-Web", sans-serif;
	font-size: 37.45px;
	letter-spacing: 0;
	line-height: 32.55px;
	text-transform: uppercase;
`;

// const getSecondInningEnd = (units: IUnit[]) => {
// 	const isDoubleHeader = units.length > 1;
//
// 	return isDoubleHeader
// 		? units[0].ballsCountdown < 80 || units[1].ballsCountdown < 80
// 		: units[0].ballsCountdown < 80;
// };

interface IProps {
	round: IRound;
	onErrorModal: (errorSend: boolean) => void;
	onEarlyVictory: (finish: boolean) => void;
}

export const SliderMatchDayItem: React.FC<IProps> = ({round, onEarlyVictory, onErrorModal}) => {
	const getSquad = useSelector(getSquadById);
	const currentRoundRankings = useSelector(getRoundRankings);
	const [unitIndex, setUnitIndex] = useState(0);
	const units = round.units;

	const firstUnitStartTime = round.units[0]?.startDateTime
		? round.units[0].startDateTime
		: round.date;

	const isFutureTime = isBefore(Date.now(), new Date(firstUnitStartTime));
	const timeForStartRound = +new Date(firstUnitStartTime) - Date.now();
	const homeSquad = getSquad(round.units[unitIndex]?.homeSquadId);
	const awaySquad = getSquad(round.units[unitIndex]?.awaySquadId);
	const isDoubleHeader = units.length > 1;

	const questions = units[0].questions;
	const hasAnswer = find(questions, (q) => !isNull(q.correctAnswer));

	// const isSecondInningEnd = getSecondInningEnd(units);

	const checkTabStatus = useCallback((tabStatus: QuestionType) => {
		if (tabStatus === QuestionType.Women) {
			setUnitIndex(0);
		} else {
			setUnitIndex(1);
		}
	}, []);

	return (
		<Fragment>
			<Exist when={round.status === RoundStatus.scheduled && round.number === 1}>
				<SliderItem className={"no_padding"}>
					<PointsRow roundId={round.id} isEmpty={true} />
					<PointsRowEmpty />
					<MathHeader>
						<MatchNumber>Match day {round.number}</MatchNumber>
						<MatchTitle>
							{homeSquad?.name} VS {awaySquad?.name}
						</MatchTitle>
					</MathHeader>
					<PredictorWillBeSoon>
						<TopBox>
							<LogoWrapper>
								<LogoBox>
									<LogoLink to="/">
										<Logo src={logo} alt="ecb-hundred-predictor" />
									</LogoLink>
									<LogoAfter>
										<div>20 ball</div>
										<p>Predictor</p>
									</LogoAfter>
								</LogoBox>
								<LogoPageName>Coming soon</LogoPageName>
							</LogoWrapper>
						</TopBox>
						<BottomBox>
							<Exist when={Boolean(timeForStartRound)}>
								<StartMatchDay>
									Questions for the 20-Ball Predictor will be available in
								</StartMatchDay>
								<BottomCountDownTimer time={Number(new Date(firstUnitStartTime))} />
							</Exist>
						</BottomBox>
						<ComingSoonCounter
							roundId={round.id}
							startGameDay={Number(new Date(firstUnitStartTime))}
						/>
					</PredictorWillBeSoon>
				</SliderItem>
			</Exist>
			<Exist when={round.status === RoundStatus.scheduled && round.number !== 1}>
				<SliderItem className={"no_padding"}>
					<PointsRow roundId={round.id} isEmpty={true} />
					<PointsRowEmpty />
					<MathHeader>
						<MatchNumber>Match day {round.number}</MatchNumber>
						<MatchTitle>
							{homeSquad?.name} VS {awaySquad?.name}
						</MatchTitle>
					</MathHeader>
					<PredictorWillBeSoon>
						<TopBox>
							<Title>PREDICTOR QUESTIONS ARE COMING SOON</Title>
						</TopBox>
						<BottomBox>
							<Exist when={Boolean(timeForStartRound)}>
								<Exist when={isFutureTime}>
									<StartMatchDay>
										The prediction questions will be available in
									</StartMatchDay>
								</Exist>
								<BottomCountDownTimer time={Number(new Date(firstUnitStartTime))} />
							</Exist>
						</BottomBox>
						<ComingSoonCounter
							roundId={round.id}
							startGameDay={Number(new Date(firstUnitStartTime))}
						/>
					</PredictorWillBeSoon>
				</SliderItem>
			</Exist>
			<Exist when={round.status === RoundStatus.active}>
				<SliderItem>
					<MathHeader>
						<Exist when={!isDoubleHeader}>
							<MatchNumber>Match day {round.number}</MatchNumber>
						</Exist>
						<Exist when={isDoubleHeader}>
							<MatchNumber>Match day {round.number} - double header</MatchNumber>
						</Exist>
						<MatchTitle>
							{homeSquad?.name} VS {awaySquad?.name}
						</MatchTitle>
						<Exist when={!!hasAnswer}>
							<MatchPoints>{currentRoundRankings?.roundPoints || 0} PTS</MatchPoints>
						</Exist>
					</MathHeader>
					<TabsMenWomen
						matchDayId={round.id}
						onErrorModal={onErrorModal}
						onEarlyVictory={onEarlyVictory}
						getTabStatus={checkTabStatus}
					/>
					{/*<NextMatchDayBanner />*/}
				</SliderItem>
			</Exist>
			<Exist when={round.status === RoundStatus.complete}>
				<SliderItem>
					<MathHeader>
						<Exist when={!isDoubleHeader}>
							<MatchNumber>Match day {round.number}</MatchNumber>
						</Exist>
						<Exist when={isDoubleHeader}>
							<MatchNumber>Match day {round.number} - double header</MatchNumber>
						</Exist>
						<MatchTitle>
							{homeSquad?.name} VS {awaySquad?.name}
						</MatchTitle>
						<MatchPoints>{currentRoundRankings?.roundPoints || 0} PTS</MatchPoints>
					</MathHeader>
					<TabsMenWomen
						matchDayId={round.id}
						onErrorModal={onErrorModal}
						onEarlyVictory={onEarlyVictory}
						getTabStatus={checkTabStatus}
					/>
					<NextMatchDayBanner roundId={round.id} />
				</SliderItem>
			</Exist>
		</Fragment>
	);
};
