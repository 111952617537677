import {createReducer} from "redux-act";
import {fetchHelpPageJSONSuccess, postContactFormSuccess} from "modules/actions/help";

export interface IHelpReducer {
	gameplay: IHelpResponse;
	terms: IHelpResponse;
	faq: IHelpResponse;
	prizes: IHelpResponse;
	policy: IHelpResponse;
	cookies: IHelpResponse;
	isSend: boolean;
}

const initialState: IHelpReducer = {
	gameplay: {
		title: "",
		text: "",
	},
	terms: {
		title: "",
		text: "",
	},
	faq: {
		title: "",
		text: "",
	},
	prizes: {
		title: "",
		text: "",
	},
	policy: {
		title: "",
		text: "",
	},
	cookies: {
		title: "",
		text: "",
	},
	isSend: false,
};

export interface IHelpResponse {
	title: string;
	text: string;
}

export const help = createReducer<IHelpReducer>({}, initialState)
	.on(fetchHelpPageJSONSuccess, (state, payload) => ({
		...state,
		...payload,
	}))
	.on(postContactFormSuccess, (state) => ({
		...state,
		isSend: true,
	}));
