import {createReducer} from "redux-act";
import {
	clearFilter,
	clearRankings,
	getOverallRankingsSuccess,
	getRegularLeagueRankingsSuccess,
	getRoundPointsSuccess,
	IRanking,
	loadMoreRankingsSuccess,
	updateFilter,
} from "modules/actions";
import {Direction, OrderRanking} from "modules/enums";

export interface IRankingsReducer {
	ranking: IRanking;
	overallRankings: IOverallRankingResponse;
	filter: IFilterRankings;
}

export interface IFilterRankings {
	roundId?: number;
	limit: number;
	page: number;
	order: OrderRanking;
	direction: Direction;
}

export interface IOverallRankingResponse {
	currentUserRanking?: ICurrentUserRanking;
	rankings: IRanking[];
	nextPage: boolean;
}

export interface ICurrentUserRanking {
	userId: number;
	firstName: string;
	lastName: string;
	roundPoints: number;
	points: number;
	rank: number;
}

const defaultFilter: IFilterRankings = {
	limit: 100,
	page: 1,
	order: OrderRanking.Rank,
	direction: Direction.ASC,
};

const initialState: IRankingsReducer = {
	ranking: {
		userId: 0,
		roundId: 0,
		firstName: "",
		lastName: "",
		roundPoints: 0,
		points: 0,
		rank: 0,
	},
	overallRankings: {
		rankings: [],
		nextPage: false,
	},
	// leagueRankings: {
	// 	rankings: [],
	// 	nextPage: false,
	// },
	filter: defaultFilter,
};

const onGetRankings = (
	state: IRankingsReducer,
	{currentUserRanking, rankings, nextPage}: IOverallRankingResponse
) => ({
	...state,
	overallRankings: {
		...state.overallRankings,
		currentUserRanking: state.overallRankings.currentUserRanking || currentUserRanking,
		rankings,
		nextPage,
	},
});

export const rankings = createReducer<IRankingsReducer>({}, initialState)
	.on(getRoundPointsSuccess, (state, ranking) => ({
		...state,
		ranking: ranking,
	}))
	.on(getOverallRankingsSuccess, onGetRankings)
	.on(clearFilter, (state) => ({
		...state,
		filter: defaultFilter,
	}))
	.on(updateFilter, (state, payload) => ({
		...state,
		filter: {
			...state.filter,
			...payload,
		},
	}))
	.on(getRegularLeagueRankingsSuccess, onGetRankings)
	.on(loadMoreRankingsSuccess, (state, {currentUserRanking, rankings, nextPage, filter}) => ({
		...state,
		overallRankings: {
			...state.overallRankings,
			currentUserRanking: state.overallRankings.currentUserRanking || currentUserRanking,
			rankings: [...state.overallRankings.rankings, ...rankings],
			nextPage,
		},
		filter: {
			...state.filter,
			page: filter.page || state.filter.page,
		},
		// filter: {
		// 	...state.filter,
		// 	...filter
		// }
	}))
	.on(clearRankings, (state) => ({
		...state,
		overallRankings: initialState.overallRankings,
		filter: defaultFilter,
	}));
