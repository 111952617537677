import React, {Fragment} from "react";
import {IQuestion, IUnit} from "modules/actions";
import {useSelector} from "react-redux";
import {getPredictionAnswerByUnitId} from "modules/selectors/prediction";
import styled from "styled-components";
import {Exist} from "components/Exist";
import {getSquadById} from "modules/selectors/squads";
import {getPlayerById} from "modules/selectors/players";
import {AnswerType, QuestionOption} from "modules";
import {eq, every, gt, isEmpty, isNull, size} from "lodash";
import {IconChecked, IconCircle, IconIncorrect} from "components/Icons";
import theme from "assets/css/theme";

const AnswerItem = styled.div`
	min-height: 40px;
	width: 100%;
	background-color: #ffffff;
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
	box-sizing: border-box;
	display: flex;
	flex-flow: row wrap;
	margin-bottom: 20px;
`;
const AnswerPrediction = styled.div`
	padding: 15px 12px;
	box-sizing: border-box;
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
`;
const QuestionText = styled.div`
	color: #343232;
	font-family: "StyreneA-Regular", sans-serif;
	font-size: 12px;
	letter-spacing: 1px;
	line-height: 16px;
`;
const AnswerBody = styled.div`
	flex: 1 1 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-flow: row wrap;
	padding-right: 10px;
	box-sizing: border-box;
`;
const AnswerText = styled.div`
	font-family: "StyreneA-Bold", sans-serif;
	text-transform: uppercase;
	font-size: 14px;
	letter-spacing: 1px;
	line-height: 18px;
	width: 100%;
	margin-top: 10px;
`;
const DrawAnswer = styled.div`
	flex: 1 1 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-flow: column;
	position: relative;

	&:before {
		display: flex;
		content: "...";
		color: #000;
		width: 32px;
		height: 32px;
		border: 2px solid #000;
		border-radius: 50%;
		box-sizing: border-box;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
`;
const AnswerPoints = styled.div`
	flex: 1 1 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-flow: column;
`;
const CorrectAnswer = styled.div`
	padding: 8px 10px;
	box-sizing: border-box;
	background-color: #000;
	color: #fff;
	//min-height: 36px;
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
`;
const AnswerIcon = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 4px;
	position: relative;
`;
const Circle = styled.div`
	position: relative;

	svg {
		stroke-width: 1.5px;
		//stroke-dashoffset: 50;
		//stroke-opacity: 0;
	}
`;
const Check = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;
const Points = styled.div`
	font-family: "StyreneA-Bold", sans-serif;
	text-transform: uppercase;
	font-size: 12px;
	letter-spacing: 1px;
	line-height: 14px;
	width: 100%;
	text-align: center;
`;
const NoAnswer = styled.div`
	flex: 1 1 75px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-flow: column;
	font-size: 10px;
	font-weight: bold;
	text-align: center;
`;

const getCircleColor = (value: boolean) => (value ? theme.primary : "red");

const getPoints = (points: number | null | undefined) => {
	if (points) {
		return `${points} PTS`;
	} else {
		return "0 PTS";
	}
};

const getClassName = (isBinary: boolean, isRange: boolean) => {
	if (isBinary) {
		return "first_step";
	}
	if (isRange) {
		return "second_step";
	}
	return "";
};

interface IAnswerProps {
	question: IQuestion;
	unit: IUnit;
	index: number;
}

// eslint-disable-next-line sonarjs/cognitive-complexity, complexity
export const AnswerUnit: React.FC<IAnswerProps> = ({question, unit, index}) => {
	const {id} = unit;
	const predictionAnswer = useSelector(getPredictionAnswerByUnitId)(id);
	const squadById = useSelector(getSquadById);
	const playerById = useSelector(getPlayerById);

	const hasAnswer = !isEmpty(predictionAnswer);
	const isTie = eq(question.correctAnswer, -713);
	const hasCorrectAnswer = !isNull(question.correctAnswer);

	const isBinary = eq(question.answerType, AnswerType.Binary);
	const isRange = eq(question.answerType, AnswerType.NumberInput);
	const isBinaryTeam = every([isBinary, eq(question.optionType, QuestionOption.Team)]);
	const isBinaryPlayer = every([isBinary, eq(question.optionType, QuestionOption.Player)]);

	const isFirstBinaryQuestion = [eq(index, 0), isBinary].every(Boolean);
	const isFirstRangeQuestion = [eq(index, 1), isRange].every(Boolean);

	const currentAnswer = predictionAnswer
		? predictionAnswer.find((a) => eq(a.questionId, question.id))
		: null;

	const points = getPoints(currentAnswer?.points);

	const answerTeam = isBinaryTeam && currentAnswer ? squadById(currentAnswer.answer) : null;
	const answerPlayer = isBinaryPlayer && currentAnswer ? playerById(currentAnswer.answer) : null;
	const correctAnswerTeam =
		isBinaryTeam && currentAnswer ? squadById(question.correctAnswer) : null;
	const correctAnswerPlayer =
		isBinaryPlayer && currentAnswer ? playerById(question.correctAnswer) : null;

	const correctAnswerTitle = String(question.correctAnswerTitle);

	const fillPercent =
		currentAnswer && currentAnswer.points
			? Math.ceil((currentAnswer.points / question.maxPoints) * 100)
			: 0;
	const isCorrectPrediction = gt(fillPercent, 0);
	const circleColor = getCircleColor(isCorrectPrediction);
	const isCorrectAnswerPlayerShow = Boolean(correctAnswerPlayer?.name);

	return (
		<Fragment>
			<Exist when={hasAnswer}>
				<AnswerItem className={getClassName(isFirstBinaryQuestion, isFirstRangeQuestion)}>
					<AnswerPrediction>
						<AnswerBody>
							<QuestionText>{question.title}</QuestionText>
							<Exist when={isBinaryTeam}>
								<AnswerText>{answerTeam?.name}</AnswerText>
							</Exist>
							<Exist when={isBinaryPlayer}>
								<AnswerText>{answerPlayer?.name}</AnswerText>
							</Exist>
							<Exist when={eq(question.answerType, AnswerType.NumberInput)}>
								<AnswerText>
									{currentAnswer?.answer} {question.answerLabel}
								</AnswerText>
							</Exist>
						</AnswerBody>
						<Exist when={!hasCorrectAnswer}>
							<DrawAnswer />
						</Exist>
						<Exist when={hasCorrectAnswer}>
							<AnswerPoints>
								<AnswerIcon>
									<Circle>
										<IconCircle
											width={32}
											height={32}
											color={circleColor}
											fillPercent={isCorrectPrediction ? fillPercent : 100}
										/>
									</Circle>
									<Check>
										{isCorrectPrediction ? (
											<IconChecked color={theme.primary} />
										) : (
											<IconIncorrect />
										)}
									</Check>
								</AnswerIcon>
								<Points>
									{/*{currentAnswer?.points}/{question.maxPoints}*/}
									{points}
								</Points>
							</AnswerPoints>
						</Exist>
					</AnswerPrediction>
					{size(correctAnswerTitle) ? (
						<Exist when={hasCorrectAnswer}>
							<CorrectAnswer>{correctAnswerTitle}</CorrectAnswer>
						</Exist>
					) : (
						<Fragment>
							<Exist when={hasCorrectAnswer}>
								<Exist when={isBinaryTeam}>
									<CorrectAnswer>{correctAnswerTeam?.name}</CorrectAnswer>
								</Exist>

								<Exist when={!isBinaryTeam}>
									<Exist when={eq(question.answerType, AnswerType.NumberInput)}>
										<CorrectAnswer>
											{isTie
												? "Tie"
												: `${question.correctAnswer} ${question.answerLabel}`}
										</CorrectAnswer>
									</Exist>

									<Exist
										when={
											!eq(question.answerType, AnswerType.NumberInput) &&
											!isCorrectAnswerPlayerShow
										}>
										<CorrectAnswer>
											{isTie ? "Tie" : question.answerLabel}
										</CorrectAnswer>
									</Exist>
								</Exist>

								<Exist when={isBinaryPlayer}>
									<Exist when={isCorrectAnswerPlayerShow}>
										<CorrectAnswer>{correctAnswerPlayer?.name}</CorrectAnswer>
									</Exist>
								</Exist>
							</Exist>
						</Fragment>
					)}
				</AnswerItem>
			</Exist>
			<Exist when={!hasAnswer}>
				<AnswerItem className={getClassName(isFirstBinaryQuestion, isFirstRangeQuestion)}>
					<AnswerPrediction>
						<AnswerBody>
							<QuestionText>{question.title}</QuestionText>
						</AnswerBody>
						<NoAnswer>Not answered</NoAnswer>
					</AnswerPrediction>
					<Exist when={!!question.correctAnswerTitle}>
						<CorrectAnswer>{question.correctAnswerTitle}</CorrectAnswer>
					</Exist>
				</AnswerItem>
			</Exist>
		</Fragment>
	);
};
