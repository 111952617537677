import {createReducer} from "redux-act";
import {
	fetchRoundsJSON,
	fetchRoundsJSONError,
	fetchRoundsJSONSuccess,
	IRound,
	setCurrentSliderRound,
} from "modules/actions/rounds";
import {RequestState} from "modules/enums";

export interface IRoundsReducer {
	loadingState: RequestState;
	rounds: IRound[];
	currentSliderRound: number;
	// isFetched: boolean;
}

const initialState: IRoundsReducer = {
	loadingState: RequestState.IDLE,
	rounds: [],
	currentSliderRound: 0,
	// isFetched: false,
};

export const rounds = createReducer<IRoundsReducer>({}, initialState)
	.on(fetchRoundsJSON, () => ({
		...initialState,
		loadingState: RequestState.Requested,
	}))
	.on(fetchRoundsJSONSuccess, (state, rounds) => ({
		...state,
		rounds,
		// isFetched: true,
		loadingState: RequestState.Received,
	}))
	.on(fetchRoundsJSONError, (state) => ({
		...state,
		loadingState: RequestState.IDLE,
	}))
	.on(setCurrentSliderRound, (state, payload) => ({
		...state,
		currentSliderRound: payload.roundId,
	}));
