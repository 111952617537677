import React, {Fragment} from "react";
import {ILeague} from "modules/reducers";
import {useDispatch, useSelector} from "react-redux";
import {getMyLeagues} from "modules/selectors/leagues";
import {Exist} from "components/Exist";
import {NavLink} from "react-router-dom";
import {LeagueClass} from "modules";
import {partial} from "lodash";
import {IconChecked} from "components/Icons";
import {postJoinLeague} from "modules/actions";
import styled from "styled-components";
import theme from "assets/css/theme";

const LeagueContainer = styled.div`
	min-height: 53px;
	width: 100%;
	background-color: #ffffff;
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
	padding: 5px 15px;
	box-sizing: border-box;
	display: flex;
	flex-flow: row wrap;
	margin-bottom: 20px;
`;
const LeagueTitle = styled.div`
	position: relative;
	padding: 10px 105px 10px 0;
	width: 100%;
`;
const LeagueName = styled.strong`
	color: #000000;
	font-family: "StyreneA-Bold", sans-serif;
	font-size: 12px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 15px;
	display: block;
	margin-bottom: 3px;
	text-transform: uppercase;
`;
const LeagueNameLink = styled(LeagueName)`
	&:hover {
		text-decoration: underline;
	}
`;
const LeagueManager = styled.p`
	color: #545454;
	font-family: "StyreneA-Regular", sans-serif;
	font-size: 12px;
	letter-spacing: 0;
	line-height: 15px;
`;
const JoinButton = styled.button`
	width: 100%;
	max-width: 100px;
	height: 28px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 50%;
	right: 0;
	background: ${theme.primary};
	color: #ffffff;
	font-family: "StyreneA-Bold", sans-serif;
	font-size: 12px;
	font-weight: bold;
	letter-spacing: 0;
	text-align: center;
	transform: translateY(-50%);
	text-transform: uppercase;
	border: none;
	outline: none;
	transition: all ease 0.3s;

	&:hover {
		background: ${theme.primaryActive};
	}
`;
const LinkToLeague = styled(JoinButton)`
	background: ${theme.primaryActive};

	svg {
		position: absolute;
		top: 50%;
		right: 5px;
		transform: translateY(-50%);
	}
`;
const LeagueClassName = styled.div`
	color: #000000;
	font-family: "StyreneA-Bold", sans-serif;
	font-size: 12px;
	font-weight: bold;
	letter-spacing: 0;
	text-transform: uppercase;
	background: #f1f1f1;
	padding: 10px 20px;
	box-sizing: border-box;
	margin-bottom: 15px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
`;

interface IProps {
	leagues: ILeague[];
}

export const LeagueForJoin: React.FC<IProps> = ({leagues}) => {
	const dispatch = useDispatch();
	const myLeagues = useSelector(getMyLeagues);

	const onJoin = (code: string) => {
		dispatch(postJoinLeague({code}));
	};

	return (
		<Fragment>
			{leagues.map((league, index) => {
				const hasCommissioner = Boolean(league.commissioner);
				const managerName = hasCommissioner ? league.commissioner?.firstName : league.class;
				const isJoined = Boolean(myLeagues.find((el) => el.id === league.id));
				const emptyPlaces = Number(league.size) - league.numberJoins;
				const isEmptyPlaces = !isJoined && emptyPlaces > 0;
				const isOfficialLeague = league.class === LeagueClass.club;

				return (
					<Fragment key={league.id}>
						<Exist when={index === 0}>
							<LeagueClassName>{league.classLabel}</LeagueClassName>
						</Exist>
						<LeagueContainer key={league.id}>
							<LeagueTitle>
								<Exist when={!isJoined}>
									<LeagueName>{league.name}</LeagueName>
								</Exist>
								<Exist when={isJoined}>
									<LeagueNameLink as={NavLink} to={`/leagues/${league.id}/about`}>
										{league.name}
									</LeagueNameLink>
								</Exist>
								<Exist when={isOfficialLeague}>
									<LeagueManager>Official Fan League</LeagueManager>
									<LeagueManager>Public Classic League</LeagueManager>
								</Exist>
								<Exist when={!isOfficialLeague}>
									<LeagueManager>{managerName}</LeagueManager>
								</Exist>
								<Exist when={isEmptyPlaces || league.size === "0"}>
									<JoinButton onClick={partial(onJoin, league.code)}>
										Join
									</JoinButton>
								</Exist>
								<Exist when={isJoined}>
									<LinkToLeague as={NavLink} to={`/leagues/${league.id}/about`}>
										Joined
										<IconChecked color={"#FFF"} />
									</LinkToLeague>
								</Exist>
							</LeagueTitle>
						</LeagueContainer>
					</Fragment>
				);
			})}
		</Fragment>
	);
};
