import {createReducer} from "redux-act";
import {showGlobalError, clearGlobalError} from "modules/actions";
import {IError} from "modules/types";

interface IErrorReducer {
	message?: string;
}

export const error = createReducer<IErrorReducer>({}, {})
	.on(showGlobalError, (state: IErrorReducer, {message}: IError) => ({message}))
	.on(clearGlobalError, () => ({}));
