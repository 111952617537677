import React from "react";
import {IIconProps} from "modules/types";

export const IconPredictor: React.FC<IIconProps> = ({width = 31, height = 21, color = "#FFF"}) => (
	<svg
		width={`${width}px`}
		height={`${height}px`}
		viewBox="0 0 31 21"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg">
		<g id="Symbols" stroke="none" fill="none">
			<g id="noun_Eye_566335" transform="translate(-28.000000, -10.000000)">
				<g transform="translate(29.000000, 11.000000)">
					<path
						d="M28.9011346,9.0832972 C26.0688862,3.4804622 20.5510097,1.13686838e-13 14.5,1.13686838e-13 C8.44899031,1.13686838e-13 2.93111377,3.4804622 0.0988653964,9.0832972 C-0.0329551321,9.34438559 -0.0329551321,9.65597255 0.0988653964,9.91706094 C2.9307696,15.5198959 8.44899031,19 14.5,19 C20.5510097,19 26.0692304,15.5195378 28.9011346,9.91706094 C29.0329551,9.65597255 29.0329551,9.34438559 28.9011346,9.0832972 Z M14.5000005,18.1592705 C8.85305587,18.1592705 3.68845599,14.8549455 0.905271484,9.50020124 C3.68845599,4.14505465 8.85305587,0.840729675 14.5000005,0.840729675 C20.1469451,0.840729675 25.3115449,4.14505465 28.0947295,9.50020124 C25.3115449,14.8553478 20.1469451,18.1592705 14.5000005,18.1592705 Z M15.1935205,4.17526531 C14.5512827,4.0878777 13.928663,4.12405044 13.3435588,4.26336921 C13.1590789,4.30742116 13.059267,4.51048991 13.1391165,4.68884658 C13.3507866,5.16267365 13.4385522,5.70777177 13.3542284,6.28223785 C13.1855807,7.43152815 12.3096453,8.3745264 11.2103378,8.58081846 C10.7040506,8.67572713 10.2187583,8.62021451 9.78612544,8.45188592 C9.61300344,8.38455449 9.42680264,8.50131006 9.39823579,8.69148555 C9.2853451,9.44359202 9.31769792,10.2451226 9.58546912,11.0849748 C10.270041,13.232776 12.1492579,14.793576 14.323092,14.8691448 C17.5112214,14.9798119 20.0791404,12.0820531 19.6062385,8.69435072 C19.2792686,6.3520763 17.4492692,4.48219638 15.1935205,4.17526531 Z"
						id="Shape"
						stroke={color}
						fill="none"
					/>
				</g>
			</g>
		</g>
	</svg>
);
