import React from "react";
import {IRanking} from "modules/actions";
import styled, {css} from "styled-components";
import {OrderRanking} from "modules";
import {useSelector} from "react-redux";
import {getUser} from "modules/selectors/user";
import medalBg from "assets/img/medal-bg.png";
import {userNameForRankings} from "modules/utils/help";

interface IRowProps {
	isYou?: boolean;
	winnerState?: number;
}
interface ICellProps {
	isActive?: boolean;
}

enum EnumWinnerState {
	gold = 1,
	silver,
	bronze,
}

const getWinner = ({winnerState, isYou}: IRowProps): ReturnType<typeof css> => {
	const rank = winnerState as EnumWinnerState;
	const states: Record<EnumWinnerState, ReturnType<typeof css>> = {
		[EnumWinnerState.gold]: css`
			background: ${isYou ? "#32C84B" : "#FF0082"};
			color: #fff;
			border-bottom: none;
			> div {
				border-bottom: none;
			}
			&:after {
				display: flex;
				content: "${rank}";
				color: #000;
				justify-content: center;
				align-items: flex-end;
				padding-bottom: 5px;
				font-size: 10px;
				font-weight: bold;
				width: 33px;
				height: 35px;
				position: absolute;
				top: 0;
				right: -4px;
				background: url(${medalBg}) no-repeat 0 0;
				z-index: 3;
			}
		`,
		[EnumWinnerState.silver]: css`
			//background: rgba(255,0,130,0.6);
			background: ${isYou ? "#32C84B" : "rgba(255,0,130,0.6)"};
			color: #fff;
			border-bottom: none;
			> div {
				border-bottom: none;
			}
			&:after {
				display: flex;
				content: "${rank}";
				color: #000;
				justify-content: center;
				align-items: flex-end;
				padding-bottom: 5px;
				font-size: 10px;
				font-weight: bold;
				width: 33px;
				height: 35px;
				position: absolute;
				top: 0;
				right: -4px;
				background: url(${medalBg}) no-repeat 0 0;
				z-index: 3;
			}
		`,
		[EnumWinnerState.bronze]: css`
			//background: rgba(255,0,130,0.4);
			background: ${isYou ? "#32C84B" : "rgba(255,0,130,0.4)"};
			color: #fff;
			border-bottom: none;
			> div {
				border-bottom: none;
			}

			&:after {
				display: flex;
				content: "${rank}";
				color: #000;
				justify-content: center;
				align-items: flex-end;
				padding-bottom: 5px;
				font-size: 10px;
				font-weight: bold;
				width: 33px;
				height: 35px;
				position: absolute;
				top: 0;
				right: -4px;
				background: url(${medalBg}) no-repeat 0 0;
				z-index: 3;
			}
		`,
	};

	if (!winnerState || !states[rank]) {
		return css`
			background: ${isYou ? "#32C84B" : "transparent"};
		`;
	}

	return states[rank];
};

const TableRow = styled.div<IRowProps>`
	position: relative;
	height: 40px;
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid #dbdbdb;
	font-weight: ${(props) => (props.isYou ? "bold" : "normal")};
	color: ${(props) => (props.isYou ? "#FFF" : "#000")};

	${getWinner};
`;
const Cell = styled.div<ICellProps>`
	box-sizing: border-box;
	padding: 5px 10px;
	font-family: "StyreneA-Regular", sans-serif;
	font-size: 12px;
	letter-spacing: 0;
	line-height: 15px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: inherit;
	flex: 0 0 auto;
	border-bottom: 1px solid #dbdbdb;

	position: relative;
	&:after {
		display: ${(props) => (props.isActive ? "block" : "none")};
		content: "";
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.1);
		position: absolute;
		top: 0;
		left: 0;
	}
`;
const Position = styled(Cell)`
	flex: 1 1 30px;
	justify-items: flex-start;
`;
const Player = styled(Cell)`
	flex: 1 1 145px;
	justify-items: flex-start;
	justify-content: flex-start;
`;
const PointsCell = styled(Cell)`
	flex: 1 1 75px;
	justify-items: flex-start;
`;
const TotalPointsCell = styled(Cell)`
	flex: 1 1 75px;
	justify-items: flex-start;
	position: sticky;
	font-family: "StyreneA-Bold", sans-serif;
	z-index: 3;
`;

interface IProps {
	ranking: IRanking;
	order: OrderRanking;
}

export const RankingsTableRow: React.FC<IProps> = ({ranking, order}) => {
	const {rank, roundPoints = 0, points: totalPoints = 0} = ranking;

	const user = useSelector(getUser);
	const isUser = ranking.userId === user?.id;
	const userName = userNameForRankings(ranking.firstName, ranking.lastName);

	const isActiveOrder = (checkValue: OrderRanking) => {
		return checkValue === order;
	};

	return (
		<TableRow winnerState={rank} isYou={isUser}>
			<Position isActive={isActiveOrder(OrderRanking.Rank)}>{rank}</Position>
			<Player isActive={isActiveOrder(OrderRanking.Rank)}>{userName}</Player>
			<PointsCell isActive={isActiveOrder(OrderRanking.RoundPoints)}>
				{roundPoints}
			</PointsCell>
			<TotalPointsCell isActive={isActiveOrder(OrderRanking.Points)}>
				{totalPoints}
			</TotalPointsCell>
		</TableRow>
	);
};
