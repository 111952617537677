import React from "react";
import {IIconProps} from "modules/types";

export const IconInfo: React.FC<IIconProps> = ({width = 26, height = 26, color = "#000"}) => (
	<svg
		width={`${width}px`}
		height={`${height}px`}
		viewBox="0 0 26 26"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg">
		<title>Group</title>
		<g id="Symbols" stroke="none" fill="none">
			<g
				id="3.0-Icons-/-Info-(Predictions-/-Postponed)-"
				transform="translate(-2.000000, -2.000000)"
				fill={color}>
				<g id="Group" transform="translate(2.000000, 2.000000)">
					<path
						d="M13,0 C5.83488372,0 0,5.83488372 0,13 C0,20.1651163 5.83488372,26 13,26 C20.1651163,26 26,20.1651163 26,13 C26,5.83488372 20.1651163,0 13,0 Z M13,24.1860465 C6.83255814,24.1860465 1.81395349,19.1674419 1.81395349,13 C1.81395349,6.83255814 6.83255814,1.81395349 13,1.81395349 C19.1674419,1.81395349 24.1860465,6.83255814 24.1860465,13 C24.1860465,19.1674419 19.1674419,24.1860465 13,24.1860465 Z"
						id="Shape"
					/>
					<path
						d="M13,11 C12.4333333,11 12,11.4034483 12,11.9310345 L12,19.0689655 C12,19.5965517 12.4333333,20 13,20 C13.5666667,20 14,19.5965517 14,19.0689655 L14,11.9310345 C14,11.4034483 13.5666667,11 13,11 Z"
						id="Path"
					/>
					<path
						d="M13,7 C12.45,7 12,7.45 12,8 C12,8.55 12.45,9 13,9 C13.55,9 14,8.55 14,8 C14,7.45 13.55,7 13,7 Z"
						id="Path"
					/>
				</g>
			</g>
		</g>
	</svg>
);
