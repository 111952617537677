import React, {Fragment, useCallback, useMemo, useState} from "react";
import {Exist} from "components/Exist";
import {eq, some} from "lodash";
import {useDispatch, useSelector} from "react-redux";
import {
	getCreatedLeagueID,
	getInvitesRequestState,
	getLeagueByIdSelector,
} from "modules/selectors/leagues";
import {FormField, Input} from "components/FormElemets";
import {Button} from "components/Buttons";
import theme from "assets/css/theme";
import {RequestState} from "modules/enums";
import styled from "styled-components";
import {EMAIL_REGEXP} from "modules/constants";
import plus from "assets/img/icon/add.svg";
import minus from "assets/img/icon/minus.svg";
import {useParams} from "react-router-dom";
import {postInviteReset} from "modules/actions";

const Inputs = styled.div`
	display: flex;
	width: 100%;
	flex-flow: row wrap;
	position: relative;
`;
const InviteButton = styled.button`
	width: 40px;
	height: 40px;
	border-radius: 4px;
	border: 1px solid transparent;
	flex-shrink: 0;
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 20px;
	margin-left: auto;
	cursor: pointer;
	background-color: #000;
`;
const InputWrapper = styled.div`
	display: flex;
	flex-flow: row wrap;
	align-items: flex-start;
	width: 100%;

	> div {
		width: calc(100% - 60px);
		margin: 0;

		&.btnRow {
			display: flex;
			flex-flow: row wrap;
			align-items: flex-end;
		}

		p {
			font-size: 14px;
			font-weight: bold;
			margin-bottom: 20px;
		}
	}
`;
const InvitesViaWrapper = styled.div`
	margin-bottom: 30px;
	display: flex;
	flex-flow: row wrap;
	align-items: flex-start;
	position: relative;
`;
const SendInviteButton = styled(Button)`
	color: #ffffff;
	font-family: "StyreneA-Bold", sans-serif;
	font-size: 12px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 15px;
	text-align: center;
	text-transform: uppercase;
	&:hover {
		color: #fff !important;
	}
`;
const WarningMessage = styled.div<IWarningMessage>`
	opacity: ${(props) => (props.isVisual ? "1" : "0")};
	color: red;
	font-family: ${theme.baseFont};
	font-size: 11px;
	letter-spacing: 0;
	line-height: 18px;
	width: 100%;
	transition: all ease 0.3s;
	margin-bottom: 10px;
`;
interface IWarningMessage {
	isVisual: boolean;
}

interface IButtonsProps {
	addLine: () => void;
	removeLine: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	last_index: number;
	index: number;
	fields_limit: number;
}

const Buttons: React.FC<IButtonsProps> = ({addLine, removeLine, last_index, index, fields_limit}) =>
	last_index === index && index < fields_limit ? (
		<InviteButton onClick={addLine}>
			<img src={plus} alt={"Add"} />
		</InviteButton>
	) : (
		<InviteButton onClick={removeLine} data-index={index}>
			<img src={minus} alt={"Remove"} />
		</InviteButton>
	);

interface IProps {
	onSendInvites: (emails: string[]) => void;
}

interface IRouteParams {
	id?: string;
}

export const InvitesViaEmail: React.FC<IProps> = ({onSendInvites}) => {
	const dispatch = useDispatch();
	const leagueCreateId = useSelector(getCreatedLeagueID);
	const {id: routeLeagueID} = useParams<IRouteParams>();
	const leagueID = parseInt(String(routeLeagueID || leagueCreateId), 10);
	const league = useSelector(getLeagueByIdSelector)(leagueID);
	const requestState = useSelector(getInvitesRequestState);
	const [emailsList, updateEmailsList] = useState<string[]>([""]);
	const [isVisible, handleIsVisible] = useState(false);

	const isSendButtonDisabled = useMemo(
		() =>
			some(emailsList, (email) => !EMAIL_REGEXP.test(email)) ||
			eq(requestState, RequestState.Requested),
		[emailsList, requestState]
	);

	const addLine = () => {
		dispatch(postInviteReset());
		if (emailsList.length <= 10) {
			updateEmailsList([...emailsList, ""]);
		}
	};

	const removeLine = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		const index = parseInt(e.currentTarget.dataset.index || "0", 10);
		const new_data = [...emailsList];
		new_data.splice(index, 1);
		return updateEmailsList(new_data);
	};

	const sendInvites = useCallback(() => {
		onSendInvites(emailsList);
	}, [onSendInvites, emailsList]);

	const checkValue = (e: React.SyntheticEvent<HTMLInputElement>) => {
		const value = e.currentTarget.value;
		handleIsVisible(value === "");
	};

	const updateEmailValueByIndex = (e: React.SyntheticEvent<HTMLInputElement>) => {
		const index = parseInt(e.currentTarget.dataset.index || "0", 10);
		const {value} = e.currentTarget;
		updateEmailsList(emailsList.map((values, i) => (index === i ? value : values)));
		dispatch(postInviteReset());
	};

	if (!league) {
		return null;
	}

	const last_index = emailsList.length - 1;
	const fieldsLimit = Number(league.size) - league.numberJoins;

	return (
		<Fragment>
			<InvitesViaWrapper>
				<Inputs>
					{emailsList.map((email, index) => (
						<InputWrapper key={index}>
							<FormField>
								<Input
									type="email"
									onChange={updateEmailValueByIndex}
									onBlur={checkValue}
									data-index={index}
									defaultValue={""}
									placeholder={"Enter email address"}
									name={"email"}
								/>
							</FormField>
							<Buttons
								addLine={addLine}
								removeLine={removeLine}
								last_index={last_index}
								index={index}
								fields_limit={fieldsLimit}
							/>
						</InputWrapper>
					))}
				</Inputs>
				<WarningMessage isVisual={isVisible}>
					Please provide your friend's email address
				</WarningMessage>
				<SendInviteButton
					disabled={isSendButtonDisabled}
					is_white_background={true}
					color={theme.secondary}
					hover_color={theme.secondaryActive}
					onClick={sendInvites}>
					<Exist when={eq(requestState, RequestState.IDLE)}>Send invites</Exist>
					<Exist when={eq(requestState, RequestState.Requested)}>Invites sent</Exist>
					<Exist when={eq(requestState, RequestState.Received)}>
						Invites has been sent
					</Exist>
				</SendInviteButton>
			</InvitesViaWrapper>
		</Fragment>
	);
};
