import * as React from "react";
import moment from "moment";
import {isFunction} from "lodash";

const MS_IN_SECOND = 1000;
const SEC_IN_MINUTE = 60;
const MIN_IN_HOUR = 60;
const HRS_IN_DAY = 24;

interface IProps {
	format?: string;
	onComplete?: () => void;
	children?: (params: ICountdownState) => React.ReactNode | React.ReactChildren;
	date: number | string | Date;
}

export interface ICountdownState {
	days: number;
	hours: number;
	minutes: number;
	seconds: number;
	diff: number;
	completed: boolean;
}

const defaultTimerValue = {
	days: 0,
	hours: 0,
	minutes: 0,
	seconds: 0,
	diff: 0,
};

export class CountdownTimer extends React.Component<IProps, ICountdownState> {
	public static defaultProps = {
		format: "hh:mm:ss",
	};

	public state = {
		...defaultTimerValue,
		completed: false,
	};

	private timer?: NodeJS.Timeout;

	constructor(props: IProps) {
		super(props);

		const startDate = moment(Date.now());
		const endDate = moment(props.date);
		const duration = endDate.diff(startDate);

		this.state = {
			...this.state,
			...this.timerValues(duration),
		};
	}

	/**
	 * @ignore
	 */
	public render() {
		const {format, children} = this.props;
		const {diff} = this.state;

		if (children) {
			return children(this.state);
		}

		if (!diff) {
			return "00:00:00";
		}

		return moment(this.state.diff).format(format);
	}

	/**
	 * @ignore
	 */
	public componentDidMount() {
		const startDate = moment(Date.now());
		const endDate = moment(this.props.date);
		let duration = endDate.diff(startDate);

		if (duration <= 0) {
			return;
		}

		this.timer = setInterval(() => this.diff((duration -= MS_IN_SECOND)), MS_IN_SECOND);
	}

	private clearTimerInterval() {
		if (this.timer) {
			clearInterval(this.timer);
		}
	}

	/**
	 * @ignore
	 */
	public componentWillUnmount() {
		this.clearTimerInterval();
	}

	private timerValues(diff: number) {
		if (diff < MS_IN_SECOND) {
			return defaultTimerValue;
		}

		const seconds = Math.floor(diff / MS_IN_SECOND);
		const minutes = Math.floor(seconds / SEC_IN_MINUTE);
		const hours = Math.floor(minutes / MIN_IN_HOUR);

		return {
			diff,
			days: Math.floor(hours / HRS_IN_DAY),
			hours: hours % HRS_IN_DAY,
			minutes: minutes % MIN_IN_HOUR,
			seconds: seconds % SEC_IN_MINUTE,
		};
	}

	private diff(diff: number) {
		if (diff < MS_IN_SECOND) {
			return this.handleCompleteState();
		}

		this.setState(this.timerValues(diff));
	}

	private handleCompleteState() {
		const {onComplete} = this.props;

		this.setState({
			completed: true,
		});

		this.clearTimerInterval();

		if (isFunction(onComplete)) {
			onComplete();
		}
	}
}

export default CountdownTimer;
