import {createAction} from "redux-act";
import {ApiError} from "modules/utils/Api";

export interface IPredictionResponse {
	answers: IResponseAnswer[];
}

export interface IGetPredictionResponse {
	answers: Record<string, ISetAnswer[]>;
}

export interface IResponseAnswer {
	questionId: number;
	unitId: number;
	answer: number;
	points: number;
}

export interface IPredictionPayload {
	roundId: number;
}

export const getPrediction = createAction<IPredictionPayload>();
export const getPredictionSuccess = createAction<IGetPredictionResponse>();
export const getPredictionError = createAction<ApiError>();

export interface IPredictionAnswer {
	unitId: number;
	answers: IAnswer[];
}

export interface IAnswer {
	questionId: number;
	answer: number;
	points?: number;
	unitId?: number;
}

export interface ISetAnswer {
	unitId: number;
	questionId: number;
	answer: number;
	points?: number;
}

export interface IPostPredictionResponse {
	unitId: string;
	answers: IAnswer[];
}

export const postPrediction = createAction<IPredictionAnswer>();
export const postPredictionSuccess = createAction<IPostPredictionResponse>();
export const postPredictionError = createAction<ApiError>();

export const setPredictionState = createAction<ISetAnswer>();
export const setPredictionError = createAction<ApiError>();
export const clearSetPredictionState = createAction();

export const resetPrediction = createAction<{unitId: string}>();
export const resetIsFetched = createAction();
export const resetPostStatus = createAction();
