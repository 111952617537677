import React, {Fragment} from "react";
import {useSelector} from "react-redux";
import {getFirstScheduledRound, getLastCompleteRound} from "modules/selectors/rounds";
import {every, get, eq} from "lodash";
import styled from "styled-components";
import theme from "assets/css/theme";
import {getSquadById} from "modules/selectors/squads";
import {ISquad} from "modules/actions";
import {NextMatchCounter} from "components/NextMatchCounter";
import {isBefore} from "date-fns";

const NextMatchWrapper = styled.div`
	width: 100%;
	margin-bottom: 30px;
	position: relative;
	padding: 0px 20px 5px;
	box-sizing: border-box;
	z-index: 4;
`;
const NextMatchContainer = styled.div`
	width: 100%;
	min-height: 50px;
	padding: 6px 10px 30px;
	box-sizing: border-box;
	background: ${theme.secondary};
	position: relative;
`;
const NextMatchDayNumber = styled.div`
	width: max-content;
	padding: 5px 10px;
	box-sizing: border-box;
	background: #000;
	color: #fff;
	font-family: ${theme.boldFont};
	text-transform: uppercase;
	margin: 5px auto;
`;
const MatchTitle = styled.div`
	width: 100%;
	padding: 2px 40px;
	box-sizing: border-box;
	text-transform: uppercase;
	color: #000;
	font-family: ${theme.boldFont};
	font-size: 18px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 1.2;
	text-align: center;
`;
const Separator = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 6px 0;
`;
const TimerContainer = styled.div`
	position: absolute;
	left: 50%;
	bottom: -25px;
	transform: translateX(-50%);
	width: 50px;
	height: 50px;
`;
const Link = styled.a`
	width: 100%;
	display: flex;
	justify-content: center;
	margin: 10px auto 20px;
	text-transform: uppercase;
	font-size: 14px;
	text-decoration: underline;
`;

const getSquadName = (squad: ISquad | undefined) => {
	if (squad) {
		return squad.name;
	}
	return "";
};

interface IProps {
	roundId: number;
}

export const NextMatchDayBanner: React.FC<IProps> = ({roundId}) => {
	const lastCompleteRound = useSelector(getLastCompleteRound);
	const nextScheduledMatch = useSelector(getFirstScheduledRound);
	const nextMatchDate = nextScheduledMatch?.date;
	const startGameDay = nextMatchDate ? new Date(nextMatchDate) : new Date();
	// const lastCompleteRoundDate = lastCompleteDate ? new Date(lastCompleteDate) : new Date();
	const isLastRoundId = eq(lastCompleteRound?.id, roundId);
	const isVisibleByTime = isBefore(Date.now(), startGameDay);
	const isVisibleNextBanner = every([nextScheduledMatch, isVisibleByTime, isLastRoundId]);
	const nextMatchNumber = nextScheduledMatch?.number;
	const nextMatchId = nextScheduledMatch?.id;

	const units = nextScheduledMatch?.units || [];
	const isDoubleMatch = units.length > 1;
	const firstUnit = units[0];
	const secondUnit = units[1];
	const firstUnitHomeSquadId = get(firstUnit, "homeSquadId", 0);
	const firstUnitAwaySquadId = get(firstUnit, "awaySquadId", 0);
	const secondUnitHomeSquadId = get(secondUnit, "homeSquadId", 0);
	const secondUnitAwaySquadId = get(secondUnit, "awaySquadId", 0);
	const getSquad = useSelector(getSquadById);
	const firstHomeSquad = getSquad(firstUnitHomeSquadId);
	const firstAwaySquad = getSquad(firstUnitAwaySquadId);
	const secondHomeSquad = getSquad(secondUnitHomeSquadId);
	const secondAwaySquad = getSquad(secondUnitAwaySquadId);

	return isVisibleNextBanner ? (
		<NextMatchWrapper>
			<Link href="/help:rules">How does scoring work?</Link>
			<NextMatchContainer>
				<NextMatchDayNumber>Up next on Match day {nextMatchNumber}</NextMatchDayNumber>
				{isDoubleMatch ? (
					<Fragment>
						<MatchTitle>
							{getSquadName(firstHomeSquad)} VS {getSquadName(firstAwaySquad)}
						</MatchTitle>
						<Separator>&</Separator>
						<MatchTitle>
							{getSquadName(secondHomeSquad)} VS {getSquadName(secondAwaySquad)}
						</MatchTitle>
					</Fragment>
				) : (
					<MatchTitle>
						{getSquadName(firstHomeSquad)} VS {getSquadName(firstAwaySquad)}
					</MatchTitle>
				)}
				<TimerContainer>
					<NextMatchCounter
						roundId={nextMatchId ?? 0}
						// startGameDay={nextMatchDate ?? ""}
					/>
				</TimerContainer>
			</NextMatchContainer>
		</NextMatchWrapper>
	) : null;
};
