import React from "react";
import {IIconProps} from "modules/types";

export const IconRankings: React.FC<IIconProps> = ({width = 23, height = 22, color = "#FFF"}) => (
	<svg
		width={`${width}px`}
		height={`${height}px`}
		viewBox="0 0 23 22"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg">
		<g id="Symbols" stroke="none" fill="none">
			<g id="noun_Star_3927101" transform="translate(-213.000000, -8.000000)">
				<g transform="translate(214.000000, 9.000000)">
					<path
						d="M7.10181392,6.1642365 L0.916224152,6.78377443 L0.857896212,6.79118369 C0.0142220929,6.92148508 -0.306692388,7.99268677 0.351909714,8.56392908 L4.98514429,12.5823364 L3.74638724,18.7810263 L3.73670465,18.8369148 C3.61444773,19.6706742 4.51759473,20.2926179 5.25779179,19.8568617 L10.5,16.7705736 L15.7422082,19.8568617 L15.7917831,19.8843544 C16.5386355,20.2735194 17.42203,19.6237821 17.2536127,18.7810263 L16.0145171,12.5823364 L20.6480903,8.56392908 L20.6914825,8.52422291 C21.305797,7.93099945 20.9509758,6.87056338 20.0837759,6.78377443 L13.8978474,6.1642365 L11.4284338,0.60384389 C11.0708847,-0.201281297 9.92911532,-0.201281297 9.57156617,0.60384389 L7.10181392,6.1642365 Z"
						id="Path"
						stroke={color}
					/>
				</g>
			</g>
		</g>
	</svg>
);
