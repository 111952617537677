import {
	clearCreateLeague,
	clearInvites,
	clearLeague,
	clearUpdateState,
	fetchLeaguePlayersSuccess,
	fetchShowForJoinLeaguesSuccess,
	fetchShowMyLeaguesSuccess,
	ILeagueUsersResponse,
	loadMoreLeaguePlayersSuccess,
	loadMoreLeaguesForJoinSuccess,
	postCreateLeagueSuccess,
	postInviteReset,
	postInviteSuccess,
	postJoinLeague,
	postJoinLeagueSuccess,
	postLeaveOrRemoveLeagueSuccess,
	postRemovePlayer,
	postRemovePlayerSuccess,
	postUpdateLeagueSuccess,
} from "modules/actions/leagues";
import {createReducer} from "redux-act";
import {LeagueClass, LeaguePrivacy, LeagueType, RequestState, RoundStatus} from "modules/enums";

export interface ILeague {
	id: number;
	startRoundId: number;
	commissioner?: ICommissioner;
	userResult?: IUserResults | null;
	code: string;
	name: string;
	type: LeagueType;
	class: LeagueClass;
	classLabel: string;
	privacy: LeaguePrivacy;
	status: RoundStatus;
	size: string;
	numberJoins: number;
}

export interface ICommissioner {
	id: number;
	firstName: string;
	lastName: string;
}

export interface IUserResults {
	points: number;
	rank: number;
	roundPoints: number;
}

export interface ILeagueReducer {
	league: ILeague;
	leaguesAvailable: ILeagues;
	leaguesForJoin: ILeagues;
	create: {
		name: string;
		privacy: LeaguePrivacy;
		error?: string;
		requestState: RequestState;
	};
	invites: {
		leagueID?: number;
		requestState: RequestState;
		error?: string;
	};
	joinedUsers: ILeagueUsersResponse;
	joinRequestState: RequestState;
	updateLeagueState: boolean;
}

export interface ILeagues {
	leagues: ILeague[];
	nextPage: boolean;
}

const initialState: ILeagueReducer = {
	league: {
		id: 0,
		startRoundId: 1,
		code: "",
		name: "",
		type: LeagueType.open,
		class: LeagueClass.regular,
		classLabel: "",
		privacy: LeaguePrivacy.private,
		status: RoundStatus.scheduled,
		size: "100",
		numberJoins: 0,
	},
	leaguesAvailable: {
		leagues: [],
		nextPage: false,
	},
	leaguesForJoin: {
		leagues: [],
		nextPage: false,
	},
	create: {
		name: "",
		privacy: LeaguePrivacy.public,
		requestState: RequestState.IDLE,
	},
	invites: {
		requestState: RequestState.IDLE,
	},
	joinedUsers: {
		users: [],
		nextPage: false,
	},
	joinRequestState: RequestState.IDLE,
	updateLeagueState: false,
};

export const leagues = createReducer<ILeagueReducer>({}, initialState)
	.on(fetchShowMyLeaguesSuccess, (state, leagues) => ({
		...state,
		leaguesAvailable: {
			...leagues,
		},
	}))
	.on(fetchShowForJoinLeaguesSuccess, (state, leagues) => ({
		...state,
		leaguesForJoin: {
			...leagues,
		},
	}))
	.on(postJoinLeague, (state) => ({
		...state,
		joinRequestState: RequestState.Requested,
	}))
	.on(postJoinLeagueSuccess, (state, league) => ({
		...state,
		leaguesAvailable: {
			...state.leaguesAvailable,
			leagues: [...state.leaguesAvailable.leagues, league],
		},
		joinRequestState: RequestState.Received,
	}))
	.on(loadMoreLeaguesForJoinSuccess, (state, payload) => ({
		...state,
		leaguesForJoin: {
			leagues: [...state.leaguesForJoin.leagues, ...payload.leagues],
			nextPage: payload.nextPage,
		},
	}))
	.on(postCreateLeagueSuccess, (state, league) => ({
		...state,
		leaguesAvailable: {
			...state.leaguesAvailable,
			leagues: [...state.leaguesAvailable.leagues, league],
		},
		invites: {
			...state.invites,
			leagueID: league.id,
		},
	}))
	.on(clearLeague, (state) => ({
		...state,
		league: initialState.league,
	}))
	.on(clearCreateLeague, (state) => ({
		...state,
		create: initialState.create,
	}))
	.on(clearInvites, (state) => ({
		...state,
		invites: initialState.invites,
	}))
	.on(postInviteSuccess, (state) => ({
		...state,
		invites: {
			...state.invites,
			requestState: RequestState.Requested,
		},
	}))
	.on(postInviteReset, (state) => ({
		...state,
		invites: {
			...state.invites,
			requestState: RequestState.IDLE,
		},
	}))
	.on(postLeaveOrRemoveLeagueSuccess, (state, payload) => ({
		...state,
		leaguesAvailable: {
			...state.leaguesAvailable,
			leagues: [...state.leaguesAvailable.leagues.filter((el) => el.id !== payload.leagueId)],
		},
	}))
	.on(postUpdateLeagueSuccess, (state, league) => ({
		...state,
		leaguesAvailable: {
			...state.leaguesAvailable,
			leagues: [
				...state.leaguesAvailable.leagues.filter((el) => el.id !== league.id),
				league,
			],
		},
		updateLeagueState: true,
	}))
	.on(clearUpdateState, (state) => ({
		...state,
		updateLeagueState: false,
	}))
	.on(fetchLeaguePlayersSuccess, (state, response) => ({
		...state,
		joinedUsers: {
			users: [...response.users],
			nextPage: response.nextPage,
		},
	}))
	.on(loadMoreLeaguePlayersSuccess, (state, response) => ({
		...state,
		joinedUsers: {
			users: [...state.joinedUsers.users, ...response.users],
			nextPage: response.nextPage,
		},
	}))
	.on(postRemovePlayer, (state) => ({
		...state,
		joinRequestState: RequestState.Requested,
	}))
	.on(postRemovePlayerSuccess, (state, userId) => ({
		...state,
		joinedUsers: {
			...state.joinedUsers,
			users: [...state.joinedUsers.users.filter((el) => el.id !== userId)],
		},
		joinRequestState: RequestState.Received,
	}));
