import {SagaIterator} from "redux-saga";
import {call, put} from "typed-redux-saga";
import {fetchVenuesJSONSuccess, showGlobalError} from "modules/actions";
import {Api} from "modules/utils/Api";

export const fetchVenuesJSONSaga = function* (): SagaIterator {
	try {
		const response = yield* call(Api.JSON.venues);
		yield* put(fetchVenuesJSONSuccess(response.venues));
	} catch (err) {
		yield* put(showGlobalError(err as Error));
	}
};
