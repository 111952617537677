import {Direction} from "modules";
import React from "react";
import {Exist} from "components/Exist";
import styled from "styled-components";

const ArrowSVG = styled.svg<IArrowPositions>`
	transform: rotate(${({position}) => Positions[position]}deg);
`;
interface IPositions {
	[x: string]: number;
}

interface IArrowPositions {
	position: string;
}

interface IProps extends IArrowPositions {
	title?: string;
	width?: number;
	height?: number;
	color: string;
	onClick?: () => void;
	className?: string;
}

const Positions: IPositions = {
	left: 180,
	top: 270,
	bottom: 90,
	right: 0,
};

const Arrow: React.FC<IProps> = ({
	title,
	width = 12,
	height = 7,
	color,
	position,
	onClick,
	className,
}) => (
	<ArrowSVG
		onClick={onClick}
		position={position}
		width={`${width}px`}
		height={`${height}px`}
		viewBox="0 0 12 7"
		className={className}>
		<title>{title}</title>
		<path
			d="M2.65342353,8.53952326 C2.83661153,8.72138854 3.08465385,8.8228604 3.3427838,8.82153428 C3.58750585,8.83352181 3.82609187,8.7426319 4.0008095,8.57085782 L8.51298578,4.309358 C8.69668566,4.12699136 8.8,3.87884756 8.8,3.61999773 C8.8,3.3611479 8.69668566,3.1130041 8.51298578,2.93063747 L4.0008095,-1.33086235 C3.81964435,-1.50866711 3.57427163,-1.60564726 3.32049906,-1.59974557 C3.0667265,-1.59384388 2.82612731,-1.48556207 2.65342353,-1.2995278 C2.30369466,-0.925524643 2.31762593,-0.340411414 2.68475809,0.0165236194 L6.47623955,3.61999773 L2.68475809,7.22347184 C2.31762593,7.58040688 2.30369466,8.16552011 2.65342353,8.53952326 L2.65342353,8.53952326 Z"
			id={`arrow-icon-${position}`}
			fill={color}
		/>
	</ArrowSVG>
);

Arrow.defaultProps = {
	title: "Arrow bottom",
	width: 12,
	height: 7,
	color: "#fff",
	position: "bottom",
};

const SortArrowsWrapper = styled.div`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	position: relative;
	width: 100%;
	height: 100%;
	cursor: pointer;

	svg {
		pointer-events: none;
		position: absolute;
		left: 50%;
		margin-left: -6px;
	}

	.arrow-top {
		top: 1px;
	}

	.arrow-bottom {
		bottom: 1px;
	}
`;

interface ISortArrowsProps {
	sortOrder: Direction;
	isActive: boolean;
}

const getArrowColor = (isHighlighted: boolean) => (isHighlighted ? "#000" : "#A4A4A4");

export const IconOrderArrow: React.FC<ISortArrowsProps> = ({children, isActive, sortOrder}) => {
	const highlightTopArrow = sortOrder === Direction.DESC;

	return (
		<SortArrowsWrapper className={"black"}>
			<Exist when={isActive}>
				<Arrow
					className="arrow-top"
					color={getArrowColor(highlightTopArrow)}
					position="top"
				/>
			</Exist>
			{children}
			<Exist when={isActive}>
				<Arrow
					className="arrow-bottom"
					color={getArrowColor(!highlightTopArrow)}
					position="bottom"
				/>
			</Exist>
		</SortArrowsWrapper>
	);
};
