import {createAction} from "redux-act";
import {ApiError} from "modules/utils/Api";
import {ILeague, ILeagues} from "modules/reducers";
import {LeaguePrivacy, LeagueType} from "modules/enums";
import {IApiResponse} from "modules/types";

export interface IShowMyLeaguesPayload {
	search?: string;
	page?: number;
	limit?: number;
}

export const fetchShowMyLeagues = createAction<IShowMyLeaguesPayload>();
export const fetchShowMyLeaguesSuccess = createAction<ILeagues>();
export const fetchShowMyLeaguesError = createAction<ApiError>();

export const fetchShowForJoinLeagues = createAction<IShowMyLeaguesPayload>();
export const fetchShowForJoinLeaguesSuccess = createAction<ILeagues>();
export const fetchShowForJoinLeaguesError = createAction<ApiError>();

export interface ILeagueJoinPayload {
	code: string;
}

export const postJoinLeague = createAction<ILeagueJoinPayload>();
export const postJoinLeagueSuccess = createAction<ILeague>();
export const postJoinLeagueError = createAction<ApiError>();

export const loadMoreLeaguesForJoin = createAction<IShowMyLeaguesPayload>();
export const loadMoreLeaguesForJoinSuccess = createAction<ILeagues>();

export interface ILeagueCreatePayload {
	startRoundId: number;
	name: string;
	privacy: LeaguePrivacy;
	type: LeagueType;
	size: number;
}

export const postCreateLeague = createAction<ILeagueCreatePayload>();
export const postCreateLeagueSuccess = createAction<ILeague>();
export const postCreateLeagueError = createAction<ApiError>();

export const clearLeague = createAction();

export interface IInviteEmails {
	leagueId: number;
	emails: string[];
}

export const postInvite = createAction<IInviteEmails>();
export const postInviteSuccess = createAction<IApiResponse>();
export const postInviteError = createAction<ApiError>();
export const postInviteReset = createAction();

export const clearCreateLeague = createAction();
export const clearInvites = createAction();

export interface ILeagueId {
	leagueId: number;
}

export const postLeaveLeague = createAction<ILeagueId>();
export const postLeaveOrRemoveLeagueSuccess = createAction<ILeagueId>();
export const postLeaveLeagueError = createAction<ApiError>();

export const postRemoveLeague = createAction<ILeagueId>();
export const postRemoveLeagueError = createAction<ApiError>();

export interface ILeagueUpdatePayload {
	leagueId: number;
	startRoundId: number;
	name: string;
	privacy: LeaguePrivacy;
	type: LeagueType;
	size: number;
}

export const postUpdateLeague = createAction<ILeagueUpdatePayload>();
export const postUpdateLeagueSuccess = createAction<ILeague>();
export const postUpdateLeagueError = createAction<ApiError>();

export const clearUpdateState = createAction();

export interface ILeagueUsersJoinedParams {
	leagueId: number;
	search?: string;
	page?: number;
	limit?: number;
}

export interface ILeagueUser {
	id: number;
	firstName: string;
	lastName: string;
}

export interface ILeagueUsersResponse {
	users: ILeagueUser[];
	nextPage: boolean;
}

export interface ILeagueRemovePlayerPayload {
	leagueId: number;
	userIds: number[];
}

export const fetchLeaguePlayers = createAction<ILeagueUsersJoinedParams>();
export const fetchLeaguePlayersSuccess = createAction<ILeagueUsersResponse>();
export const fetchLeaguePlayersError = createAction<ApiError>();

export const loadMoreLeaguePlayers = createAction<ILeagueUsersJoinedParams>();
export const loadMoreLeaguePlayersSuccess = createAction<ILeagueUsersResponse>();

export const postRemovePlayer = createAction<ILeagueRemovePlayerPayload>();
export const postRemovePlayerSuccess = createAction<number>();
export const postRemovePlayerError = createAction<ApiError>();

export interface ILeagueH2HPayload {
	leagueId: number;
	roundId: number;
}

export interface ILeagueH2HResponse {
	fixtures: IFixture[];
}

export interface IFixture {
	leftUser: IH2HUser | null;
	rightUser: IH2HUser | null;
	leftUserPoints: number | null;
	rightUserPoints: number | null;
	leftUserPredictions: {
		answers: IUserAnswer[];
	};
	rightUserPredictions: {
		answers: IUserAnswer[];
	};
}

export interface IH2HUser {
	id: number;
	firstName: string;
	lastName: string;
}

export interface IUserAnswer {
	unitId: number;
	questionId: number;
	answer: number;
	points: number;
}

export const getH2HFixture = createAction<ILeagueH2HPayload>();
export const getH2HFixtureSuccess = createAction<ILeagueH2HResponse>();
export const getH2HFixtureError = createAction<ApiError>();
